<template>
<div class="bg-gray-200 h-screen flex flex-col justify-center">
    <div class="flex flex-col items-center">
        <img class="h-40 mx-auto" src="../assets/loading-animate.gif" alt="loading...">
        <h1>Please wait we are getting things ready for you</h1>
    </div>

    <div class="mx-auto w-64">

        <transition @before-enter="beforeEnter" @enter="enter" @leave="leave" :css="false">

            <div v-if="isCollection" class="w-full text-gray-600 text-center font-medium text-lg overflow-auto">Loading collections...</div>

        </transition>
        <transition @before-enter="beforeEnter" @enter="enter" @leave="leave" :css="false">
            <div v-if="isBot" class="w-full text-gray-600  text-center font-medium text-lg">Loading bot articles...</div>

        </transition>
        <transition @before-enter="beforeEnter" @enter="enter" @leave="leave" :css="false">
            <div v-if="isTodo" class="w-full text-gray-600  text-center font-medium text-lg">Loading Todo's...</div>

        </transition>
        <transition @before-enter="beforeEnter" @enter="enter" @leave="leave" :css="false">
            <div v-if="isFinalizing" class="w-full text-gray-600  text-center font-medium text-lg">Finalizing everything...</div>

        </transition>
        <transition @before-enter="beforeEnter" @enter="enter" @leave="leave" :css="false">

            <div v-if="isReadyToDiveIn" class="w-full text-gray-600  text-center font-medium text-lg">Ready to dive in...</div>
        </transition>

    </div>

</div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    data: () => {
        return {
            isCollection: true,
            isBot: false,
            isTodo: false,
            isFinalizing: false,
            isReadyToDiveIn: false
        }
    },
    methods: {
        beforeEnter(el) {
            el.style.opacity = 0
            el.style.width = '10em'
        },
        enter(el, done) {
            Velocity(
                el, {
                    opacity: 1,
                    width: '14em'
                }, {
                    duration: 1000,
                    easing: 'easeOutCubic',
                    complete: done
                }
            )
        },
        leave(el, done) {
            Velocity(
                el, {
                    opacity: 0,
                    width: '14em'
                }, {
                    duration: 0,
                    easing: 'easeInCubic',
                    complete: done
                }
            )
        },
    },
    created: function () {
        setTimeout(() => {
            this.isCollection = false
            this.isBot = true
            this.isTodo = false
            this.isFinalizing = false
            this.isReadyToDiveIn = false

        }, 1000)
        setTimeout(() => {
            this.isCollection = false
            this.isBot = false
            this.isTodo = true
            this.isFinalizing = false
            this.isReadyToDiveIn = false

        }, 2000)
        setTimeout(() => {
            this.isCollection = false
            this.isBot = false
            this.isTodo = false
            this.isFinalizing = true
            this.isReadyToDiveIn = false

        }, 3000)

        setTimeout(() => {
            this.isCollection = false
            this.isBot = false
            this.isTodo = false
            this.isFinalizing = false
            this.isReadyToDiveIn = true

        }, 4000)
    }

}
</script>

<style scoped>

</style>