<template>
<div class="h-auto flex flex-col">
    <Navbar />
    <div class="w-full flex flex-col md:flex-row justify-center items-center mt-20">
        <p class="w-full w-9/12 lg:w-4/12 flex flex-row text-3xl font-bold text-blue-600 text-center">
            Build your own research assistant
        </p>
        <a href="/dashboard" class="relative w-6/12 lg:w-1/12 text-center bg-green-300 h-10 top-2 mt-10 lg:mt-0">
            <div class="absolute -top-2 -left-2 w-full h-full bg-white text-center px-4 py-1 border-2 border-green-600 font-semibold text-green-600">
                Getting started
            </div>
        </a>
    </div>
    <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 p-2 md:p-8">
            <img src="../../assets/chat_bot_illustrator_2.svg" alt="">
        </div>
        <div class="w-full lg: flex flex-col justify-center gap-2">
            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/bot.png" alt="" />
                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        One free zealbot
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        All your projects are independently monitered.
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/vertical-settings-mixer.png" alt="" />
                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        One Time Configuration
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Our Adaptive Zealbots are bound to improvise the results for you.
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/approve-and-update.png" alt="" />
                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        Updates On Time
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Get your research data results posted to your inbox in your convenient time
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 flex flex-col justify-center gap-2">
            <div class="w-full flex flex-row">
                <div class="w-2/12 p-o lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/search.png" alt="" />

                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        ZEALBOTS searches for you
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Our AI-powered platform is unbiased and potentially monitors any area of science.
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full lg:w-1/2 p-2 md:p-8">
            <img src="../../assets/observations_mejb.svg" alt="">
        </div>
    </div>

    <div class="w-full lg:w-8/12 self-center flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <p class="text-blue-600 font-bold text-xl lg:text-3xl text-center">Automatic Literature Search With AI = More Time For Innovation!</p>
    </div>

    <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 p-2 md:p-8">
            <img src="../../assets/automate-lab.svg" alt="">
        </div>
        <div class="w-full lg:w-1/2 flex flex-col justify-center gap-2">
            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/radar.png" alt="">
                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-lg lg:text-xl">
                        Discovering a world of knowledge in your area of interest is just a Zealbots away.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'About',
    components: {
        Navbar,
        Footer,
    }
}
</script>

<style scoped>

</style>
