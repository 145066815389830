import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import VueApexCharts from "vue3-apexcharts";
import moment from 'moment'
import '@/assets/styles/tailwind.css'
import store from './store';
import axios from 'axios';
import VCalendar from 'v-calendar';

// import VueMeta from 'vue-meta'
axios.defaults.withCredentials = false
axios.defaults.baseURL = 'https://api.zealbots.com/api/v1/';
axios.defaults.headers.common['Content-Type'] = 'application/json';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faFolder,
  faEllipsisH,
  faSlidersH,
  faCog,
  faSignOutAlt,
  faHome,
  faProjectDiagram,
  faSwatchbook,
  faCloud,
  faUsers,
  faUserPlus,
  faUsersCog,
  faFolderPlus,
  faThLarge,
  faBars,
  faCaretUp,
  faCaretDown,
  faSearch,
  faFilePdf,
  faLink,
  faDownload,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBook,
  faQuoteRight,
  faPlusCircle,
  faUserAstronaut,
  faLanguage,
  faSignInAlt,
  faUserTie,
  faFileAlt,
  faUniversity,
  faUserGraduate,
  faTimesCircle,
  faAngleUp,
  faAngleDown,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faPaste,
  faList,
  faTrash,
  faRobot,
  faExclamationTriangle,





} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret)
library.add(faFolder)
library.add(faEllipsisH)
library.add(faSlidersH)
library.add(faCog)
library.add(faSignOutAlt)
library.add(faHome)
library.add(faProjectDiagram)
library.add(faSwatchbook)
library.add(faCloud)
library.add(faUsers)
library.add(faUserPlus)
library.add(faUsersCog)
library.add(faFolderPlus)
library.add(faThLarge)
library.add(faBars)
library.add(faCaretUp)
library.add(faCaretDown)
library.add(faSearch)
library.add(faFilePdf)
library.add(faLink)
library.add(faDownload)
library.add(faAngleDoubleLeft)
library.add(faAngleDoubleRight)
library.add(faBook)
library.add(faQuoteRight)
library.add(faPlusCircle)
library.add(faUserAstronaut)
library.add(faLanguage)
library.add(faSignInAlt)
library.add(faUserTie)
library.add(faFileAlt)
library.add(faUniversity)
library.add(faUserGraduate)
library.add(faTimesCircle)
library.add(faAngleUp)
library.add(faAngleDown)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faChevronLeft)
library.add(faPaste)
library.add(faList)
library.add(faTrash)
library.add(faRobot)
library.add(faExclamationTriangle)

const Gstore = reactive({
  flashMessage: '',
  preBotId: ''
})

const app = createApp(App).use(router).use(VueApexCharts).use(store).use(VCalendar, {})//.use(VueMeta)
app.config.globalProperties.$filters = {
  dateFormat(value) {
    return moment(String(value)).format('YYYY MMM')
  }
}
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')

// Providing some data globally
app.provide('Gstore', Gstore)

app.config.globalProperties.$static = {
  path(value) {
    let isDevelopment = true
    if (isDevelopment) { return window.location.origin + '/' + value }
    else {
      return value
    }
  }
}