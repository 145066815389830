<template>
<div class="fixed z-50 bg-black bg-opacity-60 h-screen w-full flex items-center">
    <div class="relative w-auto mx-auto rounded-lg bg-white p-12 text-center shadow-xl">
        <div @click="$emit('cancel')" class="absolute right-4 top-0 text-6xl text-right font-light text-green-800 cursor-lg cursor-pointer">&times;</div>
        <h1 class="font-bold text-2xl p-2">You are attempting to log out of Zealbots</h1>
        <h2 class="p-2 text-lg font-semibold text-gray-700">Are You sure?</h2>
        <p class="py-8 text-gray-500 font-semibold">Logged in as {{Username}}</p>
        <button @click="$emit('logOut')" class="bg-green-800 text-white px-24 py-4 rounded-lg">Log out</button>
    </div>
</div>
</template>

<script>
export default {

    computed: {
        Username: function () {
            if (this.$store.getters.StateUser) {
                return this.$store.getters.StateUser.split("@")[0];
            } else 
            return "UserName";
        }
    }

}
</script>

<style>

</style>
