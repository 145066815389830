<template>

    <!-- <div class="flex flex-col  justify-center lg:hidden items-center ">
        <h1 class="text-red-400 ">Dashboard Not Suitable for Mobile screen
            <font-awesome-icon class="text-red-400  text-sm" :icon="['fas', 'exclamation-triangle']" />
        </h1>

        
    </div> -->

    <div class="flex flex-row h-full row bg-gray-200 overflow-y-scroll">
        <!-- component -->
        <LeftNavigation selected='0' />

        <main class="flex-1 mr-2 overflow-visible md:overflow-hidden transition duration-500 ease-in-out bg-gray-200 ">

            <div class="text-gray-700 h-screen dark:text-gray-500 transition duration-500 ease-in-out ">
                <div class="flex flex-col md:flex-row h-full">

                    <div class="md:w-3/4 2xl:w-10/12 gl:w-9/12">
                        <div class="flex flex-row gap-2 w-auto 2xl:h-1/6 md:h-1/6 2xl:px-2 gl:px-1 ">

                            <!-- Bot -->
                            <div class="container bg-white  px-3 ml-2 lg:ml-0 m-2 w-1/3 rounded-md mx-auto">
                                <div class="py-2 md:p-4">
                                    <div class="h-full rounded-lg text-center relative">
                                        <h1 class="font-sans text-md md:text-lg font-medium text-gray-900">Bots</h1>
                                        <!-- loading gif -->
                                        <div v-if="!botCount && !botModifiedDate">
                                            <img class="h-20 mx-auto" src="../assets/loading-animate.gif" alt="loading...">
                                        </div>
                                        <!-- No issues -->
                                        <div v-if="botCount && botModifiedDate">
                                            <h1 class="title-fontfont-medium text-2xl text-green-800">{{botCount}}</h1>
                                            <div class="text-xs md:text-lg text-center justify-center left-0 w-full ">
                                                {{ botModifiedDate }}
                                            </div>
                                        </div>
                                        <!-- 500 Error -->
                                        <div v-if="botCount =='500 Error'" class="mt-2">
                                            <button @click="GetBotListApi(stateUserInfoData)" class="px-8 py-2 bg-indigo-600 hover:bg-indigo-400 rounded-md text-white">Refresh</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- collection -->
                            <div class="container bg-white px-3 m-2 w-1/3 rounded-md mx-auto">
                                <div class="py-2 md:p-4">
                                    <div class="h-full rounded-lg overflow-hidden text-center">
                                        <h1 class="font-sans text-md md:text-lg font-medium text-gray-900 mb-1">Collections</h1>
                                        <!-- loading gif -->
                                        <div v-if="!collectionCount || load">
                                            <img class="h-20 mx-auto" src="../assets/loading-animate.gif" alt="loading...">
                                        </div>
                                        <!-- No issues -->
                                        <div v-if="collectionCount && collectionCount!='500 Error'">
                                            <h1 class="font-medium text-2xl text-teal-800">{{collectionCount}}</h1>
                                            <div class="text-xs md:text-lg text-center justify-center left-0 w-full">
                                                {{botModifiedDate}}
                                            </div>
                                        </div>
                                        <!-- 500 Error -->
                                        <div v-if="collectionCount =='500 Error' && !load" class="mt-2">
                                            <button @click="GetCollectionListApi(stateUserInfoData)" class="px-8 py-2 bg-indigo-600 hover:bg-indigo-400 rounded-md text-white">Refresh</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Zkloud -->
                            <div class="container bg-white px-3 m-2 w-1/3 rounded-md mx-auto">
                                <div class="py-2 md:p-4">
                                    <div class="h-full rounded-lg text-center">
                                        <h1 class="title-font text-md md:text-lg font-medium text-gray-900">Zkloud</h1>
                                    </div>
                                    <h1 class="font-medium text-center font-medium text-2xl text-teal-800">--</h1>
                                    <div class="text-xs md:text-lg text-center justify-center left-0 w-full">
                                        of 5GB used
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col md:flex-row 2xl:h-3/6 md:h-3/6 lg:h-full sm:h-5/6 ml-1 mb-2">
                            <!-- Recommendation -->
                            <div class="flex flex-col w-full md:w-2/3 h-full">
                                <div class="flex flex-col h-full gap-1 m-1 justify-start ">
                                    <div class="flex justify-center items-center h-1/4 border-0 bg-gradient-to-br from-pink-600 via-purple-600 to-indigo-900 rounded-md">
                                        <a href="/">
                                        <div class="title font-bold text-gray-50 text-xs md:text-base hover:text-green-300">Need inspiration?</div>
                                        </a>
                                        <p class="text-white text-xs md:text-sm p-2"> Read articles from 15 million authors </p>
                                    </div>
                                    <div class="mt-2 flex flex-col gap-2 h-3/4 ">
                                        <div class="flex h-1/6 flex-row justify-start ">
                                            <div class="w-4/5 flex flex-row ">
                                                <div v-for="(bot, index) in bot_list.slice(0,5)" :key="bot" :class="{'w-1/4' : botCount === 4, 'w-1/3' : botCount === 3, 'w-1/5' : botCount == 5, 'w-1/2' : botCount === 2, 'w-full' : botCount === 1}" class="h-full ">
                                                    <a class="capitalize bg-white text-xs text-center font-normal px-2 py-2 block leading-normal cursor-pointer hover:text-white hover:bg-green-500 truncate " :class="{
                                                    'text-white bg-green-500 ' :active_bot === bot.id || (active_bot === null && index === 0),
                                                    'rounded-l-md': index === 0,}" @click="toggleTab(bot.id)">
                                                        {{ bot.bot_name }} 
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="w-1/5 ">
                                                <a class="w-full bg-white rounded-r-md text-xs text-center font-normal text-teal-600 bg-white px-2 py-2 block leading-normal cursor-pointer hover:bg-green-500 hover:bg-black truncate">
                                                    More
                                                </a>
                                            </div>
                                        </div>

                                        <div v-if="recommendedArticles.length " class="w-full h-4/6 md:h-5/6  bg-white  rounded rounded-md 
                                    overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-indigo-600 hover:scrollbar-thumb-indigo-500 ">

                                            <div v-for="article in recommendedArticles" :key="article" >
                                               
                                                <div v-if="active_bot == article.bot_id" class="flex flex-col px-2 ">
                                                    <div v-if="article.title" class="text-md font-bold text-blue-600 font-medium p-2 md:text-lg"> 
                                                        <a class="hover:text-blue-900" :href="'/' + article.title[0] + '/' + article.airflow_id">{{ article.title }}</a>  
                                                        <h1 class="text-xs">Publication Date :{{article.pub_date}}</h1>
                                                        </div>
                                                    <div v-else>Nothing to show</div>
                                                    <div v-if="article.abstract_text" class=" text-xs lg:text-base text-gray-800 p-2 2xl:text-md ">
                                                        <div class="flex-row md:hidden">
                                                         {{ article.abstract_text.slice(0,250) }}........<a class="text-blue-900" :href="'/ref/'+article.airflow_id">More</a>
                                                         </div>
                                                         <div class="hidden md:flex .">
                                                             {{ article.abstract_text }}
                                                         </div>
                                                         </div>
                                                    <div v-else class="p-5" > 
                                                        
                                                        Use this Link for more Info... <span> <a class="text-blue-500 " :href="article.html_url">Click</a></span>
                                                        
                                                        </div>
                                                </div>
                                            </div>
                                            

                                        </div>
                                        <div v-else class="bg-white  shadow shadow-sm   rounded rounded-md">
                                            <div class="flex flex-col justify-center items-center p-2">
                                                <img class=" w-36 h-48 lg:w-56 lg:h-56  animate-pulse" src="../assets/loading.svg" />
                                                <div class="text-center text-xl font-medium">
                                                    No records to show
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <!-- Todo -->
                            <div class="w-full md:w-2/6 mx-1 lg:mx-2 h-full ">
                                <div class="bg-white rounded-md  h-full py-5 lg:py-0  shadow px-1 ">
                                    <!-- Todo Header -->
                                    <div class="flex justify-between h-12 items-center text-sm px-4">
                                        <div class="flex-none title font-bold text-teal-800 text-lg">Todo</div>
                                        <div class="flex-grow "></div>
                                        <button v-on:click="seen = !seen" class="flex justify-center w-8 h-8 p-1 rounded-full items-center bg-green-200 focus:outline-none hover:bg-green-500 text-green-600 hover:text-white">
                                            <svg class="w-4 h-4 focus:outline-none" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M12 4v16m8-8H4"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <!-- Todo content -->
                                    <div v-if="!allTodos" class="mt-10">
                                        <img class="h-20 mx-auto" src="../assets/loading-animate.gif" alt="loading...">
                                    </div>
                                    <!-- If no records found for todo api -->
                                    <div class="flex flex-col mt-10" v-if="allTodos == 'Nothing to show'">
                                        <img src="../assets/NoData.svg" class="h-24 w-24 mx-auto object-cover" alt="No data">
                                        <h1 class="text-center text-lg">No records to show</h1>
                                    </div>
                                    <!-- 500 Error -->
                                    <div v-if="allTodos =='500 Error'" class="flex mt-2 justify-center">
                                        <button @click="fetchTodos(stateUserInfoData)" class="px-8 py-2 bg-indigo-600 hover:bg-indigo-400 rounded-md text-white">Refresh</button>
                                    </div>
                                    <!-- if no issues -->
                                    <div v-if="typeof(allTodos) == 'object'" class="w-full pl-1 h-5/6 overflow-y-auto lg:overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-teal-300 scrollbar-track-teal-50">
                                        <div v-for="todo in allTodos" :key="todo" class="flex flex-row justify-around w-full mr-3 my-2 py-1 border rounded-md hover:border-teal-400 ">
                                            <div class="flex flex-row w-6 justify-center items-center bg-white rounded-md border-r border-gray-200">
                                                <input class="rounded rounded-xs ring-1 focus:ring-1 ring-purple-500 focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" type="checkbox" v-model="todo.status" v-on:click="checkboxToggleFunc(todo)">
                                            </div>
                                            <div class="flex flex-row w-9/12 h-full justify-between items-center bg-gray-50 rounded-tr-md rounded-br-md py-1 ">
                                                <!-- <div class="h-full w-11/12 flex flex-col text-sm font-semibold text-teal-600 truncate"> -->
                                                <p class="capitalize truncate text-teal-600">
                                                    {{ todo.name }}
                                                </p>
                                                <!-- </div> -->
                                            </div>
                                            <div class="my-auto mr-2">
                                                <font-awesome-icon v-on:click="deleteTodoFunc(todo)" class="text-gray-400 hover:text-red-400 text-lg" :icon="['fas', 'times-circle']" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- Articles you may like Article -->
                        <div class="flex flex-col 2xl:h-2/6 gl:h-2/6 md:2/6 lg:mb-12 sm:w-full sm:h-2/6
                scrollbar-thin scrollbar-teal-teal-200 scrollbar-thumb-teal-300 ">

                            <h1 class="p-2 ml-2 max-w-auto title font-bold text-teal-800 text-lg">
                                Articles you may like
                                
                            </h1>
                            
                            <div v-if="random.provided_by === 'No article'" class="flex justify-center bg-white mx-2 my-2 rounded rounded-md h-4/6 shadow shadow-sm text-center">
                                <div class="flex flex-col justify-center items-center">
                                    <img src="../assets/NoData.svg" class="h-28 w-28 object-cover" alt="No data">
                                    <h1 class="text-xl">No records to show</h1>
                                </div>
                            </div>

                            <div v-else class="flex flex-col md:flex-row justify-between items-start gl:p-1 ">
                                <div class="w-full h-full m-2 p-1  " v-for="update in updates" :key="update">
                                    <div class="h-full" v-on:click="botArticleClick(update)">
                                        <div class="bg-white  shadow p-3 h-full rounded-md border hover:border-green-500 hover:text-indigo-900 hover:bg-white cursor-pointer">
                                            <h3 class="tracking-widest text-indigo-700 lg:text-sm font-medium title-font">Bot</h3>
                                            <h2 class="text-lg md:text-xl text-gray-900 font-medium title-font lg:text-lg sm:text-sm gl:text-lg truncate mb-2 capitalize">{{ update.bot_name}}</h2>
                                            <p class=" text-sm lg:text-base ">{{ update.title }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Calender -->
                    <div class=" w-full md:w-1/4 2xl:w-2/12 gl:w-3/12 justify-center">

                        <div class="mt-2 h-auto  rounded-md">

                            <v-date-picker is-expanded @dayclick="onDayClick" :attributes='attrs' mode="date" />

                        </div>

                        <div class="flex flex-col bg-white rounded rounded-md mt-2">
                            <div class="flex flex-row p-2 justify-between">
                                <h1 class="text-center">Event</h1>
                                <button v-on:click="seen = !seen" class="flex justify-center w-8 h-8 p-1 rounded-full items-center bg-green-200 focus:outline-none hover:bg-green-500 text-green-600 hover:text-white">
                                    <i v-if="seen">
                                        <font-awesome-icon class="text-green-500 hover:text-white text-xl  cursor-pointer " :icon="['fas', 'chevron-up']" />
                                    </i>
                                    <i v-else class="fas chevron-down">
                                        <font-awesome-icon class="text-green-500 hover:text-white text-xl  cursor-pointer " :icon="['fas', 'chevron-down']" />
                                    </i>

                                </button>
                            </div>

                            <div @click="ClickFilter()" class=" relative w-full py-2">
                                <button  class="bg-green-400 text-white font-semibold py-2 px-4 rounded inline-flex justify-between items-center w-full mx-2">
                                    <span v-text="choosedCollection" class="mr-1"></span>
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                                </button>
                                <ul v-if="isOpen" class="block absolute  text-gray-700 pt-1 w-full">
                                    <div v-for="collection in CollectionListInfo.data" :key="collection">

                                        <li @click="chooseCollection(collection)"><a class="rounded-t bg-gray-200 hover:bg-green-400 py-2 px-4 block whitespace-no-wrap capitalize mx-2 border border-gray-300" href="#">{{collection.name.split(":")[0]}}</a></li>
                                    </div>
                                </ul>
                            </div>

                            <form v-if="seen" @submit.prevent="postTodoData" method="post" class="mx-2">
                                <input hidden type="text" name="collectionId" v-model="collectionForTodo" class="w-full border-teal-500 shadow rounded-md text-xs placeholder-gray-500" placeholder="collectionId">
                                <input disabled type="text" name="date" v-model="calenderDay" class="select-none w-full border-teal-500 shadow rounded-md m-1 p-2 placeholder-gray-500" placeholder="Date">
                                <input type="text" name="content" class="w-full border border-teal-500 shadow rounded-md m-1 p-2 text-sm placeholder-gray-500" required placeholder="Name">
                                <input type="text" name="name" class="w-full border border-teal-500 shadow rounded-md m-1 p-2 text-sm placeholder-gray-500" placeholder="Description (optional)">
                                <button class="bg-transparent text-teal-600 text-center font-semibold border border-teal-600 hover:bg-teal-500 hover:text-white hover:border-transparent rounded m-2 py-2 px-8">Add</button>
                            </form>

                            <div v-if="todayEvents.length">
                                <div v-for="todo in todayEvents" :key="todo" class="p-2 relative bg-indigo-500 m-1 text-white rounded-md text-sm">
                                    <div class="absolute top-0 right-3 flex items-center justify-between w-5 h-5 mt-2 mr-2 text-gray-500 rounded hover:text-gray-700 group-hover:flex">
                                        <input class="rounded rounded-xs ring-1 focus:ring-1 ring-purple-500 focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" type="checkbox" v-model="todo.status" v-on:click="checkboxToggleFunc(todo)">
                                    </div>
                                    <i @click="routeTask(todo.id)" class="far fa-edit cursor-pointer mr-1 text-white text-lg"></i>
                                    <span class="rounded-full bg-gray-100 text-gray-500 text-xs font-medium px-2 py-1 capitalize">{{ todo.content }}</span>
                                    <div class="my-1">{{ todo.name }}</div>
                                    <div class="flex items-center">
                                        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                        </svg>
                                        <span class="ml-1 leading-none">{{ todo.date }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <h1 class="p-2 text-center bg-gray-300 m-1 rounded-md">No Events</h1>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div v-if="event_status != '' " class="relative w-full -mt-5">
                <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                    <p class="text-white font-bold">{{event_status}}</p>
                    <font-awesome-icon class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
                </div>
            </div>

        </main>
    </div>

</template>

<script>

import {
    mapActions,
    mapGetters
} from "vuex";
import axios from "axios";
import {
    onErrorCaptured,
    ref
} from 'vue';
import moment from 'moment';

import LeftNavigation from '@/components/LeftNavigation'

export default {
    name: "Dashboard",
    inject: ["Gstore"],
    components: {

        LeftNavigation
    },
    setup() {
        const error = ref(null);
        const choosedCollection = ref(null)
        onErrorCaptured((e) => {
            error.value = e;
            return true;
        });
        return {
            error,
            choosedCollection
        }
    },
    data: () => {
        return {
            stateUserInfoData: null,
            load: false,
            //For Todo
            seen: false,
            calenderDay: '',
            todayEvents: [],
            collectionForTodo: null,

            //For Bots
            botCount: null,
            collectionCount: null,
            bot_list: [],
            botModifiedDate: null,
            active_bot: null,

            recommendedArticles: [],

            random: [],
            event_status: "",
            updates: [],
            isOpen : false,

        }
    },
    computed: mapGetters(["allTodos", "attrs", "CollectionListInfo"]),

    methods: {
        
        // Store functions for todos
        ...mapActions(["fetchTodos", "addTodo", "deleteTodo", "checkboxToggle", "markTodo", "updateTodayDate"]),

        // set active bot
        toggleTab: function (id) {
            this.active_bot = id;
        },
        ClickFilter() {
            this.isOpen = !this.isOpen;
        },
        routeTask: function (id) {
            this.$router.push("/tasks/" + id)
        },

        // Date format
        frontEndDateFormat: function (date) {
            return moment(date).format("MMM D, yyyy h:mm a");
        },
        formatDate: function (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('-');
        },

        // Creating new todo 
        postTodoData: async function (submitEvent) {
            let userInfo = this.$store.getters.StateUserInfo;
            let data = {
                "name": submitEvent.target.elements.name.value,
                "status": false,
                "date": submitEvent.target.elements.date.value,
                "content": submitEvent.target.elements.content.value,
                "collection": submitEvent.target.elements.collectionId.value,
            }
            let bucket_data = {
                obj: data,
                user: userInfo.id,
                key: userInfo.key
            };
            await this.addTodo(bucket_data)
            this.seen = !this.seen
            this.todayEvents.push(bucket_data.obj)
            await this.fetchTodos(bucket_data)
            await this.markTodo()
        },

        botArticleClick(updates) {

            this.$router.push('/' + updates.title + '/' + updates.id);
        },
        // Deleting todo
        deleteTodoFunc: async function (obj) {
            if (confirm("Are you sure you want to delete")) {
                let userInfo = this.$store.getters.StateUserInfo;
                let bucket_data = {
                    obj: obj,
                    user: userInfo.id,
                    key: userInfo.key
                }
                await this.deleteTodo(bucket_data)
                // this.updateDate(obj.date ,'delete')
                this.fetchTodos(bucket_data)
                this.markTodo()

            }
        },
        // Checkbox toggle
        checkboxToggleFunc: async function (obj) {
            let userInfo = this.$store.getters.StateUserInfo;
            let bucket_data = {
                obj: obj,
                user: userInfo.id,
                key: userInfo.key
            }
            await this.checkboxToggle(bucket_data)
            await this.fetchTodos(bucket_data)
        },
        // Calender click
        onDayClick: function (day) {
            this.calenderDay = day.id
            let userInfo = this.$store.getters.StateUserInfo;
         axios.get(`https://api.zealbots.com/api/v1/calendar/view/?date=${this.calenderDay}&Key=${userInfo.key}&format=json`)
            .then(response => {
            if (response && response.data.data) {
                this.todayEvents.length = 0;
                response.data.data.forEach((element) => this.todayEvents.push(element));
            } else if (response && response.data.error) {
                this.todayEvents.length = 0;
            } else if (response == undefined) {
                const response = axios.get(`https://api.zealbots.com/api/v1/calendar/view/?date=${this.calenderDay}&Key=${userInfo.key}&format=json`)
                if (response && response.data.data) {
                    this.todayEvents.length = 0;
                    response.data.data.forEach((element) => this.todayEvents.push(element));
                } else if (response && response.data.error) {
                    this.todayEvents.length = 0;
                } else {
                    this.Gstore.flashMessage = 'Something went wrong'
                }
            } })
        },
        GetBotListApi: async function (data) {
            await axios.get(`/zbot_article/?user=${data.id}&fields=id,articles,bot&Key=${data.key}&format=json`)
                .then(response => {
                    if (response && response.data) {
                        response.data.data.forEach((element) => {
                            this.bot_list.push(element.bot)
                        })
                        this.botCount = response.data.count
                        this.botModifiedDate = this.frontEndDateFormat(this.bot_list[this.bot_list.length - 1].last_run)
                    } else if (response && response.error) {
                        this.botCount = response.error
                    } else if (response == undefined) {
                        this.botCount = "500 Error"
                    } else {
                        this.botCount = 'Server error'
                    }
                })
        },
        GetCollectionListApi: async function (data) {
            this.load = true
            await axios.get(`/collection/?author=${data.id}&fields=id,name,paper&Key=${data.key}&format=json`)
                .then(response => {
                    if (response && response.data) {
                        this.collectionCount = response.data.count
                        this.load = false
                    } else if (response && response.error) {
                        this.collectionCount = response.error
                        this.load = false
                    } else if (response == undefined) {
                        this.collectionCount = "500 Error"
                        this.load = false
                    } else if (!navigator.onLine) {
                        this.collectionCount = "no internet, please check your internet connection"
                    }
                })
        },
        GetRecoArticles: async function (bot_list) {
            bot_list.forEach(async (element) => {
                let response = await axios.get(`https://raas.zealbots.com/recommend/daily/bots/${element.id}`)
                if (response && response.data && response.data[0]) {
                    this.active_bot = response.data[0].bot_id
                    this.recommendedArticles.push(response.data[0])
                }
            })
        },
        chooseCollection: function (data) {
            this.collectionForTodo = data.id

            this.choosedCollection = data.name.split(":")[0]
        },

        async logout() {
            await this.$store.dispatch("LogOut");
            this.$router.push("/login");
        },
    },

    created: async function () {
        var userInfo = this.$store.getters.StateUserInfo;
        this.stateUserInfoData = userInfo

        let bucket_data = {
            user: userInfo.id,
            key: userInfo.key
        }
        if (this.CollectionListInfo.data) {
            this.chooseCollection(this.CollectionListInfo.data[0])

        }
                axios.get(`https://api.zealbots.com/api/v1/random_article/?Key=${userInfo.key}&format=json`)
            .then((response) => {
              
                if (response && response.data) {
                    this.random = response.data;
                    
                }
                this.updates = response.data.inbox

            })
            .catch(error => {
                console.warn(error);
            });

        // this.chooseCollection(collection)
        await this.GetBotListApi(userInfo)
        await this.GetCollectionListApi(userInfo)
        await this.GetRecoArticles(this.bot_list)

        // default today events set to current date
        this.updateTodayDate(new Date())
        this.calenderDay = this.formatDate(new Date())
        var day = {
            'id': this.calenderDay
        };
        this.onDayClick(day)
        

        // Todo fetch
        this.fetchTodos(bucket_data)
        this.markTodo()
        //Recommedation
        if (this.bot_list.length > 0) {
            this.toggleTab(this.bot_list[0].id)
        }
        // setInterval(() => {
        //     this.fetchUsedSize(data)
        // }, 5000)
    },
};
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
    display: block;
}
</style>
