<template>
  <Navbar />
  <div class="h-auto min-h-screen flex flex-col mt-20">
    <div class="w-full lg:w-6/12 flex flex-row self-center justify-center text-3xl font-bold text-blue-500 px-4">
      User datapolicy
    </div>

    <div class="w-full lg:w-6/12 flex flex-row self-center justify-start px-4 py-2 text-justify text-gray-700">
      &emsp; ZealBots Services, including zealbots Sign-In, are part of an authentication and authorization framework 
      that gives you, the developer, the ability to connect directly with zealbots users when you would like to request 
      access to zealbots user data. The policy below, as well as the zealbots APIs Terms of Service, govern the use of 
      zealbots API Services when you request access to zealbots user data. Please check back from time to time as these 
      policies are occasionally updated.
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Accurately represent your identity and intent
      </p>
      <p class="text-gray-700">
        &emsp; If you wish to access zealbots user data you must provide zealbots users and zealbots with clear and accurate 
        information regarding your use of zealbots API Services. This includes, without limitation, requirements to accurately represent.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        What data are you requesting?
      </p>
      <p class="text-gray-700">
        &emsp;You must provide clear and accurate information explaining the types of data being requested. In addition, 
        if you plan to access or use a type of user data that was not originally disclosed in your privacy policy when a 
        zealbots user initially authorized access, you must update your privacy policy and prompt the user to consent to 
        any changes before you may access that data.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Why are you requesting zealbots user data?
      </p>
      <p class="text-gray-700">
        &emsp;Be honest and transparent with users when you explain the purposefor which your application requests user data. 
        If your application requests data for one reason but the data will also be utilized fora secondary purpose, you must 
        notify zealbots users of both usecases. As a general matter, users should be able to readily understand the value of 
        providing the data that your application requests, as well as the consequences of sharing that data with your application.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Be transparent about the data you access with clear and prominent privacy disclosures
      </p>
      <p class="text-gray-700">
        &emsp;You must publish a privacy policy that fully documents how your application interacts with user data. You must list 
        the privacy policy URL in your OAuth client configuration when your application is made available to the public.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Your Privacy Policy and all in-product privacy notifications should be accurate, comprehensive, and easily accessible.
      </p>
      <p class="text-gray-700">
        &emsp;Your privacy policy and in-product privacy notifications must thoroughly disclose the manner in which your application 
        accesses, uses, stores, or shares zealbots user data. Your use of zealbots user data must be limited to the practices explicitly 
        disclosed in your published privacy policy, but you should consider the use of additional in-product notifications to ensure that 
        users understand how your application will handle user data. If you change the way your application uses zealbots user data, 
        you must notify users and prompt them to consent to an updated privacy policy before you make use of zealbots user data in a new 
        way or for a different purpose that originally disclosed.
      </p>  
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Disclosures about data use should be prominent and timely.
      </p>
      <p class="text-gray-700">
        &emsp; Your privacy policy and any in-product notifications regarding data use should be prominently displayed in your application 
        interface so that users can find this information easily. Where possible, disclosures about data use should be timely and shown in context.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Request relevant permissions
      </p>
    
      <p class="text-gray-700">
        &emsp; Permission requests should make sense to users, and should be limited to the critical information necessary to implement your application.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Don't request access to information that you don't need.
      </p>
      <p class="text-gray-700">
        &emsp; You may only request access to the zealbots user data that is necessary to implement existing features or services in your application. 
        Don't attempt to "future proof" your access to user data by requesting access to information that might benefit services or features that have 
        not yet been implemented.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Request permissions in context where possible.
      </p>
      <p class="text-gray-700">
        &emsp; Request access to user data in context (via incremental auth) whenever you can, so that users understand why you need the data.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Deceptive or unauthorized use of zealbots API Services is prohibited
      </p>
      <p class="text-gray-700">
        &emsp;You are strictly prohibited from engaging in any activity that may deceive users or zealbots about your use of zealbots API Services. 
        This includes without limitation the following requirements:
      </p>  
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Do not misrepresent what data is collected or what you do with zealbots user data.
      </p>

      <p class="text-gray-700">
        &emsp;Be up front with users so that they can make an informed decision to grant authorization. You must disclose all user data that you access, 
        use, store, delete, or share, as well as any actions youtake on a user's behalf.
      </p>
      <p class="text-gray-700 py-1">
        You are not permitted to access, aggregate, or analyze zealbots user data if the data will be displayed, sold, or otherwise distributed to a third party 
        conducting surveillance.
      </p>
      <p class="text-gray-700 py-1">
        Overall there should be no surprises for zealbots users: hidden features, services, or actions that are inconsistent with the marketed purpose of your 
        application may lead zealbots to suspend your ability to access zealbots API Services.
      </p>  
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Do not mislead zealbots about an application's operating environment.
      </p>
      <p class="text-gray-700">
        &emsp;You must accurately represent the environment in which the authentication page appears. For example, don't claim to be an Android application in 
        the user agent header if your application is running on iOS, or represent that your application's authentication page is rendered in a desktop browser 
        if instead the authentication page is rendered in an embedded web view.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Do not use undocumented APIs without express permission.
      </p>
      <p class="text-gray-700">
        &emsp;Don't reverse engineer undocumented zealbots API Services or otherwise attempt to derive or use the underlying source code of undocumented zealbots 
        API Services. You may only access data from zealbots API Services according to the means stipulated in the official documentation of that API Service, as 
        provided on zealbots's developer site.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Do not make false or misleading statements about any entities that have allegedly authorized or managed your application.
      </p>
      <p class="text-gray-700">
        &emsp;You must accurately represent the company, organization, or other authority that manages your application. Making false representations about client 
        credentials to zealbots or zealbots users is grounds for suspension.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Child-directed apps
      </p>
      <p class="text-gray-700">
        &emsp;The Children's Online Privacy Protection Act, or COPPA, applies to websites, apps, and services directed to children under the age of 13 and general 
        audience apps, websites, or services with users known to be under the age of 13. While child-directed apps may use some zealbots services, developers are 
        responsible for using these services according to their obligations under the law. Please review the FTC's guidance on COPPA (including information about 
        the differences between mixed audience apps and apps directed primarily to children from the FTC's website) and consult with your own legal counsel.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify ml-4">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Child-directed apps:
      </p>
      <p class="text-gray-700">
        &emsp;If your application is directed primarily at children, it should not use zealbots Sign-In or any other zealbots API Service that accesses data associated 
        with a zealbots Account. This restriction includes zealbots Play Games Services and any other zealbotsAPI Service using the OAuth technology for authentication 
        and authorization.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify ml-4">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Mixed audience apps:
      </p>
      <p class="text-gray-700">
        &emsp;Applications that are mixed audience shouldn't require users to sign in to a zealbots Account, but can offer, for example, zealbots Sign-In or zealbots 
        Play Games Services as an optional feature. In these cases, users must be able to access the application in its entirety without signing into a zealbots Account.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Maintain a secure operating environment
      </p>
      <p class="text-gray-700">
        &emsp;You must take reasonable and appropriate steps to protect all applications or systems that make use of zealbots API Services against unauthorized or unlawful 
        access, use, destruction, loss, alteration, or disclosure.
      </p> 
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Enforcement
      </p>
      <p class="text-gray-700">
        &emsp; You must access zealbots API Services in accordance with the zealbots APIs Terms of Service. If you are found to be out of compliance with the zealbots APIs 
        Terms of Service, this zealbots API Services: User Data Policy, or any zealbots product policies that are applicable to the zealbots API Service you are using, zealbots 
        may revoke or suspend your access to zealbots API Services and other zealbots products and services. Your access to zealbots API Services may also be revoked if your 
        application enables end-users or other parties to violate the zealbots APIs Terms of Service and/or zealbots policies
      </p>
    </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Navbar,
    Footer,
  }
} 
</script>

<style scoped>

</style>