<template>
<div class="fixed flex bg-black bg-opacity-70 justify-center items-center inset-0 z-50 outline-none overflow-x-hidden overflow-y-auto">
  <div class="w-5/6 h-4/6 md:w-1/3 md:h-3/5 rounded-lg">
    <img @click="$emit('cancelClick')" class="w-12 float-right -mr-8 cursor-pointer" src="https://img.icons8.com/fluency/96/000000/close-window.png"/>
  
  
    <div class="flex flex-col clear-both justify-center rounded-t-lg w-full h-1/4 p-4 bg-white">
      
      
      <img class="mx-auto w-10" :src="headImage"/>
      <h1 class="text-center font-bold text-xl text-indigo-600">{{headTitle}}</h1>
        
      
    </div>
    <div class="body-popup flex flex-col rounded-b-lg py-1 md:py-12 px-1 md:px-6 bg-gray-100">
      <h1 class="mb-8 text-left word-break text-xs md:text-lg font-semibold w-3/4 mx-auto ">
        {{bodyContent}}
        <span v-if="highlightContent" class="block text-pink-700">
            {{highlightContent}}
        </span>
      </h1>
      <div class="flex gap-4 justify-between w-3/4 mx-auto">
        
        <button @click="$emit('cancelClick')" class="cancel-btn w-2/4 text-sm border border-indigo-400 font-semibold rounded bg-white px-4 py-2 ">{{cancelBtnText}}</button>
        <button @click="$emit('confirmClick')" class="yes-btn w-2/4 text-sm border border-indigo-400 font-semibold rounded bg-white px-4 py-2 hover:bg-gray-200">{{yesBtnText}}</button>
      </div>
    </div> 
  
    </div>
</div>
</template>

<script>
export default {
    props: ["headTitle", "bodyContent", "highlightContent", "headImage", "cancelBtnText", "yesBtnText"],
    emits: ["cancelClick", "confirmClick"]
}
</script>

<style scoped>
.body-popup {
    background: #f8f8f8;
}

.cancel-btn {
    border: .25px solid lightgray;
}

.cancel-btn:hover {
    color: white;
    background: gray;
}

.yes-btn {
    background: #f62c7d;
    border: none;
    color: white;
}

.yes-btn:hover {
    background: rgba(246, 44, 125, 0.8);
}
</style>
