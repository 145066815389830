<template>
<div class="flex flex-row h-screen w-screen bg-gray-200">
    <LeftNavigation selected="10" />
    <main class="flex-1 bg-gray-200 dark:bg-gray-900 overflow-auto transition duration-500 ease-in-out">
        <div class="flex flex-col h-screen relative bg-indigo-600">
            <!-- <div class="flex items-center w-full my-2 h-3/12">
                <input v-model="searchbox" class="flex items-center h-full w-3/12 px-4 ml-2 text-sm bg-gray-200 rounded-full focus:outline-none focus:ring" type="search" placeholder="Search for anything…">
                <div class="ml-10 text-white">
                    <a class="mx-4 text-sm text-lg border-b-2 font-semibold text-indigo-100" href="#">Projects</a>
                    <a class="mx-4 text-sm font-semibold text-white hover:text-lg hover:text-indigo-100" href="#">Activity</a>
                </div>
            </div> -->
            <div class="flex flex-col-reverse md:flex-row h-10/12 md:h-full bg-gradient-to-tr from-blue-200 via-indigo-200 to-pink-200 overflow-visible md:overflow-hidden">

                <div class="2xl:w-10/12 gl:w-9/12 h-full  mt-3 md:mt-0">
                    <div v-if="!allTodos" class="mt-10">
                        <img class="h-20 mx-auto" :src="$static.path(require('@/assets/loading-animate.gif'))" alt="loading...">
                    </div>
                    <div class="flex flex-col mt-10" v-if="allTodos == 'Nothing to show'">
                        <img :src="$static.path(require('@/assets/NoData.svg'))" class="h-24 w-24 mx-auto object-cover" alt="No data">
                        <h1 class="text-center text-lg">No records to show</h1>
                    </div>
                    <div v-if="allTodos =='500 Error'" class="flex mt-2 justify-center">
                        <button @click="fetchTodos(stateUserInfoData)" class="px-8 py-2 bg-indigo-600 hover:bg-indigo-400 rounded-md text-white">Refresh</button>
                    </div>
                    <div v-if="typeof(allTodos) == 'object'" class="flex flex-row  flex-grow h-full p-2 space-x-2 overflow-x-scroll mr-2
                        scrollbar scrollbar-thin scrollbar-thumb-indigo-600 hover:scrollbar-thumb-indigo-500">
                        <div v-for="collection in collection" :key="collection" class="flex flex-col flex-shrink-0 2xl:w-1/5 xl:w-1/3
                            scrollbar scrollbar-thin scrollbar-thumb-indigo-600 hover:scrollbar-thumb-indigo-500">
                            <div class="flex items-center flex-shrink-0 h-10 px-2">
                                <span class="text-md font-semibold">{{collection[0].collection}}</span>
                                <span class="flex items-center justify-center w-8 h-8 ml-2 text-md font-semibold text-indigo-600 bg-white rounded bg-opacity-40">{{collection.length}}</span>
                                <button @click="openInput(collection[0].collection);" class="flex items-center justify-center w-8 h-8 ml-auto mr-2 bg-indigo-500 text-indigo-100 rounded hover:bg-indigo-800 hover:text-indigo-100">
                                    <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                    </svg>
                                </button>
                            </div>
                            <!-- collection form -->
                            <form v-if="collectionForTodo == collection[0].collection && seenInput" @submit.prevent="postTodoData" method="post" class="flex flex-col justify-center m-2">
                                <input hidden type="text" name="collectionId" v-model="collectionForTodo" class=" w-full border-teal-500 shadow rounded-md text-xs placeholder-gray-500" placeholder="collectionId">
                                <input type="date" name="date" class="select-none w-full border-teal-500 shadow rounded-md m-1 p-2 placeholder-gray-500" placeholder="Date" required>
                                <input type="text" name="content" v-model="collectionInput" class="w-full border border-teal-500 shadow rounded-md m-1 p-2 text-sm placeholder-gray-500" required placeholder="Name">
                                <input type="text" name="name" class="w-full border border-teal-500 shadow rounded-md m-1 p-2 text-sm placeholder-gray-500" placeholder="Description">
                                <button class="w-1/3 text-white bg-indigo-600 text-center font-semibold border border-indigo-600 hover:bg-indigo-500 
                                hover:text-white hover:border-transparent rounded m-2 py-2 px-8">Add</button>
                            </form>
                            
                            <div v-for="collection in collection" :key="collection.id" class="flex flex-col pr-2 ">

                                <div :class="[highlight.includes(collection.id) && highlightVisible ? 'bg-indigo-500' : '']" class="relative flex flex-col items-start p-4 mt-3 bg-white rounded-lg cursor-pointer bg-opacity-90 group hover:bg-opacity-100">
                                    <div class="absolute top-1 right-0 flex items-center justify-center w-5 h-5 mt-3 mr-4 text-gray-500 rounded hover:bg-gray-200 hover:text-gray-700 group-hover:flex">
                                        <input class="rounded rounded-xs ring-1 focus:ring-1 ring-purple-500 focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" type="checkbox" v-model="collection.status" v-on:click="checkboxToggleFunc(collection)">
                                    </div>
                                    <div class="flex items-center w-full text-xs text-gray-400">
                                        <i @click="editable = !editable; editInput(collection)" :class="[highlight.includes(collection.id) && highlightVisible ? 'text-white' : '']" class="far fa-edit cursor-pointer mr-1 text-gray-500 text-lg"></i>
                                        <span v-if="collection.status" class="flex items-center h-6 px-3 text-xs font-semibold text-green-500 bg-green-100 rounded-full">Completed</span>
                                        <span v-else class="flex items-center h-6 px-3 text-xs font-semibold text-pink-500 bg-pink-100 rounded-full">Pending</span>
                                    </div>
                                    <div class="flex justify-between items-center w-full mt-3">
                                        <h4 class="text-sm" :class="[highlight.includes(collection.id) && highlightVisible ? 'text-white font-medium' : '']">
                                            {{collection.name}}
                                        </h4>
                                    </div>
                                    <div class="flex items-center w-full mt-3 text-xs font-medium text-gray-400">
                                        <div :class="[highlight.includes(collection.id) && highlightVisible ? 'text-white' : '']" class="flex items-center">
                                            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                            </svg>
                                            <span class="ml-1 leading-none">{{collection.date}}</span>

                                        </div>
                                    </div>
                                    <div class="absolute bottom-0 right-0 flex items-center justify-center w-5 h-5 mb-3 mr-4 text-gray-500 rounded-full hover:bg-gray-200 hover:text-gray-700 group-hover:flex">
                                        <font-awesome-icon v-on:click="deleteTodoFunc(collection)" class="text-gray-400 right-0 hover:text-red-500 text-lg" :icon="['fas', 'times-circle']" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" 2xl:w-2/12 gl:w-3/12 h-auto  mx-2 overflow-y-auto
                        scrollbar scrollbar-thin scrollbar-thumb-indigo-600 hover:scrollbar-thumb-indigo-500">

                    <div class="pt-2 h-auto rounded-md">
                        <v-date-picker is-expanded @dayclick="onDayClick" v-model="calenderDay" :attributes='attrs' mode="date" />
                    </div>

                    <div class="flex flex-col bg-white rounded rounded-md mt-2 h-2/3 pr-2 overflow-y-auto
                        scrollbar scrollbar-thin scrollbar-thumb-indigo-600 hover:scrollbar-thumb-indigo-500">

                        <div class="flex flex-row p-2 justify-between">
                            <h1 class="text-center">Event</h1>
                            <button v-on:click="seen = !seen" class="flex justify-center w-8 h-8 p-1 rounded-full items-center bg-green-200 focus:outline-none hover:bg-green-300 text-green-600 hover:text-white">
                                <i v-if="seen">
                                        <font-awesome-icon class="text-green-500 hover:text-white text-xl  cursor-pointer " :icon="['fas', 'chevron-up']" />
                                    </i>
                                    <i v-else class="fas chevron-down">
                                        <font-awesome-icon class="text-green-500 hover:text-white text-xl  cursor-pointer " :icon="['fas', 'chevron-down']" />
                                    </i>

                            </button>
                        </div>
                        <div class="">
                            <!-- event form -->
                            
                                <div v-for="collection in CollectionListInfo.data" :key="collection"> </div>

                                <div @click="ClickFilter()" class=" relative w-full py-2">
                                    <button class="bg-indigo-500 text-white font-semibold py-2 px-4 rounded inline-flex justify-between items-center w-full">
                                        <span v-text="choosedCollection" class="mr-1"></span>
                                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                                    </button>
                                    <ul v-if="isOpen" class="block absolute  text-gray-700 pt-1 w-full z-10">
                                        <div v-for="collection in CollectionListInfo.data" :key="collection">
                                            <li @click="chooseCollection(collection)"><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">{{collection.name.split(":")[0]}}</a></li>
                                        </div>
                                  
                                    </ul>
                                </div>
                            <form v-if="seen" @submit.prevent="postTodoData" method="post" class="mx-2">
                                <input disabled type="text" name="date" v-model="calenderDay" class="select-none w-full border-teal-500 shadow rounded-md m-1 p-2 placeholder-gray-500" placeholder="Date">
                                <input hidden type="text" name="collectionId" v-model="collectionForTodo" class="w-full border-teal-500 shadow rounded-md text-xs placeholder-gray-500" placeholder="collectionId">
                                <input type="text" name="content" class="w-full border border-teal-500 shadow rounded-md m-1 p-2 text-sm placeholder-gray-500" required placeholder="Name">
                                <input type="text" name="name" class="w-full border border-teal-500 shadow rounded-md m-1 p-2 text-sm placeholder-gray-500" placeholder="Description">
                                <button class="bg-transparent text-teal-600 text-center font-semibold border border-teal-600 hover:bg-teal-500 hover:text-white hover:border-transparent rounded m-2 py-2 px-8">Add</button>
                            </form>
                        </div>

                        <div class="flex flex-row p-2 m-1 rounded-md bg-indigo-200">
                            <input class="rounded rounded-xs ring-1 focus:ring-1 ring-purple-500 focus:ring-pink-500 h-4 w-4 mt-1 text-pink-600 border-gray-300" type="checkbox" v-model="highlightVisible">
                            <span class="ml-2">
                                Highlight selected date
                            </span>
                        </div>

                        <div v-if="todayEvents.length">
                            <div v-for="todo in todayEvents" :key="todo" class="p-2 relative bg-indigo-600 m-1 text-white rounded-md text-sm">
                                <div class="absolute top-0 right-3 flex items-center justify-between w-5 h-5 mt-2 mr-2 text-gray-500 rounded hover:text-gray-700 group-hover:flex">
                                    <input class="rounded rounded-xs ring-1 focus:ring-1 ring-purple-500 focus:ring-pink-500 h-4 w-4 text-pink-600 border-gray-300" type="checkbox" v-model="todo.status" v-on:click="checkboxToggleFunc(todo)">
                                </div>
                                <i @click="editable = !editable; editInput(todo)" class="far fa-edit cursor-pointer mr-1 text-white text-lg"></i>
                                <span class="rounded-full bg-gray-100 text-gray-500 text-xs font-medium px-2 py-1">{{ todo.content }}</span>
                                <div class="my-1">{{ todo.name }}</div>
                                <div class="flex items-center">
                                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                    </svg>
                                    <span class="ml-1 leading-none">{{ todo.date }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h1 class="p-2 text-center bg-gray-300 m-1 rounded-md">No Events</h1>
                        </div>
                    </div>
                </div>
            </div>
            <!-- edit form  -->
            <div v-if="editable" class="overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                <div class="bg-white relative md:w-2/3 lg:w-1/2 mx-auto rounded-lg">
                    <div @click="editable = !editable" class="absolute right-0 top-0 text-4xl cursor-pointer hover:bg-indigo-500  font-bold text-white bg-indigo-600 rounded-full w-10 m-4 h-10 text-center">x</div>
                    <form class="flex flex-col gap-4 mt-6 p-10" @submit.prevent="checkboxToggleFunc(editedContent)">
                        <input type="date" disabled v-model="editedContent.date" name="date" placeholder="yyyy-mm-dd" class="flex-1 p-4 text-sm bg-white placeholder-purple-300  outline-none text-gray-600 break-words overflow-hidden">
                        <input type="text" disabled v-model="editedContent.content" name="name" placeholder="Name" class="flex-1 p-4 bg-white placeholder-purple-300  outline-none text-gray-600 break-words overflow-hidden">
                        <textarea type="text" v-model="editedContent.name" name="content" placeholder="Description" rows="7" cols="60" class="flex-1 p-4 bg-white placeholder-purple-300 outline-none text-gray-600 overflow-ellipsis overflow-hidden"></textarea>
                        <button class="bg-indigo-600 hover:bg-indigo-500 block w-1/3 rounded-md py-4 text-white font-bold shadow">Submit</button>
                    </form>
                </div>
            </div>

            <!-- Notifications -->
            <div v-if="Gstore.flashMessage && notify" class="flex flex-row justify-between items-center bg-green-600 
            absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                <p class="text-white font-bold">{{Gstore.flashMessage}}</p>
                <font-awesome-icon class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
            </div>

        </div>
    </main>
</div>
</template>

<script>
import LeftNavigation from '@/components/LeftNavigation.vue';
import {
    mapActions,
    mapGetters,
    useStore
} from "vuex";
import axios from "axios";
import moment from 'moment';
import {
    ref
} from '@vue/reactivity';
import {
    computed,
    watchEffect
} from '@vue/runtime-core';

export default {
    setup() {
        const store = useStore();
        const choosedCollection = ref("General")
        const searchbox = ref("");
        const collection = ref("");
        const stateUserInfoData = computed(() => {
            return store.getters["StateUserInfo"]
        })
        watchEffect(() => {});
        return {
            searchbox,
            choosedCollection,
            collection,
            stateUserInfoData
        }
    },

    data() {
        return {
            seenInput: false,
            collectionInput: null,
            todayEvents: [],
            seen: false,
            calenderDay: '',
            highlight: [],
            highlightVisible: false,
            editable: false,
            editedContent: null,
            notify: false,
            collectionForTodo: null,
            isOpen: false,
        }
    },

    inject: ['Gstore'],

    components: {
        LeftNavigation
    },

    methods: {
        ...mapActions(["fetchTodos", "addTodo", "deleteTodo", "checkboxToggle", "markTodo"]),

        postTodoData: async function (submitEvent) {
            // ------------------------------------
            // let start = performance.now();
            // ------------------------------------
            this.seenInput = false
            let userInfo = this.$store.getters.StateUserInfo;
            let data = {
                "name": submitEvent.target.elements.name.value,
                "status": false,
                "date": this.formatDate(submitEvent.target.elements.date.value),
                "content": submitEvent.target.elements.content.value,
                "collection": submitEvent.target.elements.collectionId.value
            }
            let bucket_data = {
                obj: data,
                user: userInfo.id,
                key: userInfo.key
            }
            await this.addTodo(bucket_data)
            this.seen = false
            await this.fetchTodos(bucket_data)
            if (typeof (this.allTodos) == 'object') {
                this.collection = await this.groupByKey(this.allTodos, 'collection')
            }
            this.todoFilter()
            let day = {
                id: data.date
            }
            await this.onDayClick(day)
            this.markTodo()
            this.notify = true
            this.Gstore.flashMessage = "Todo added successfully"
            // ------------------------------------
            // let end = performance.now();
            // let time = end - start;
            // let sec = Math.floor((time/1000) % 60);
            // alert('Execution time in sec: ' + sec);
            // ------------------------------------
            setTimeout(() => {
                this.notify = false;
                this.Gstore.flashMessage = ""
            }, 3000)
        },
        deleteTodoFunc: async function (obj) {
            if (confirm("Are you sure you want to delete")) {
                let userInfo = this.$store.getters.StateUserInfo;
                let bucket_data = {
                    obj: obj,
                    user: userInfo.id,
                    key: userInfo.key
                }
                await this.deleteTodo(bucket_data)
                await this.fetchTodos(bucket_data)
                this.todayEvents = this.todayEvents.filter((element) => element.id !== bucket_data.obj.id)
                if (typeof (this.allTodos) == 'object') {
                    this.collection = await this.groupByKey(this.allTodos, 'collection')
                }
                this.todoFilter()
                this.markTodo()
                this.notify = true
                this.Gstore.flashMessage = "Todo deleted successfully"
                setTimeout(() => {
                    this.notify = false;
                    this.Gstore.flashMessage = ""
                }, 3000)

            }
        },
        checkboxToggleFunc: async function (obj) {
            let userInfo = this.$store.getters.StateUserInfo;
            let bucket_data = {
                obj: obj,
                user: userInfo.id,
                key: userInfo.key
            }
            await this.checkboxToggle(bucket_data)
            this.editable = false
            await this.fetchTodos(bucket_data)
            if (typeof (this.allTodos) == 'object') {
                this.collection = await this.groupByKey(this.allTodos, 'collection')
            }
            this.todoFilter()
            let day = {
                id: obj.date
            }
            await this.onDayClick(day)
        },
        editInput: function (data) {
            this.editedContent = {
                id: data.id,
                name: data.name,
                content: data.content,
                date: this.formatDate(data.date),
                status: true
            }
        },

        onDayClick: function (day) {
            this.calenderDay = day.id
            let that = this
            let userInfo = this.$store.getters.StateUserInfo;
            try {
                axios.get(`https://api.zealbots.com/api/v1/calendar/view/?date=${this.calenderDay}&Key=${userInfo.key}&format=json`)
                    .then((response) => {
                        if (response && response.data.data) {
                            that.todayEvents = new Array();
                            response.data.data.forEach((element) => that.todayEvents.push(element));
                            that.highlight.length = 0;
                            that.todayEvents.forEach((el) => that.highlight.push(el.id));
                        } else if (response && response.data.error) {
                            that.todayEvents = new Array();
                            that.highlight = new Array();
                        } else if (response == undefined) {
                            console.log("something went wrong")
                        }
                    })

            } catch (err) {
                console.warn(err)
            }
        },
        groupByKey: function (array, key) {
            return array.reduce((hash, obj) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, {
                    [obj[key]]: (hash[obj[key]] || []).concat(obj)
                })
            }, {})
        },
        openInput: function (collection) {
            if (collection == this.collectionForTodo) {
                this.seenInput = !this.seenInput;
                this.collectionForTodo = collection
            } else {
                this.seenInput = false;
                this.collectionForTodo = collection;
                this.seenInput = true
            }
        },
        frontEndDateFormat: function (date) {
            return moment(date).format("MMM D, yyyy h:mm a");
        },
        formatDate: function (date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('-');
        },
          ClickFilter() {
            this.isOpen = !this.isOpen;
        },
        chooseCollection: function (data) {
            this.collectionForTodo = data.id
            this.choosedCollection = data.name.split(":")[0]
        },
        async todoFilter() {
            let todoFilter = await this.allTodos.map(el => {
                this.CollectionListInfo.data.forEach(element => {
                    if (el.collection == element.id) {
                        return el.collection = element.name.split(":")[0]
                    }
                })
            })
            return todoFilter
        }
    },
    computed: mapGetters(["allTodos", "attrs", "CollectionListInfo"]),
    created: async function () {
        if (typeof (this.allTodos) != 'object') {
            this.fetchTodos(this.stateUserInfoData)
        }
        if (typeof (this.allTodos) == "object") {
            this.collection = await this.groupByKey(this.allTodos, 'collection')
            this.todoFilter()
        }
        this.calenderDay = this.formatDate(new Date())
        var day = {
            'id': this.calenderDay
        };
        this.onDayClick(day)
        if (this.$route.params.id != 0) {
            this.editedContent = await this.allTodos.filter((el) => el.id == this.$route.params.id)[0];
            this.editable = true
        }

    },
}
</script>

<style scoped>
/* .dropdown:hover .dropdown-menu {
    display: block;
} */
</style>
