<template>
<div class="flex flex-row h-screen w-screen bg-gray-200">
    <LeftNavigation selected="7" />
    <main class="h-screen flex-1 bg-gray-200 dark:bg-gray-900 overflow-y-auto transition duration-500 ease-in-out overflow-y-hidden">
        <div class="px-2 py-4 md:px-20 text-gray-700 dark:text-grey-500 transition duration-500 ease-in-out">
              
            <div class=" w-full flex flex-col gap-4 mt-10 px-10 py-10 lg:max-w-3xl bg-white relative rounded-md shadow-2xl">
                <img src="../assets/png/firefox-logo.png" alt="Firefox Logo" class="animate-bounce right-4 top-8 absolute w-10">
                <div class="flex flex-row">
                    <div>
                        <img src="../assets/png/zealbots-logo.png" alt="zealbots" class="w-14">
                    </div>
                    <div class="ml-4">
                        <h1 class="text-2xl font-medium pb-4 capitalize">Zealbots Clip
                            <strong class="animate-pulse text-teal-800">Free!</strong>
                        </h1>
                        <p class="text-sm text-gray-700">With Zealbots click, you can add your favorite research articles.<br>
                            Hurray! Within seconds, they are avaliable for your research works across all devices!.
                        </p>
                    </div>
                </div>
                <div v-if="!isFirefox">
                    <p class="text-red-500 text-medium"> Looks like you haven't Installed Firefox yet &nbsp; 
                        <span @click="$browser.openBrowser('https://www.mozilla.org/en-US/firefox/new/')" class="text-indigo-600 cursor-pointer" >Click here to install firefox</span>
                    </p>
                </div>
                <div class="flex flex-col">
                    <button v-if="isZealbot" class="bg-teal-700 text-white shadow-xl text-base py-2 px-6 font-bold rounded hover:bg-teal-500" @click="openFirefox()">
                    uninstall
                    </button>
                    <a href="https://addons.mozilla.org/en-US/firefox/addon/zealbots/" target="_blank" v-else 
                    class="w-full lg:w-1/3 cursor-pointer bg-teal-500 hover:bg-teal-700 text-white shadow-xl text-base py-2 px-6 font-bold rounded ">
                        Install plugin for firefox
                    </a>
                    <div class="py-4 pl-2 flex">
                        <i class="far fa-clock text-xl text-blue-800"></i>
                        <p class="pl-2">Last Updated: Sep 23, 2020</p>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import LeftNavigation from '@/components/LeftNavigation.vue';
// const fs = require('fs');
// const path = require('path');
// const homedir = require('os').homedir();
// const ini = require('ini');

// // firefox path location
// var paths;
// var profile_ini;
// var firefox;
// var plugin_config;
// var firefox_check;
// if (process.platform == "darwin") {
//     paths = path.join(homedir, 'Library/Application Support/')
//     profile_ini = paths + "/firefox/profiles.ini"
//     console.log("profile path: ", profile_ini)
//     plugin_config = ini.parse(fs.readFileSync(profile_ini, 'utf-8'));
//     firefox = path.join(paths, 'firefox', plugin_config.Profile0.Path);
//     firefox_check =path.join('/Applications/Firefox.app/')

// } else if (process.platform == "win32") {
//     paths = path.join(homedir, 'AppData/Roaming/Mozilla/')
//     profile_ini = paths + "/Firefox/profiles.ini"
//     plugin_config = ini.parse(fs.readFileSync(profile_ini, 'utf-8'));
//     firefox = path.join(paths, 'Firefox', plugin_config.Profile0.Path);
//     firefox_check = 'C:\\Program Files\\Mozilla Firefox\\firefox'
//     if (fs.existsSync("C:\\Program Files (x86)\\Mozilla Firefox")) {
//         firefox_check ="C:\\Program Files (x86)\\Mozilla Firefox" 
//     }
//     else {
//         firefox_check = 'C:\\Program Files\\Mozilla Firefox\\firefox'
//     } 

// } else {
//     paths = path.join(homedir, '.mozilla')
//     profile_ini = paths + "/firefox/profiles.ini"
//     plugin_config = ini.parse(fs.readFileSync(profile_ini, 'utf-8'));
//     firefox = path.join(paths, 'firefox', plugin_config.Profile0.Path);
//     if(fs.existsSync('/etc/firefox')){
//         firefox_check = path.resolve('/etc', 'firefox') 
//     } else {   
//         firefox_check = path.resolve('/usr/bin','firefox')
//     }
// }

// const { spawn } = require('child_process')

export default {
    data() {
        return {
            isFirefox: true,
            isZealbot: false
        }
    },
    components: {
        LeftNavigation
    },
    methods: {
    //     zealbots_plugin_checker: function () {
    //         if (fs.existsSync(firefox_check)) {
    //             this.isFirefox= true
    //             let rawdata = fs.readFileSync(path.join(firefox, 'extensions.json'));
    //             let extenjs = JSON.parse(rawdata);
    //             var zealbots_bool = false;
    //             extenjs.addons.forEach(function (data) {
    //                 var sara = data.sourceURI    
    //                 if (sara != null && sara.includes('zealbots') && data.active == true) { 
    //                     zealbots_bool = true
    //                 }
    //             })
    //             if (zealbots_bool) {
    //                 this.isZealbot = true
    //                 console.log('\x1b[36m%s\x1b[0m', '\n--------------------------------------\n---------Zealbots Installed-----------\n--------------------------------------\n')
    //             } else {
    //                 this.isZealbot = false
    //                 console.log('\x1b[31m%s\x1b[0m', '\n--------------------------------------\n--------Zealbots not installed--------\n--------------------------------------\n')
    //             }

    //         } else {
    //             this.isFirefox= false
    //             console.log("-------------- something went wrong--------------", "\n")
    //         }
    //     },
    //     openFirefox() {
    //         var child;
    //         if (process.platform == "darwin") {
    //              child = spawn('open', ['-a', 'firefox', 'https://addons.mozilla.org/en-US/firefox/addon/zealbots/?src=search'])

    //         } else if (process.platform == "win32") {
    //              child = spawn('C:\\Program Files\\Mozilla Firefox\\firefox',  ['https://addons.mozilla.org/en-US/firefox/addon/zealbots/?src=search'])

    //         } else {
    //              child = spawn('firefox', ['https://addons.mozilla.org/en-US/firefox/addon/zealbots/?src=search'])

    //         }
    //         child.stdout.on('data', (data) => {
    //             console.log(`stdout: ${data}`)
    //         })

    //         child.stderr.on('data', (data) => {
    //             console.log(`stdout: ${data}`)
    //         })

    //         child.on('error', (error) => {
    //             console.log(`stdout error: ${error.message}`)
    //         })

    //         child.on('exit', (code, signal) => {
    //             if (code) console.log(`Process exited with code : ${code} 🥴`)
    //             if (signal) console.log(`Process killed with signal: ${signal} 🥵`)
    //             console.log(`\n--------------------------------------\n--------Runned successfully 🥰--------\n--------------------------------------\n`)
    //         })
    //     }
    },
    created() {
        // this.zealbots_plugin_checker()
    }
}

</script>

<style scoped>

</style>
