<template>
  <Navbar />


  
    <Footer />
   

  
  
</template>
<script>

</script>
