<template>
  <div class="h-auto flex flex-col">
    <Navbar />
    <div class="w-full flex flex-col items-center mt-20">
      <p class="w-8/12 flex flex-row text-xl md:text-4xl font-bold text-blue-900">
        Automating Literature Search With AI
      </p>
      <p class="w-8/12 flex flex-row text-md md:text-2xl font-normal text-gray-500 self-center mt-5">
        More time for research!
      </p>
    </div>

      <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center items-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 p-2 md:p-8">
          <img src="../../assets/all_the_data.svg" alt="">
        </div>
        <div class="w-full lg:w-1/3 flex flex-col justify-center gap-2">
          <div class="w-full flex flex-row">
            <div class="w-1/12 lg:w-2/12 p-0 lg:p-2 flex justify-center items-center">
              <img src="https://img.icons8.com/nolan/128/services.png" alt=""/>
            </div>
            <div class="w-10/12 flex flex-col justify-center">
              <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                100+ Million Articles
              </div>
              <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                All of academia at your finger tips!
              </div>
            </div>
          </div>

          <div class="w-full flex flex-row">
            <div class="w-1/12 lg:w-2/12 p-0 lg:p-2 flex justify-center items-center">
              <img src="https://img.icons8.com/nolan/128/home.png" alt=""/>
            </div>
            <div class="w-10/12 flex flex-col justify-center">
              <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                Million+ Institutions
              </div>
              <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                The world is your lab!
              </div>
            </div>
          </div>

          <div class="w-full flex flex-row">
            <div class="w-1/12 lg:w-2/12 p-0 lg:p-2 flex justify-center items-center">
              <img src="https://img.icons8.com/nolan/128/idea.png" alt=""/>
            </div>
            <div class="w-10/12 flex flex-col justify-center">
              <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                8+ Million Authors
              </div>
              <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                Let’s do excellent research together!
              </div>
            </div>
          </div>

          <div class="w-full flex flex-row">
            <div class="w-1/12 lg:w-2/12 p-0 lg:p-2 flex justify-center items-center">
              <img src="https://img.icons8.com/nolan/128/bookmark.png" alt=""/>
            </div>
            <div class="w-10/12 flex flex-col justify-center">
              <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                25,000 Journals!
              </div>
              <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                Make your impact now!
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="w-full flex flex-col items-center mt-20">
        <p class="w-8/12 flex flex-row text-xl md:text-4xl font-bold text-blue-900">
          Built For Innovation!
        </p>
        <p class="w-8/12 flex flex-row text-md md:text-2xl font-normal text-gray-500 self-center mt-5">
          Smart tools for smarter people!
        </p>
      </div>

      <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10 items-center">
        <div class="w-full lg:w-1/3 flex flex-col justify-center gap-2">
          <div class="w-full flex flex-row">
            <div class="w-full flex flex-col justify-center">
              <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                ZEALBOTS
              </div>
              <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md text-justify">
               We introduce to you, your personal and configurable AI for automating your literature search. Keep track of your research field, 
               university, and favorite authors and get more time for innovation, as the world need it the most and more.
              </div>
              <div class="flex flex-row justify-center items-center p-4">
                <router-link to="/why_zealbots">
                  <button class="px-4 py-2 border border-blue-600 rounded-md text-blue-600 shadow hover:shadow-lg">
                      Read more
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 p-2 md:p-8">
          <img src="../../assets/Firmware.svg" alt="">
        </div>
      </div>

      <div class="w-full flex flex-col items-center mt-20">
        <p class="w-8/12 flex flex-row text-xl md:text-4xl font-bold text-blue-900">
         Blogs, Journals, Manuals and Protocols
        </p>
        <p class="w-8/12 flex flex-row text-md md:text-2xl font-normal text-gray-500 self-center mt-5">
          Read, think and share science!
        </p>
      </div>

      <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 p-2 md:p-8">
          <img src="../../assets/web_developer.svg" alt="">
        </div>
        <div class="w-full lg:w-1/3 flex flex-col justify-center gap-2">
          <div class="w-full flex flex-row">
            <div class="w-full flex flex-col justify-center">
              <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                Scholarly
              </div>
              <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md text-justify">
               Penn your thoughts and we will ensure its academic quality. Our AI takes care of everything end to end from adding citation 
               to team edits thus making your content reach the right audience.
              </div>
              <div class="flex flex-row justify-center items-center p-4">
                <router-link to="/scholarly">
                  <button class="px-4 py-2 border border-blue-600 rounded-md text-blue-600 shadow hover:shadow-lg">
                      Read more
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full flex flex-col items-center mt-20">
        <p class="w-8/12 flex flex-row text-xl md:text-4xl font-bold text-blue-900">
         A safe home for your data
        </p>
        <p class="w-8/12 flex flex-row text-md md:text-2xl font-normal text-gray-500 self-center mt-5">
         All the space you need!
        </p>
      </div>

      <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/3 flex flex-col justify-center gap-2">
          <div class="w-full flex flex-row">
            <div class="w-full flex flex-col justify-center">
              <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                ZKLOUD
              </div>
              <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md text-justify">
               Your Data Privacy-That’s all right! <br> Zero-Knowledge Cloud warmly welcomes you to the age of fearless collaboration.
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-center items-center p-4">
            <router-link to="/why_zkloud">
              <button class="px-4 py-2 border border-blue-600 rounded-md text-blue-600 shadow hover:shadow-lg">
                  Read more
              </button>
            </router-link>
          </div>
        </div>
        <div class="w-full lg:w-1/2 p-2 md:p-8">
          <img src="../../assets/upload.svg" alt="">
        </div>
      </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Navbar,
    Footer,
  }
} 
</script>

<style scoped>

</style>