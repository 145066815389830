<template>

<div class="fixed  bg-white  justify-center items-center inset-0 z-40 outline-none overflow-x-hidden overflow-y-auto">
    <!-- Title -->
    <div class="m-1  text-white   text-md bg-blue-500 rounded">
        <h1 class=" relative py-1 px-2">
        {{collectiondata.title}}
        </h1>
        
        <div @click="$emit('cancelArticle')" class="top-7 right-4 absolute my-auto mr-2 cursor-pointer ">
        <font-awesome-icon  class="text-gray-700  text-2xl" :icon="['fas', 'times-circle']" />
        </div>
    </div>

    <!-- <p>{{collectiondata}}</p> -->
    <div class="flex flex-row px-4 text-sm font-medium text-left py-2 bg-gray-100 border rounded m-2 ">
        <span class="text-white ">
            <a :href="collectiondata.pdf_url" class=" cursor-pointer rounded text-red-500" v-if="collectiondata.pdf_url" >
                <font-awesome-icon class=" text-4xl cursor-pointer " :icon="['fas', 'file-pdf']" />
            </a>
        </span>
        <div class="ml-2">
        <div>
    
        Publ ished : {{collectiondata.pub_date}}. {{collectiondata.journal}}.
        </div>
        <span class="text-teal-500">
            <a class="cursor-pointer" :href="collectiondata.html_url" v-if="collectiondata.html_url" >Goto Journal Website</a>
        </span>
        </div>
    </div>
    <div class="px-4 flex justify-between text-lg font-bold  text-justify" v-if="collectiondata.abstract">
        <h1 class="text-blue-500">
        Abstract
        </h1>
        <div v-if="collectionpage" class="flex  gap-6">
            <div  @click="isOpen = !isOpen" class="text-xs text-white border p-1 bg-green-500 rounded ">
                <div class="cursor-pointer" >

                Move to collection 
                </div>
            
            </div>
                <div v-if="isOpen" class="mt-8 absolute">
                <a class=" " title="Add To Collections">
                    <div >

                        <ul class="bg-white rounded-lg shadow-lg ">
                            <span v-for="collectionlist in CollectionListInfo.data" :key="collectionlist" >
                                    
                                <li class="py-1 px-6 text-black hover:bg-gray-100 text-xs border" @click=" movetocollection([collectiondata.id],collectionlist);" >
                                    {{collectionlist.name.split(":")[0]}}{{collectionlist.id}}
                                </li>
                            </span>
                        </ul>
                    </div>
                </a>
            </div>
            
         <div @click="$emit('deleteArticle')" class="">

            <font-awesome-icon  class=" text-2xl text-gray-700 cursor-pointer " :icon="['fas', 'trash']" />
        </div>
        </div>    
        <!--  -->
          <div v-else class="flex  gap-6">
            <div  @click="isOpen = !isOpen" class="text-xs text-white border p-1 bg-green-500 rounded ">
                <div class="cursor-pointer" >

                Add to collection 
                </div>
            
            </div>
                <div v-if="isOpen" class="mt-8 absolute">
                <a class=" " title="Add To Collections">
                    <div >

                        <ul class="bg-white rounded-lg shadow-lg ">
                            <span v-for="collectionlist in CollectionListInfo.data" :key="collectionlist" >

                                <li class="py-1 px-6 text-black hover:bg-gray-100 text-xs border" @click="addCollection(collectionlist.id,collectiondata.id)">
                                    {{collectionlist.name.split(":")[0]}}{{collectionlist.id}}
                                </li>
                            </span>
                        </ul>
                    </div>
                </a>
            </div>
            
         <div @click="$emit('deleteArticle')" class="">

            <font-awesome-icon  class=" text-2xl text-gray-700 cursor-pointer " :icon="['fas', 'trash']" />
        </div>
        </div>
        <!--  -->
        
    </div>
    
    <div class="px-4 py-1 text-xs text-justify">{{collectiondata.abstract}}</div>
    <div v-if="collectiondata.doi" class="px-4 py-2 w-10/12 text-sm font-medium text-blue-500 text-justify">DOI:{{collectiondata.doi}}</div>
    <NoteTaking v-if="collectionpage" :note_list=todoInfo :current=collectiondata.id />

<div class="px-4 w-10/12 text-md flex flex-col" v-if="collectiondata.authors.length>0">
    <h1 class=" py-1 text-lg font-bold text-blue-500 text-justify">Authors</h1>
    <div class="flex flex-wrap text-justify">
    <span v-for="author in collectiondata.authors" :key="author" class=" py-1 text-xs text-gray-700 ">{{author.name_full}},</span>
    </div>
</div>
<div class="pl-4 py-1 w-10/12 text-md flex flex-col" v-if="collectiondata.institutes.length>0">
    <h1 class=" py-2 text-lg font-bold text-blue-500 text-justify">Affliations</h1>
    <p v-for="author in collectiondata.institutes" :key="author" class="px-1 pb-1 text-xs text-gray-700 text-left">{{author.name}}</p>
    <br />
</div>
<!-- Citeman -->
<div v-if="collectionpage">
<h1 class="px-8 py-2 w-full bg-gray-200 text-gray-700 font-normal text-lg text-left">Citation download</h1>
<br />
<div class="px-8 w-10/12 flex flex-start mb-8">
    <a class="w-auto px-2 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100"> bibText </a>
    <a class="w-auto px-2 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100"> EndNote </a>
    <a class="w-auto px-2 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100"> Refman </a>
</div>
</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "axios";
import NoteTaking from "@/components/NoteTaking.vue";
import {mapActions} from "vuex";

export default {
            data () {
            return {
                isOpen : false,
                added_collection: false, 
                note_list: [],
                
                           
            }      
        },
        components :{ NoteTaking,
             
        },
    emits: ["deleteArticle","DeleteModel,PdfUrlClick"],
    props: {
        collectiondata: {
            type: Object,
            default: () => {}
        },
        collectionpage : {
            type :Boolean,
            default : () => false

        },     
    },
    methods : {
        ...mapActions(["getAllNotes"]),
    async addCollection(n, article_id) {
        
                    this.isOpen = !this.isOpen;
            try {
                var user_token = this.$store.getters.StateUserInfo.key;
                 
                let collection_details = {
                    "collection_id": n,
                    "user_id": this.$store.getters.StateUserInfo.id,
                    "publication_id": [article_id]
                }
                console.log(collection_details)

                const response = await axios.post(`${process.env.VUE_APP_ADD_COLLECTIONS_API}${user_token}`, collection_details);
                console.log(response.data)
                
            } catch (e) {
                this.errors.push(e)
            }

        },
       async movetocollection (articles,selectedcollection) {
             
            this.isOpen = !this.isOpen

            //    articles add into collection function
            var that = this
            var collection = selectedcollection.id
            console.log("collection id: ", collection, articles);
            var userInfo = this.$store.getters.StateUserInfo;

            var input_data = {
                from_collection_id: this.$route.params.id,
                to_collection_id: collection,
                publication_id: articles,
                user_id: userInfo.id
            };
            
            console.log("move articles data: ", input_data);
            
            await axios
            .post(`https://api.zealbots.com/api/v1/collection/article/move/?Key=${userInfo.key}`, input_data)
            .then(function (response) {
                console.log("log", response)
                that.notification = true
                that.messagestatus = "success"
                that.messagebody = response.data.message
                that.showSingleCollectionModal = false
                that.dropdown = false
                that.showmultipleCollectionModal = false
                that.display_muliple_option = false
                that.multipledropdown = false
                setTimeout( () => {
                    that.notification = false
                   
                }, 5000)
            })
            .catch ( error => {
                that.notification = true
                that.messagebody = error
            })
        },
        noteTakingList: async function (article) {
            // var that = this
            console.log("note :", article.id)
            this.note_list = []
            var userInfo = this.$store.getters.StateUserInfo;
            var note_input = {
                article_id: article.id,
                user_key: userInfo.key
            };
            var get_note_list = await this.getAllNotes(note_input);
            console.log("get_note_list", get_note_list)
            
            // var note_api_url = `notes/view/?article=${article.id}&Key=${userInfo.key}&format=json`
            // try {
            //     axios.get(note_api_url)
            //         .then(function (response) {
            //             if (response && response.status == 200 && !("error" in response.data)) {
            //                 console.log("log", response.data)
            //                 that.note_list = response.data.data
            //             } else {
            //                 console.log("no article matched notes", article.id)
            //                 console.log(response.data)
            //             }
            //         });
            // } catch (error) {
            //     console.log("error:", error)
            // } finally {
            //     console.log("finally todo list completed ")
            // }
        },
        },
    computed:mapGetters(["CollectionListInfo","todoInfo"])
    ,
    
}
</script>

<style scoped>

</style>
