<template>
<div class="flex flex-row h-full row w-screen bg-gray-200">
    <LeftNavigation selected='1' :bot_id='botid' />

    <main class="flex-1 bg-gray-200 overflow-y-auto transition duration-500 ease-in-out">
        <div class="flex flex-col w-full h-full bg-gray-200">
            <!-- Main Web view Dev Start -->
            <div v-if="publication_list.length" class="flex  w-full text-gray-700 dark:text-gray-500 transition duration-500 ease-in-out">
                <!-- left column container start -->
                <div class="w-full md:w-5/12 h-screen z-0 overflow-y-scroll scrollbar-thin scrollbar-track-indigo-200 scrollbar-thumb-indigo-600
                    bg-gradient-to-tr from-blue-200 via-indigo-200 to-pink-200">
                    <!-- title -->
                    <div class="flex flex-col sticky h-3/12 bg-teal-600 p-2">
                        <div class="w-full flex flex-row z-10 ">
                            <div class="w-10/12 flex justify-center items-center">
                                <h2 class="text-lg font-bold text-center text-white">{{botTitle}} ( {{currentArticleCount}} / 100 )</h2>
                            </div>
                            <div class="w-2/12 my-2 cursor-pointer">
                            <!-- w-auto px-2 z-10 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100 -->
                                <button @click="botEditClick(bucket_id_data)" class="border border-white rounded bg-teal-900 ">
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div class="w-full flex flex-row z-10 ">
                            <input @keyup="search" v-model="searchbox" class="rounded-md h-8 w-full py-3 px-6 text-gray-800 leading-tight focus:outline-none" type="text" placeholder="Search" />
                        </div>
                    </div>
                    <!-- Articles -->
                    <div class="w-full h-9/12 p-2">
                        <div v-if="display_muliple_option" class="flex justify-center">
                            <!-- check box check and then show container start -->
                            <div class="relative inline-block text-left">
                                <!-- add to collection btn -->
                                <button @click="multipledropdown = !multipledropdown" class="w-auto px-2 ring bg-white rounded-sm text-blue-600 py-1 m-3 transform hover:scale-105 motion-reduce:transform-none">
                                    Add to collection
                                </button>

                                <div @mouseleave="multipledropdown = !multipledropdown;" v-bind:class="[multipledropdown ? 'block':'hidden']" class="origin-top-right z-10 absolute right-15 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    <div class="py-1" role="none">
                                        <a v-for="collection in collection_list" :key="collection" @click="toggleMultipleAddtoCollectionModal(collection)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                            <!-- article move time show container -->
                                            <div class="font-medium flex flex-row">
                                                <span v-text="collectionNameSplit(collection.name)" class="cursor-pointer"></span>
                                                <div>
                                                </div>
                                            </div>
                                            <!-- end article move time show container -->

                                        </a>
                                    </div>
                                </div>
                            </div>
                            <button @click="showmultipleDeleteModal = !showmultipleDeleteModal" class="w-auto px-2 ring bg-white ring-blue-500 ring-offset-2 rounded-sm text-blue-600 py-1 font-bold m-3 transform hover:scale-105 motion-reduce:transform-none">
                                Articles Delete
                            </button>

                        </div>
                        <div v-if="publication_list.length" class="px-2">
                            <transition-group :css="false" @before-enter="botBeforeEnter" @enter="botEnter" @leave="botLeave">
                                <div v-for="publication in publication_list" :key="publication"  class="card_article_list w-full h-20 flex flex-row my-2 rounded-md shadow-sm bg-white border hover:shadow-lg hover:bg-gray-200">
                                    <div class="w-full flex flex-row">
                                        <div class="w-1/12 relative h-12 rounded-l-md flex flex-row justify-center items-center">
                                            <div v-if="publication.pdf_url" class="hidden md:flex w-2/6 h-12 text-red-500 rounded-l-md p-8">

                                                <span class="inline-block align-baseline ...">

                                                    <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'file-pdf']" /></span></div>

                                            <div v-else class="hidden md:flex w-2/6 h-12 text-indigo-500 rounded-l-md p-8">
                                                <span class="inline-block align-baseline ...">

                                                    <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'book']" />
                                                </span></div>
                                            <div class="w-2/6">
                                                <input v-on:click="checkbox" v-bind:data-id="publication.id" class="rounded articles-checkbox absolute left-1 z-1 h-4 w-4 mr-2 outline-none active:outline-none" type="checkbox" name id />
                                            </div>
                                        </div>
                                        <div class="w-11/12 rounded-r-md flex flex-col place-content-center" v-on:click="currentPublication = publication ; articleShow = !articleShow;">
                                            <div class="w-full text-sm font-medium text-gray-900 px-2 text-left truncate article_title">{{publication.title}}</div>
                                            <div class="w-full text-sm font-light text-pink-700 px-2 text-left truncate">{{publication.journal}}</div>
                                            <div class="w-full text-xs font-thin px-2 text-left truncate">
                                                <span v-for="author in publication.authors" :key="author">{{author.name_full}},</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </div>
                        <div v-else>
                            <div class="w-full h-24 flex flex-row mb-2 mt-2 rounded-md shadow-sm bg-white border hover:shadow-lg hover:border-purple-600 cursor-pointer">No records found</div>
                        </div>
                    </div>
                </div>
                <!-- end left colomn container -->

                <!--  start right colomn container -->
                <div class="w-0 h-0 md:w-7/12 pr-2 md:h-screen overflow-y-scroll scrollbar-thin scrollbar-track-indigo-200 scrollbar-thumb-indigo-600 bg-white rounded-sm">
                    <div class="flex flex-row items-center w-full h-2/12 px-4 py-2 bg-gradient-to-br from-teal-700 via-purple-600 to-indigo-900 z-10">
                        <div class="w-11/12 flex items-center min-h-20 h-20 px-2 text-gray-50 text-left text-lg font-normal">
                            {{currentPublication.title}}
                        </div>
                        <div class="w-1/12 pr-2 font-medium flex flex-row justify-end items-center">

                            <span @click="showSingleDeleteModal = !showSingleDeleteModal" class="w-12 h-12 bg-gray-900 bg-opacity-50 rounded-full justify-center items-center flex flex-row text-white font-bold hover:bg-gray-700 cursor-pointer">

                                <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'trash']" />

                            </span>

                        </div>
                    </div>
                    <div class="px-4 py-2 m-2 bg-gray-200 rounded text-md font-medium text-left lg:mr-4">
                        <span class="text-white items-center pr-4">
                            <a :href="currentPublication.pdf_url" class="cursor-pointer rounded text-red-500" v-if="currentPublication.pdf_url" >

                                <font-awesome-icon class=" text-2xl cursor-pointer " :icon="['fas', 'file-pdf']" />
                            </a>
                        </span>
                        Published : {{currentPublication.pub_date}}. {{currentPublication.journal}}.
                        <span class="text-white over:scale-105">
                            <a :href="currentPublication.html_url" class="cursor-pointer rounded bg-teal-500 px-4 py-1" v-if="currentPublication.html_url" @click="$browser.openBrowser(currentPublication.html_url)">Visit journal</a>
                        </span>
                    </div>
                    <div class="px-8 py-2 w-10/12 text-lg font-bold text-blue-500 text-justify" v-if="currentPublication.abstract">Abstract</div>
                    <div class="px-8 py-2 w-11/12 text-md text-justify">{{currentPublication.abstract}}</div>
                    <div class="px-8 py-2 w-10/12 text-md font-medium text-blue-500 text-justify" v-if="currentPublication.doi">DOI:{{currentPublication.doi}}</div>

                    <div class="px-8 py-2 w-10/12 text-md font-bold text-blue-500 flex flex-start">

                        <div class="relative inline-block text-left">
                            <!-- add to collection btn -->
                            <button @click="dropdown = !dropdown;" class="w-auto px-2 z-10 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100" id="options-menu" aria-expanded="true" aria-haspopup="true">
                                Add to collection
                            </button>
                            <div v-bind:class="[dropdown ? 'block':'hidden']" class="origin-top-right absolute right-15 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <div class="py-1" role="none">
                                    <!-- collection name display dropdown-->
                                    <a v-for="collection in collection_list" :key="collection" @click="selectedcollection = collection; showSingleCollectionModal = !showSingleCollectionModal;" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                        <div class=" font-medium flex flex-row">
                                            <span v-text="collectionNameSplit(collection.name)" class="cursor-pointer"></span>
                                        </div>
                                    </a>
                                    <!-- end collection name display dropdown -->

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="px-8 py-2 w-10/12 text-md flex flex-col" v-if="currentPublication.authors.length>0">
                        <h1 class="px-2 py-2 text-lg font-bold text-blue-500 text-justify">Authors</h1>
                        <span v-for="author in currentPublication.authors" :key="author" class="px-2 py-1 text-sm text-gray-700 text-left">{{author.name_full}}</span>
                    </div>
                    <div class="px-8 py-2 w-10/12 text-md flex flex-col" v-if="currentPublication.institutes.length>0">
                        <h1 class="px-2 py-2 text-lg font-bold text-blue-500 text-justify">Affliations</h1>
                        <p v-for="author in currentPublication.institutes" :key="author" class="px-2 py-1 text-sm text-gray-700 text-left">{{author.name}}</p>
                        <br />
                    </div>

                </div>
                <!--  end right colomn container -->

            </div>
            <!-- full column closed -->

            <!-- single articles add to collection modal -->
            <ConfirmPopup headImage="https://img.icons8.com/fluency/96/000000/add-to-collection.png" yesBtnText="Yes, add this" cancelBtnText="Not for now" v-if="showSingleCollectionModal" headTitle="Add to Collection" bodyContent="Are you sure you want to add these Publication into collection?" :highlightContent="currentPublication.title" @cancelClick="showSingleCollectionModal = !showSingleCollectionModal" @confirmClick="addtocollection([currentPublication.id])" />

            <!-- start multiple collection modal -->
            <ConfirmPopup headImage="https://img.icons8.com/fluency/96/000000/add-to-collection.png" yesBtnText="Yes, add these" cancelBtnText="Not for now" v-if="showmultipleCollectionModal" headTitle="Add to Collection" bodyContent="Are you sure you want to add these Publication into collection?" @cancelClick="showmultipleCollectionModal = !showmultipleCollectionModal" @confirmClick="MultipleArticlesAddtoCollection()" />

            <!-- end multiple collection modal -->

            <!-- start multiple delete modal -->
            <ConfirmPopup headImage="https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/64/000000/external-delete-multimedia-kiranshastry-lineal-color-kiranshastry.png" yesBtnText="Yes, delete" cancelBtnText="No, keep these" v-if="showmultipleDeleteModal" headTitle="Delete multiple Article" bodyContent="Are you sure you want to delete these Publication?" @cancelClick="showmultipleDeleteModal = !showmultipleDeleteModal" @confirmClick="multipleAticleDelete()" />

            <!-- articles delete modal  -->
            <ConfirmPopup class="z-50" 
                v-if="showSingleDeleteModal" 
                headImage="https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/64/000000/external-delete-multimedia-kiranshastry-lineal-color-kiranshastry.png" 
                yesBtnText="Yes, delete" 
                cancelBtnText="No, keep this" 
                headTitle="Delete Article" 
                bodyContent="Are you sure you want to delete this Publication?" 
                :highlightContent="currentPublication.title" 
                @cancelClick="showSingleDeleteModal = !showSingleDeleteModal" 
                @confirmClick="articleShow=!articleShow;articleDelete([currentPublication.id])"
                @PdfUrlClick="pdfViewer(currentPublication.pdf_url)" 
            />
            <!-- end articles delete modal -->

            <!-- start articles load time loader  -->

            <div v-if="loading && !publication_list.length" class="flex h-screen">
                <!-- <h2 class="text-center">{{botTitle}} ( {{currentArticleCount}} / {{totalCount}} )</h2> -->
                <div class="m-auto">
                    <p class="w-full text-center p-5">
                        <img class="h-20 mx-auto" :src="$static.path(require('@/assets/loading-animate.gif'))" alt="loading...">
                        Loading Articles
                    </p>
                </div>
            </div>

            <!-- end articles load time loader  -->

            <!-- start articles no record found  -->
            <div v-if="!loading && !publication_list.length" class="flex h-screen">
                <div class="m-auto">
                    <img class="w-56 h-56" :src="$static.path(require('@/assets/loading.svg'))" />
                    <div class="text-center text-xl font-medium">
                        No records to show
                    </div>
                </div>
            </div>
            <div v-else-if="bucket_result == '500 Error'" class="flex h-screen">
                500 Error
            </div>

            <!-- end articles no record found  -->
            <div v-else-if="notification" class="relative w-full -mt-5">
                <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                    <p class="text-white font-bold">{{messagebody}}</p>
                    <font-awesome-icon class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
                </div>
            </div>
        </div>
        <!-- row closed -->
    </main>
</div>
<PopupArticle  @deleteArticle="showSingleDeleteModal = !showSingleDeleteModal" class="flex-none md:hidden" 
v-if="articleShow" :collectiondata='currentPublication' 
@cancelArticle="articleShow = !articleShow" />
</template>

<script>
import LeftNavigation from "@/components/LeftNavigation.vue";
import PopupArticle from '@/components/PopupArticle.vue'
import {
    mapActions
} from "vuex";
import $ from "jquery";
import axios from "axios";
import searchSortAnimate from '@/mixins/searchSortAnimate'
// const { BrowserWindow } = require("@electron/remote");

import ConfirmPopup from '@/components/ConfirmPopup.vue'

export default {
    name: "Bot",
    components: {
        LeftNavigation,
        ConfirmPopup,
        PopupArticle
    },
    inject: ["Gstore"],
    props: ["botId", "extraValue"],
    mixins: [searchSortAnimate],
    data() {
        return {
            articleShow: false,
            publication_list: [],
            currentPublication: [],
            botTitle: "",
            loading: false,
            totalCount: 0,
            currentArticleCount: 0,
            searchbox: "",
            collection_list: [],
            dropdown: false,
            multipledropdown: false,
            showmultipleCollectionModal: false,
            showSingleDeleteModal: false,
            showSingleCollectionModal: false,
            display_muliple_option: false,
            notification: false,
            messagestatus: "",
            messagebody: "",
            error: false,
            selectedcollection: "",
            showmultipleDeleteModal: false,
            botid: "",
            bucket_result: null,
            bucket_id_data: null,
        };
    },
    methods: {
        ...mapActions(["GetSingleBucket"]),

        botListFunction: async function (bucket_id) {
            this.loading = true;
            var userInfo = this.$store.getters.StateUserInfo;
            this.bucket_id_data =  bucket_id;
            var bucket_data = {
                bucket_id: bucket_id,
                user: userInfo.id,
                key: userInfo.key
            };
            this.bucket_result = await this.GetSingleBucket(bucket_data);
            if (this.bucket_result.data) {
                bucket_data = this.bucket_result.data.data[0];
                this.botTitle = bucket_data.bot.bot_name;
                let bot_articles_list = bucket_data.recommendation;
                this.botid = bucket_data.bot.id;
                this.totalCount = bot_articles_list.length;
                bot_articles_list.forEach(el => {
                    this.currentArticleCount++;
                    this.publication_list = []
                    this.currentPublication = []
                    var article = el;
                    axios
                        .get(`/publication/?id=${article}&fields=id,title,pub_type,doi,pub_date,journal,impact_factor,authors,institutes,abstract,html_url,pdf_url&Key=${this.$store.getters.StateUserInfo.key}&format=json`)
                        .then(response => {
                            if (response && response.data) {
                                this.publication_list = [...this.publication_list, response.data.data[0]]
                                this.loading = false
                                this.currentPublication = this.publication_list[0]
                            } else {
                                console.log("Some error")
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                })
            } else {
                this.publication_list = [];
                this.currentPublication = [];
                this.currentArticleCount = 0
            }
        },
        getbotartices: async function () {
            await this.GetBotList();
            this.publication_list = this.$store.getters.BotListInfo;
        },
        toggleMultipleAddtoCollectionModal: function (collection) {
            // console.log("clicked toggleMultipleAddtoCollectionModal", collection)
            this.selectedcollection = collection
            this.showmultipleCollectionModal = !this.showmultipleCollectionModal;
            // console.log("after clicked collection", this.showmultipleCollectionModal)
        },
        closeMultipleAddtoCollectionModal: function () {
            this.multipledropdown = false
            this.showmultipleCollectionModal = false;
        },

        // pdfViewer: function (data) {
        //     // console.log("pdf preview url: ", data)
        //     let win = new BrowserWindow({ width: 800, height: 600 })
        //     win.loadURL(data)
        // },
        botEditClick: function (data) {
            // console.log("Do you want Delete bot?", this.botid)
            console.log("/bot_edit/" + data)
            this.$router.push("/bot_edit/" + this.botid);            
            // this.$browser.openBrowser(`https://www.zealbots.com/zbot/zbot_edit/${this.botid}`)
        },

        collectionNameSplit(data) {
            // collection and id details split and return collction name only 
            return data.split(":")[0];
        },
        checkbox: function () {
            // checkbox select and unselect item. if any checkbox check that time addto collection and articles delete button show, otherwise button hide function
            var checkboxes2 = document.querySelectorAll('.articles-checkbox:checked');
            if (checkboxes2 && checkboxes2.length == 0) {

                this.display_muliple_option = false
            } else {
                this.display_muliple_option = true
            }
        },
        search: function () {
            var input_value = this.searchbox;
            // console.log("new function:", input_value);
            $(".article_title").each(function () {
                var str = $(this).text();
                if (input_value) {
                    if (str.toLowerCase().indexOf(input_value.toLowerCase()) >= 0) {
                        $(this)
                            .closest(".card_article_list")
                            .show();
                    } else {
                        $(this)
                            .closest(".card_article_list")
                            .hide();
                    }
                } else {
                    $(this)
                        .closest(".card_article_list")
                        .show();
                }
            });
        },

        addtocollection: function (articles) {

            // Articles add into collection function
            let that = this
            let collection = this.selectedcollection.id
            // console.log("collection id: ", collection, articles);
            var userInfo = this.$store.getters.StateUserInfo;
            var input_data = {
                publication_id: articles,
                collection_id: collection,
                user_id: userInfo.id
            };
            axios
                .post(`https://api.zealbots.com/api/v1/collection/AddToCollectionView/?Key=${userInfo.key}`, input_data)
                .then(response => {
                    console.log(response)
                    that.notification = true
                    that.messagestatus = "success"
                    that.messagebody = `Articles added into collection successfully`
                    that.showSingleCollectionModal = false
                    that.dropdown = false
                    that.showmultipleCollectionModal = false
                    that.display_muliple_option = false

                    that.multipledropdown = false
                    setTimeout(function () {
                        that.notification = false
                    }, 5000)
                })
                .catch(error => {
                    console.log(error)
                    that.notification = true
                    that.messagebody = error
                })
        },
        articleDelete: async function (data) {
            // single and multiple articles delete function 
            var userInfo = this.$store.getters.StateUserInfo;
            var article_data = {
                bucket_id: this.$route.params.id,
                article_id: data,
                user_id: userInfo.id
            };
            let response = await axios.post(`https://api.zealbots.com/api/v1/bot_article/deleted/?Key=${userInfo.key}`, article_data)
            if (response && response.data) {
                this.notification = true
                this.messagestatus = "success"
                this.messagebody = `Article deleted successfully`
                this.showSingleDeleteModal = false
                this.showmultipleDeleteModal = false
                this.display_muliple_option = false
                if (data.length > 1) {
                    data.forEach((data) => {
                        this.publication_list = this.publication_list.filter(el => el.id != data)
                    })
                } else {
                    this.publication_list = this.publication_list.filter(el => el.id != data[0])
                }
                this.currentArticleCount = this.currentArticleCount - data.length
                this.currentPublication = this.publication_list[0]
            } else if (response && response.data.error) {
                this.notification = true
                this.messagebody = response.data.error
            }
            setTimeout(function () {
                this.notification = false;
                // this.botListFunction(this.$route.params.id);
            }, 5000)
        },

        MultipleArticlesAddtoCollection: function () {
            // checkbox selected item find and multiple articles add into collection function
            // console.log("MultipleArticlesAddtoCollection: ", this.selectedcollection)
            var arraypublication = []
            var checkboxes = document.getElementsByClassName("articles-checkbox")
            for (var checkbox of checkboxes) {
                if (checkbox.checked) {
                    arraypublication.push(checkbox.getAttribute('data-id'))
                }
            }
            // console.log("final collection: ", this.selectedcollection, arraypublication)
            this.addtocollection(arraypublication)
        },
        multipleAticleDelete: function () {
            // checkbox selected item find and multiple articles delete function
            var arraypublication = []
            var checkboxes = document.getElementsByClassName("articles-checkbox")
            for (var checkbox of checkboxes) {
                if (checkbox.checked) {
                    arraypublication.push(checkbox.getAttribute('data-id'))
                }
            }
            // console.log("articles deleted", arraypublication)
            this.articleDelete(arraypublication)
        },
    },
    created: async function () {
        this.Gstore.preBotId = this.botId
        // console.log('route', this.$route.params.id);
        this.botListFunction(this.Gstore.preBotId);

    },
    mounted: function () {
        this.collection_list = this.$store.getters.CollectionListInfo.data;
        // console.log(this.collection_list)
    },
    beforeRouteUpdate(to, from, next) {
        this.Gstore.preBotId = to.params.id
        // console.log("before", to, from, next, this.$route.path);
        this.botListFunction(to.params.id);
        next();
    },
    // watch: {
    //     searchbox(newKeyword, OldKeyword) {
    //         console.log(`New Keyword is ${newKeyword}`);
    //         console.log(`Old Keyword is ${OldKeyword}`);
    //     },

    //     $route(to, from) {
    //         console.log("after", to, from, this.$route.path);
    //         // this.botListFunction();
    //     }
    // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

button {
  z-index: 1;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  color: white;
  padding: 0.5em 1em;
  outline: none;
  background-color: #0D9388;
  overflow: hidden;
  transition: color 0.4s ease-in-out;
}

button::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #14B8A6;
  transform-origin: center;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  transition: transform 0.70s ease-in-out;
}

button:hover {
  cursor: pointer;
  color: #ffffff;
}

button:hover::before {
  transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
}

</style>
