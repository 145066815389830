<template>
<Navbar />
<div class="h-auto min-h-screen  mt-20 mb-20">

 <div class="bg-white mx-auto p-4 h-5/6 w-2/4 ">
        <h1 class="pb-4 text-indigo-600 text-center font-semibold text-3xl">
            Contact us
        </h1>

        <div class="flex flex-col items-center gap-4">

            <div class="w-full uppercase border">
                <h1 class="p-4 font-semibold text-gray-500">Tell us your name *</h1>
                <div class="border border-input">
                    <input v-model="Name" class="w-full p-4" type="text" placeholder="Enter your full name here" />
                </div>
            </div>
            <div class=" w-full uppercase border">
                <h1 class="p-4 font-semibold text-gray-500">Enter your email *</h1>
                <div class="border border-input">
                    <input v-model="email" class="w-full p-4" type="text" placeholder="Eg. eample@email.com" />
                </div>
            </div>
            <div class="w-full uppercase border">
                <h1 class="p-4 font-semibold text-gray-500">What is the message about ?</h1>
                <div class="border border-input">
                    <input  v-model="message" class="w-full p-4" type="text" placeholder="Enter your subbject here" />
                </div>
            </div>

            <div class="w-full uppercase border">
                <h1 class="p-4 font-semibold text-gray-500">Content</h1>
                <textarea v-model="Content" rows="6" class=" p-2 w-full border border-input" placeholder="Write us a message">
      </textarea>
            </div>
            <button @click="AddModal()" class="p-2 text-center font-semibold bg-green-500 text-white w-1/4 hover:bg-green-400 rounded-lg">Send Message
            </button>

        </div>
     
    </div>

    <Success v-if="notification==true" />

</div>
<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Success from '@/components/success.vue';
import axios from "axios";

export default {
    name: 'About',
    components: {
        Navbar,
        Footer,
        Success,
    },
    data: () => ({
        Name: "",
        email: "",
        message: "",
        Content: "",
        notification: false,

    }),

    methods: {

        AddModal: async function () {
            var user_key = this.$store.getters.StateUserInfo.key;

            let whole = new Date()
            let y = whole.getFullYear()
            let m = whole.getMonth() < 10 ? `0${whole.getMonth()}` : whole.getMonth()
            let d = whole.getDate() < 10 ? `0${whole.getDate()}` : whole.getDate()
            let formatData = `${y}-${m}-${d}`
            console.log(formatData)

            var data = {}
            data['contact_name'] = this.Name
            data['contact_email'] = this.email
            data['contact_subject'] = this.message
            data['content'] = this.Content
            data['date'] = formatData

            let response = await axios.post(`https://api.zealbots.com/api/v1/contact/?Key=${user_key}&format=json`, data)
            if (response) {
                console.log("response.data", response.data)
                        this.Name=""
                        this.email=""
                        this.message=""
                        this.Content=""
                        this.notification=true
                        setTimeout(() => {
                            this.notification=false

                        }, 5000)

            } else {
                console.log("value Err")
            }

        },
    }
}
</script>

<style scoped>
.border-input {
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.required:after {
    content: " *";
    color: red;
}
</style>
