<template>
  <Navbar />
  <div class=" h-screen">
    
      <div class="h-2/4 md:h-3/6 flex flex-col bg-blue-50 justify-center items-center">
        <div class="w-full h-full flex flex-col items-center justify-center">
          <div class="flex flex-col justify-center items-center">
          <h1 class="text-xl md:text-4xl  mb-4 mt-20 md:mt-0 font-medium text-gray-900">
              Reinforced Research Flow with AI
          </h1>
          <p class=" leading-relaxed text-xs text-center md:text-2xl lg:text-2xl text-gray-600 p-2 md:p-0">
              Let our bots look out for new articles, while you focus on innovation!<br>
          </p>
          </div>
        
          <div class="flex flex-row md:w-2/4">
            <div class="w-10/12 md:w-11/12">
                <input ref="search_input" v-model="keyword" v-on:keyup="search_trigger" class="w-full p-2 border rounded-l-lg shadow-lg mt-10 md:mt-20 pt-3 px-6 focus:outline-none focus:border focus:border-blue-400" placeholder="Brain, AI, nanotechnology">
            </div>
            <div @click.prevent="checkName" class="w-2/12 lg:w-1/12 flex flex-row items-center justify-center bg-blue-500 rounded-r-lg mt-10 md:mt-20 shadow-lg">
                <font-awesome-icon  class="text-white text-2xl lg:text-2xl cursor-pointer" :icon="['fas', 'search']"/>
            </div>
          </div>
          <div v-if="isLoggedIn">
          <button class="mt-10 bg-pink-700 rounded-3xl text-sm text-white font-bold px-4 py-1 pt-2 shadow-xl hover:bg-green-500">
              <a href="">Customize Your Bot</a>
          </button>
          </div>
          <div v-else>
            <button class="mt-10 bg-pink-700 rounded-3xl text-sm text-white font-bold px-4 py-1 pt-2 shadow-xl hover:bg-green-500">
              <a href="/why_zealbots">CREATE A NEW BOT</a>
          </button>
          </div>
        </div>
      </div>
     
  <div class="text-gray-600 body-font mt-10">
  <div class="container px-5  mx-auto ">
    <div class="flex flex-wrap -m-4">
      <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
        <div class="h-full text-center flex flex-col justify-center items-center">
          <img alt="testimonial" class="xl:w-2/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" src="../assets/web_developer.svg">
          <h2 class="text-blue-500 title-font tracking-wider text-lg md:text-3xl font-bold">Configure The Bots</h2>
          <p class="leading-relaxed">Keep up-to-date with the latest papers, journals, methodologies and geolocation trends. Stay connected with researchers and institutions worldwide. </p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          <a class="border m-2 p-1 w-2/6" href="/why_zealbots"> Read More</a>
        </div>
      </div>
      <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
        <div class="h-full text-center flex flex-col justify-center items-center">
          <img alt="testimonial" class="xl:w-2/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" src="../assets/Artificial_intelligence_illustrator.svg">
          <h2 class="text-blue-500 title-font tracking-wider text-lg md:text-3xl font-bold">Assign Bots To Your Projects</h2>
          <p class="leading-relaxed">Make your research relevant with the help of our semantic AI. Give the search engine a hint, and it will learn your interests in no time.</p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          <a class="border m-2 p-1 w-2/6" href="/why_zealbots"> Read More</a>
        </div>
      </div>
      <div class="lg:w-1/3 lg:mb-0 p-4">
        <div class="h-full text-center flex flex-col justify-center items-center">
          <img alt="testimonial" class="xl:w-2/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" src="../assets/process.svg">
          <h2 class="text-blue-500 title-font tracking-wider text-lg md:text-3xl font-bold">Receive Updates Over Mail</h2>
          <p class="leading-relaxed">Only read what’s worth your time. ZealBots will find the best publications for you.</p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          <a class="border m-2 p-1 w-2/6" href="/about_us"> Read More</a>
        </div>
      </div>
    </div>
  </div>
  <div class=" flex flex-col md:flex-row  ">
  <div class="lg:w-2/3 lg:mb-0 p-4">
        <div class="h-full text-center flex flex-col justify-center items-center">
          <img alt="testimonial" class="xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" src="../assets/all_the_data.svg">
          <h2 class="text-blue-500 title-font tracking-wider text-lg md:text-3xl font-bold">A Scholar</h2>
          <p class="leading-relaxed w-1/2">We automate your literature review and enable you with more time and energy for innovation, if you are student or researcher.</p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          
          <a class="border m-2 p-1 w-3/6 lg:w-1/6" href="/scholarly"> Read More</a>
        </div>
      </div>
      <div class="lg:w-2/3 lg:mb-0 p-4">
        <div class="h-full text-center flex flex-col justify-center items-center">
          <img alt="testimonial" class="xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" :src="firstImage">
          <h2 class="text-blue-500 title-font tracking-wider text-lg md:text-3xl font-bold">An Institution</h2>
          <p class="leading-relaxed w-1/2">Our state of art platform for academic institutions and cost effective data strategy helps you gain more visibility to get among top universities.</p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          
          <a class="border m-2 p-1 w-3/6 lg:w-1/6 " href=""> Read More</a>
        </div>
      </div>
  </div>
  </div>
<Footer />
</div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
// const axios = require('axios');
export default {
  name: 'Home',
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      firstImage: 'https://www.zealbots.com/static/site/img/science_fqhl.svg',
      keyword: "",
      publications: [],
    }
  },

methods: {
    checkName() {
      if (this.$refs.search_input.value) {
        this.$router.push('/search/'+this.keyword);
      }
      else {
        this.$refs.search_input.style.border = "1px solid red"

      }
    },
    search_trigger: function (e) {
            if (e.keyCode === 13) {
                this.checkName()
            }
        },
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("Home");
    }
  },

computed: {
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },
    User: function() {
      return this.$store.getters.StateUser;
    },
    UserInfo: function() {
      return this.$store.getters.StateUserInfo;
    }
  },
}   
var myRadios = document.getElementsByName('tabs2');
var setCheck;
var x = 0;
for(x = 0; x < myRadios.length; x++){
  myRadios[x].onclick = function(){
  if(setCheck != this){
    setCheck = this;
  }else{
    this.checked = false;
    setCheck = null;
    }
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.active{
    text-decoration: underline;
}
.tab-content {
  max-height: 0;
  -webkit-transition: max-height .35s;
  -o-transition: max-height .35s;
  transition: max-height .35s;
  }
  /* :checked - resize to full height */
  .tab input:checked ~ .tab-content {
  max-height: 100vh;
  }
  /* Label formatting when open */
  .tab input:checked + label{
  /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
  font-size: 1.25rem; /*.text-xl*/
  padding: 1.25rem; /*.p-5*/
  border-left-width: 2px; /*.border-l-2*/
  border-color: #6574cd; /*.border-indigo*/
  background-color: #f8fafc; /*.bg-gray-100 */
  color: #6574cd; /*.text-indigo*/
  }
  /* Icon */
  .tab label::after {
  float:right;
  right: 0;
  top: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  font-size: 1.25rem;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
  }
  /* Icon formatting - closed */
  .tab input[type=checkbox] + label::after {
  content: "+";
  font-weight:bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/
  }
  .tab input[type=radio] + label::after {
  content: "\25BE";
  font-weight:bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/
  }
  /* Icon formatting - open */
  .tab input[type=checkbox]:checked + label::after {
  transform: rotate(315deg);
  background-color: #6574cd; /*.bg-indigo*/
  color: #f8fafc; /*.text-grey-lightest*/
  }
  .tab input[type=radio]:checked + label::after {
  transform: rotateX(180deg);
  background-color: #6574cd; /*.bg-indigo*/
  color: #f8fafc; /*.text-grey-lightest*/
  }
</style>
