<template>
  <footer class="w-full text-gray-600 body-font bg-gray-50 flex flex-col">
    <div class="w-10/12 mx-auto flex flex-col md:flex-row justify-center items-center py-4">
      <div class="w-full md:w-1/2 flex flex-col justify-center items-center">
        <router-link to="/">
          <a class="flex title-font font-medium items-center mb-0 md:mb-4">
            <img class="w-12 h-12 p-2" src="../assets/img/logo.png" alt="">
            <span class="ml-1 md:ml-3 text-xl font-bold text-gray-600">ZEALBOTS</span>
          </a>
        </router-link>
        <p class="text-sm text-gray-500">Reinforced Research Flow with AI</p>
      </div>
      <div class="w-full md:w-1/2 flex flex-row justify-between items-center md:mt-10 gap-4">
        <div class="w-1/2 p-4 ml-12">
          <nav class="list-none">
            <router-link to="/scholarly">
            <li>
              <a class="text-gray-600 hover:text-gray-800">Scholarly</a>
            </li>
            </router-link>
            <router-link to="/why_zkloud">
            <li>  
              <a class="text-gray-600 hover:text-gray-800">Zkloud</a>
            </li>
            </router-link>
            <router-link to="/why_zealbots">
            <li>
              <a class="text-gray-600 hover:text-gray-800">Zealbots</a>
            </li>
            </router-link>
            <router-link to="/help">
            <li>
              <a class="text-gray-600 hover:text-gray-800">Help</a>
            </li>
            </router-link>
          </nav>
        </div>
    
        <div class="w-1/2 px-4 p-4 ml-12">
          <nav class="list-none">
            <router-link to="/about_us">
            <li>
              <a class="text-gray-600 hover:text-gray-800">About</a>
            </li>
            </router-link>
            <router-link to="/terms_and_conditions">
            <li>
              <a class="text-gray-600 hover:text-gray-800">Terms</a>
            </li>
            </router-link>
            <router-link to="/privacy_policy">
            <li>
              <a class="text-gray-600 hover:text-gray-800">Privacy</a>
            </li>
            </router-link>
            <router-link to="/contact_us">
            <li>
              <a class="text-gray-600 hover:text-gray-800">Contact</a>
            </li>
            </router-link>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
      
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
    
        </div>
      </div>
    </div>
    <div class="bg-gray-200">
      <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p class="text-gray-500 text-sm text-center sm:text-left">© 2021 Zealbots —
          <a href="https://www.zealbots.com/" rel="noopener noreferrer" class="text-gray-600 ml-1" target="_blank">@Zealbots</a>
        </p>
        <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          <a class="text-gray-500">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          <a class="ml-3 text-gray-500">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
            </svg>
          </a>
          <a class="ml-3 text-gray-500">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          <a class="ml-3 text-gray-500">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
              <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>