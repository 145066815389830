<template>
<!-- component -->
<div class="flex flex-row h-screen w-screen bg-gray-200">
    <LeftNavigation />
    <main class="flex-1 bg-gray-200 dark:bg-gray-900 overflow-y-auto transition duration-500 ease-in-out">
        <div class="text-gray-700 p-2 py-2 md:p-4 transition duration-500 ease-in-out">

            <!-- Vertical Tabs -->
            <div class="flex flex-row h-full justify-center">
                <a class="text-xs md:text-sm rounded-l-md font-medium px-2 md:px-5 py-2 block leading-normal cursor-pointer hover:text-white hover:bg-green-400 shadow-lg" :class="{
              'text-teal-600 bg-white': openTab !== 1,
              'text-white bg-green-500': openTab === 1,
            }" @click="toggleTab(1)">
                    My Profile
                </a>
                <a class="text-sm  font-medium px-2 md:px-5 py-2 block leading-normal cursor-pointer hover:text-white hover:bg-green-400 shadow-lg" :class="{
              'text-teal-600 bg-white': openTab !== 2,
              'text-white bg-green-500': openTab === 2,
            }" @click="toggleTab(2)">
                    Recent Logins
                </a>
                <a class="text-sm rounded-r-md font-medium px-2 md:px-5 py-2 block leading-normal cursor-pointer hover:text-white hover:bg-green-400 shadow-lg" :class="{
              'text-teal-600 bg-white': openTab !== 3,
              'text-white bg-green-500': openTab === 3,
            }" @click="toggleTab(3)">
                    Payment details
                </a>
            </div>

            <!-- This is for profile -->
            <div :class="{ hidden: openTab !== 1, block: openTab === 1 }" class="flex py-3 justify-center ">

                <div v-if="profileDetails" class="p-0 md:p-4 bg-white  rounded-md shadow-xl ">
                    <div class="ml-4 flex flex-wrap bg-gray-300 justify-center content-center rounded-full p-15 w-16 h-16 md:w-24 md:h-24 border-2 border-gray-500">
                        <p class="text-2xl md:text-4xl font-bold text-gray-700">{{ profileDetails.first_name[0] }}</p>
                    </div>

                    <div class=" m-0 md:m-4 border-t">
                        <table class="border-dotted min-w-full table-fixed mt-4 text-md">
                            <tr>
                                <td class="p-2">
                                    <h2 style="" class="pr-16 font-normal md:font-semibold leading-0 md:leading-6">
                                        First Name
                                    </h2>
                                </td>
                                <td class="p-2">
                                    <p class="leading-0 md:leading-6">
                                        {{": " + profileDetails.first_name}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-2">
                                    <h2 class="font-normal md:font-semibold leading-0 md:leading-6">
                                        Last Name
                                    </h2>
                                </td>
                                <td class="p-2">
                                    <p class="leading-0 md:leading-6">
                                        {{": " +profileDetails.last_name}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-2">
                                    <h2 class="font-normal md:font-semibold">
                                        Display name
                                    </h2>
                                </td>
                                <td class="p-2">
                                    <p class="leading-0 md:leading-6">
                                        {{": " +profileDetails.display_name}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-2">
                                    <h2 class="font-normal md:font-semibold">
                                        Date Joined
                                    </h2>
                                </td>
                                <td class="p-2">
                                    <p class="leading-0 md:leading-6">
                                        {{": " +profileDetails.date_joined}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-2">
                                    <h2 class="font-normal md:font-semibold leading-0 md:leading-6">
                                        Email
                                    </h2>
                                </td>
                                <td class="p-2">
                                    <p class="leading-0 md:leading-6">
                                        {{": " +profileDetails.email}}
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="m-2">
                        <img class="lg:w-3/6 p-8" src="../assets/process.svg" alt="">
                    </div>
                </div>
            </div>

            <!-- This is for history  -->
            <div :class="{ hidden: openTab !== 2, block: openTab === 2 }" class="p-0 md:p-4 flex justify-center ">
                <div class="mt-4 inline-block shadow-xl rounded-md overflow-x-auto md:overflow-hidden">
                    <table class="leading-normal">
                        <thead>
                            <tr>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 items-center border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Sl.no
                                </th>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    State/ Country
                                </th>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Date & Time
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Browser
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    IP Address
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Location
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(details, index) in loginHistory" :index="index" :key="details.id">
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border-b-2 border-gray-200 bg-white text-xs lg:text-sm">
                                    <div class="flex items-center">
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {{ index + 1 }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p v-text="stateCountry(details)" class="text-left text-gray-900 whitespace-no-wrap"></p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-left text-gray-900 whitespace-no-wrap">
                                        {{ details.login_time }}
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        {{ details.browser }}
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        {{ details.system_ip }}
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        {{ details.zone }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- <div class="">
            <img class="" src="../assets/lighthouse.svg" alt="">
          </div>       -->
            </div>

            <!-- This is for Payment  -->
            <div :class="{ hidden: openTab !== 3, block: openTab === 3 }" class=" p-0 md:p-4 flex justify-center">
                <div class="mt-4 inline-block shadow-xl rounded-md overflow-x-auto md:overflow-hidden">
                    <table class="leading-normal">
                        <thead>
                            <tr>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 items-center border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Sl.no
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Plan Group
                                </th>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Payment Method
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Payment Status
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Plan Start Date
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-300 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Plan End Date
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="PaymentHistory">
                            <tr v-for="(details, index) in PaymentHistory" :index="index" :key="details.id">
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border-b border-gray-200 bg-white text-xs lg:text-sm">
                                    <div class="flex items-center">
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {{ index + 1 }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p v-if="details.product_detail.group_type" class="text-center text-gray-900 whitespace-no-wrap">
                                        {{details.product_detail.group_type}}
                                    </p>
                                    <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    {{details.payment_method}}
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        {{ details.payment_status }}
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        {{ details.start_from }}
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        {{ details.expiriy }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="">
            <img class="" src="../assets/lighthouse.svg" alt="">
          </div>       -->
            </div>
        </div>
    </main>
</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LeftNavigation from '@/components/LeftNavigation.vue'
import {
    ref,
    onBeforeMount,
    onMounted,
    onBeforeUpdate,
} from "vue";

export default {
    name: "Dashbaord",
    components: {
        LeftNavigation
    },

    props: {
        msg: String,
    },
    data() {
        return {
            openTab: 1,
            profileDetails: null,
            loginHistory: null,
            firstLetter: null,
            PaymentHistory: null,
        };
    },
    methods: {
        toggleTab(tabNumber) {
            this.openTab = tabNumber;
        },
        frontEndDateFormat: function (date) {
            return moment(date).format("MMM D, yyyy h:mm a");
        },
        stateCountry(data) {
            return data.state + ", " + data.country;
        },
    },

    setup() {
        const profileDetailss = ref(null);
        const loginHistorys = ref(null);
        onBeforeMount(() => {
            console.log("Before Mount!");
        });
        onMounted(() => {
            console.log("Mounted!");
        });
        onBeforeUpdate(() => {
            console.log("Before Update!");
        });
        return {
            profileDetailss,
            loginHistorys
        };
    },
    async created() {
        var that = this;
        var user_id = this.$store.getters.StateUserInfo.id;
        var user_key = this.$store.getters.StateUserInfo.key;
        var profileDetailsUrl = `https://api.zealbots.com/api/v1/login_details/${user_id}/?Key=${user_key}&format=json`;
        var loginHistoryUrl = `https://api.zealbots.com/api/v1/login_history/?Key=${user_key}&format=json`;
        var PaymentUrl = `http://127.0.0.1:8000/api/v1/Payment/?Key=c524596dfe07e5a0cd1b1bdc3e7dd93754a3489a&format=json`;
        // api/v1/Payment/
        await axios
            .get(profileDetailsUrl)
            .then((res) => (that.profileDetails = res.data));
        this.profileDetails.date_joined = this.frontEndDateFormat(this.profileDetails.date_joined);
        await axios
            .get(loginHistoryUrl)
            .then((resp) => (that.loginHistory = resp.data.data));
        //Updating each elements time format in loginHistory object
        this.loginHistory.forEach((element) => {
            element.login_time = this.frontEndDateFormat(element.login_time);
        });
        await axios
            .get(PaymentUrl)
            .then((resp) => (that.PaymentHistory = resp.data.data));
        //Updating each elements time format in loginHistory object
        this.PaymentHistory.forEach((element) => {
            element.login_time = this.frontEndDateFormat(element.login_time);
        });
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.my_file {

    bottom: 0;
    outline: none;
    color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 35px;
    cursor: pointer;
    transition: 0.5s;
}

.my_file::-webkit-file-upload-button {
    visibility: hidden;
}
</style>
