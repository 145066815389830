<template>
<div>

    <div class="w-full hidden md:hidden mx-auto lg:flex p-1 flex-row items-between text-gray-600 body-font bg-blue-50 top-0 fixed z-50">
        <router-link to="/">
            <a class="flex title-font font-medium items-center mb-4 md:mb-0">
                <img class="w-12 h-12 p-2" src="../assets/img/logo.png" alt="">
                <span class="ml-3 text-xl font-bold text-gray-600">ZEALBOTS</span>
            </a>
        </router-link>
        <nav class="md:ml-auto  flex flex-wrap items-center text-base justify-center text-gray-800">
            <router-link to="/dashboard">
                <div v-if="isLoggedIn" class="relative cursor-pointer px-2">
                    <a class="cursor-pointer hover:text-blue-800">
                        <!-- <font-awesome-icon  class="mr-2 text-gray-600 text-lg" :icon="['fas', 'user-astronaut']" /> -->
                        <span class="text-lg">Dashboard</span>
                    </a>
                </div>
                <!-- <a class="cursor-pointer hover:text-blue-800"></a> -->
            </router-link>
            <router-link to="/download">
                <div class="relative cursor-pointer px-2">
                    <a class="cursor-pointer hover:text-blue-800">
                        <!-- <font-awesome-icon  class="mr-2 text-gray-600 text-lg" :icon="['fas', 'user-astronaut']" /> -->
                        <span class="text-lg">Download</span>
                    </a>
                </div>
                <!-- <a class="cursor-pointer hover:text-blue-800"></a> -->
            </router-link>
            <router-link to="/pricing">
                <div class="relative cursor-pointer px-2">
                    <a class="cursor-pointer hover:text-blue-800">
                        <!-- <font-awesome-icon  class="mr-2 text-gray-600 text-lg" :icon="['fas', 'user-astronaut']" /> -->
                        <span class="text-lg">Pricing</span>
                    </a>
                </div>
                <!-- <a class="cursor-pointer hover:text-blue-800"></a> -->
            </router-link>
            <div @click="zbot()" v-if="isLoggedIn" class="relative cursor-pointer">
                <!-- @mouseover="bothover(true)" @mouseleave="bothover(false)"> -->
                <a class="mr-5 cursor-pointer hover:text-blue-800">Mybots
                    <font-awesome-icon class="ml-1 text-blue-400 text-sm" :icon="['fas', 'caret-down']" />
                </a>
                <div v-if="BotListInfo!=null" class="w-40 relative mt-4 md:absolute z-10" :class="{'block':botDisplay, 'hidden':!botDisplay}">

                    <ul class="bg-white rounded-lg shadow-lg">
                        <span v-for="n in botData" :key="n">
                            <li @click="open_bot(n.id)" class="py-3 px-6 hover:bg-gray-100">
                                {{n.bot.bot_name}}
                            </li>
                        </span>
                        <span v-if="this.BotListInfo.count<bot_limit">
                            <a href="/newbot">
                                <li class="py-3 px-6 hover:bg-gray-100">
                                    New Bot
                                </li>
                            </a>
                        </span>
                        <span v-else>
                            <a href="/pricing">
                                <li class="py-3 px-6 text-green-500 hover:text-gray-600 hover:bg-gray-100">
                                    Upgrade
                                </li>
                            </a>
                        </span>
                    </ul>
                </div>
            </div>

            <div v-if="isLoggedIn" class="relative cursor-pointer" @click="zkloud()">
                <!-- @mouseover="zkloudhover(true)" @mouseleave="zkloudhover(false)"> -->
                <slot name="button">
                    <a class="mr-5 cursor-pointer hover:text-blue-800">Zkloud
                        <font-awesome-icon class="ml-1 text-blue-400 text-sm" :icon="['fas', 'caret-down']" />
                    </a>
                </slot>
                <div class="w-40 relative md:absolute z-10 mt-4" :class="[{'block':zkloudDisplay, 'hidden':!zkloudDisplay}]">
                    <ul class="bg-white rounded-lg shadow-lg">
                        <li class="py-3 px-6 hover:bg-gray-100">
                            <a href="/zkloud"> Recent</a>
                        </li>
                        <li class="py-3 px-6 hover:bg-gray-100">
                            <a href="/zkloud"> My files</a>

                        </li>
                        <li class="py-3 px-6 hover:bg-gray-100">
                            <a href="/zkloud"> Favorite</a>

                        </li>

                    </ul>
                </div>
            </div>
            <div v-else class="relative cursor-pointer px-2">
                <router-link to="/why_zkloud">
                    <a class="cursor-pointer hover:text-blue-800">
                        <!-- <font-awesome-icon  class="mr-2 text-gray-600 text-lg" :icon="['fas', 'cloud']" /> -->
                        <span class="text-lg">Zkloud</span>
                    </a>
                </router-link>
            </div>

            <div v-if="isLoggedIn" class="relative cursor-pointer" @click="collection()">
                <!-- @mouseover="collectionhover(true)" @mouseleave="collectionhover(false)"> -->
                <slot name="button">
                    <a class="mr-5 cursor-pointer hover:text-blue-800">Collections
                        <font-awesome-icon class="ml-1 text-blue-400 text-sm" :icon="['fas', 'caret-down']" />
                    </a>
                </slot>
                <div class="w-48 -ml-12 relative md:absolute mt-4 z-10" :class="[{'block':collectionDisplay, 'hidden':!collectionDisplay}]">
                    <slot name="menu">
                        <ul class="bg-white rounded-lg shadow-lg">
                            <span v-for="n in collectionData" :key="n" @click="collectionArticleClick(n.id)">

                                <li class="py-3 px-6 hover:bg-gray-100">
                                    {{n.name.split(":")[0]}}
                                </li>
                            </span>
                        </ul>

                    </slot>
                </div>
            </div>
            <div>
                <router-link v-if="!isLoggedIn" to="/why_zealbots">
                    <div class="relative cursor-pointer px-2">
                        <a class="cursor-pointer hover:text-blue-800">
                            <!-- <font-awesome-icon  class="mr-2 text-gray-600 text-lg" :icon="['fas', 'user-astronaut']" /> -->
                            <span class="text-lg">Zealbots</span>
                        </a>
                    </div>
                    <!-- <a class="cursor-pointer hover:text-blue-800"></a> -->
                </router-link>
            </div>
            <div v-if="isLoggedIn" class="relative cursor-pointer px-2">
                <router-link to="/">
                    <a @click="showLogOutModal = !showLogOutModal" class="cursor-pointer hover:text-blue-800">
                        <!-- <font-awesome-icon  class="mr-2 text-gray-600 text-lg" :icon="['fas', 'sign-out-alt']" /> -->
                        <span class="text-lg">Logout</span>
                    </a>
                </router-link>
            </div>
            <div v-else class="relative cursor-pointer px-2">
                <router-link to="/login">
                    <a class="cursor-pointer hover:text-blue-800">
                        <!-- <font-awesome-icon  class="mr-2 text-gray-600 text-lg" :icon="['fas', 'sign-in-alt']" /> -->
                        <span class="text-lg">Login</span>
                    </a>
                </router-link>
            </div>

            <div class="block md:hidden cursor-pointer px-2">
                <font-awesome-icon class="text-gray-500 hover:text-blue-800 text-xl" :icon="['fas', 'bars']" />
            </div>
        </nav>
    </div>

    <!-- second_nav -->
    <nav class="lg:hidden flex fixed w-full items-center px-3 h-16 bg-blue-50 z-50 border">
        <div class="w-full flex flex-row justify-between items-center self-end">
            <router-link to="/">
                <a class="flex title-font font-medium items-center md:mb-0">
                    <img class="w-12 h-12 p-2" src="../assets/img/logo.png" alt="">
                    <span class="ml-3 text-xl font-bold text-gray-600">ZEALBOTS</span>
                </a>
            </router-link>
            <div class="flex flex-row ">

                <div v-if="isLoggedIn" class="hidden md:flex">
                    <div class="">
                        <img :src="$static.path(require('@/assets/profile-pic.png'))" class="w-12 h-12 rounded-full" />
                    </div>
                    <div class="mx-2  pt-3">
                        <div class="font-semibold text-black truncate">{{ Username.charAt(0).toUpperCase()+Username.slice(1) }}</div>

                    </div>
                </div>
                <div class="mx-2  pt-2">
                    <button class="mr-2" aria-label="Open Menu" @click="drawer">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8">
                            <path d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>

            </div>

        </div>

        <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100" leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
            <div @keydown.esc="isOpen = false" v-show="isOpen" class="z-10 fixed inset-0 transition-opacity">
                <div @click="isOpen = false" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
            </div>
        </transition>
        <aside class="transform top-0 left-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30" :class="isOpen ? 'translate-x-0' : '-translate-x-full'">
            <router-link to="/" class="flex w-full items-center p-2 border-b cursor-pointer">

                <div class="flex flex-col w-52 mt-2 p-2 justify-start  rounded-lg bg-green-500 bg-opacity-25">
                    <div>
                        <img :src="$static.path(require('@/assets/profile-pic.png'))" class="w-14 h-14 rounded-full" />
                    </div>
                    <div class="pt-3">
                        <div class="font-semibold text-black truncate">{{ Username.charAt(0).toUpperCase()+Username.slice(1) }}</div>
                        <p class="text-black text-sm xl:text-xs hover:text-green-400">Researcher</p>
                    </div>

                </div>

            </router-link>
            <router-link v-if="isLoggedIn" to="/dashboard" class="flex items-center p-4 hover:bg-teal-500 hover:text-white">
                <span class="mr-2">
                    <span>
                        <svg class="fill-current ml-1 h-5 w-5" viewBox="0 0 24 24">
                            <path d="M16 20h4v-4h-4m0-2h4v-4h-4m-6-2h4V4h-4m6
                            4h4V4h-4m-6 10h4v-4h-4m-6 4h4v-4H4m0 10h4v-4H4m6
                            4h4v-4h-4M4 8h4V4H4v4z" />
                        </svg>
                    </span>

                </span>
                <span>Dashboard</span>
            </router-link>
            <router-link to="/download" class="flex items-center p-4 hover:bg-teal-500 hover:text-white">
                <span class="mr-2">
                    <font-awesome-icon class="hover:text-white text-xl cursor-pointer ml-1 " :icon="['fas', 'download']" />
                </span>
                <span>Download</span>
            </router-link>
            <router-link to="/pricing" class="flex items-center p-4 hover:bg-teal-500 hover:text-white">
                <span class="mr-2">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                        <path d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                        </path>
                    </svg>
                </span>
                <span>Pricing</span>
            </router-link>

            <span v-if="isLoggedIn" @click="botClick()" class="flex items-center p-4 hover:bg-teal-500 hover:text-white cursor-pointer">
                <span class="mr-2">
                    <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" class="w-6 h-6">
                        <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                    </svg>
                </span>
                <!-- mob view Botlist -->
                <span>Mybots</span>
            </span>
            <div v-if="isBot && isLoggedIn && BotListInfo!=null">
                <span v-for="n in botData" :key="n" class="px-10 flex items-center p-4 hover:bg-teal-500 hover:text-white" @click="open_bot(n.id)">
                    <span class="mr-2">
                        <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                        </svg>
                    </span>
                    <span class="cursor-pointer"> {{n.bot.bot_name}}</span>
                </span>
                <span v-if="this.BotListInfo.count<bot_limit" class="px-10 flex items-center p-4 hover:bg-teal-500 hover:text-white">
                    <span class="mr-2">
                        <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                        </svg>
                    </span>
                    <a href="/newbot" class=" hover:bg-gray-100">
                        New Bot
                    </a>
                </span>
                <span v-else class="px-10 flex items-center p-4 hover:bg-teal-500 hover:text-white">
                    <span class="mr-2">
                        <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path>
                        </svg>
                    </span>
                    <a href="/pricing" class="  hover:text-gray-600 text-green-500 hover:bg-gray-100">

                        Upgrade

                    </a>
                </span>
            </div>

            <span v-if="isLoggedIn" @click="zkloudClick()" class="flex items-center p-4 hover:bg-teal-500 hover:text-white cursor-pointer">
                <span class="mr-2">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                        <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                        </path>
                    </svg>
                </span>
                <span>Zkloud</span>
            </span>

            <a href="/why_zkloud" v-else class="flex items-center p-4 hover:bg-teal-500 hover:text-white cursor-pointer">

                <span class="mr-2">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                        <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                        </path>
                    </svg>
                </span>
                <span>Zkloud</span>
            </a>

            <div v-if="isZkloud">
                <a href="/zkloud" class="px-10 flex items-center p-4 hover:bg-teal-500 hover:text-white">
                    <span class="mr-2">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                            </path>
                        </svg>
                    </span>
                    <span>myfiles</span>
                </a>
                <a href="/zkloud" class="px-10 flex items-center p-4 hover:bg-teal-500 hover:text-white">
                    <span class="mr-2">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                            </path>
                        </svg>
                    </span>
                    <span>Recent files</span>
                </a>
                <a href="/zkloud" class="px-10 flex items-center p-4 hover:bg-teal-500 hover:text-white">
                    <span class="mr-2">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z">
                            </path>
                        </svg>
                    </span>
                    <span>Trash</span>
                </a>
            </div>

            <span v-if="isLoggedIn" @click="collectionClick()" class=" cursor-pointer flex items-center bg-white p-4 hover:bg-teal-500 hover:text-white cursor">
                <span class="mr-2">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                        <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                        </path>
                    </svg>
                </span>
                <span class="">Collections</span>
            </span>
            <div v-if="isCollection">
                <span v-for="n in collectionData" :key="n" class="px-10 flex items-center p-4 hover:bg-teal-500 hover:text-white cursor-pointer" @click="collectionArticleClick(n.id)">
                    <span class="mr-2">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                            </path>
                        </svg>
                    </span>
                    <span class="mr-2 "> {{n.name.split(":")[0]}}

                    </span>

                </span>
            </div>
            <span v-if="!isLoggedIn" class=" cursor-pointer flex items-center bg-white p-4 hover:bg-teal-500 hover:text-white cursor">
                <a href="/why_zealbots">
                    <span class="mr-2">
                        <font-awesome-icon class="hover:text-white text-xl cursor-pointer  " :icon="['fas', 'robot']" />
                    </span>
                    <span class="">Zealbots</span>
                </a>
            </span>
            <a v-if="isLoggedIn" @click="showLogOutModal = !showLogOutModal" class="flex items-center p-4 hover:bg-teal-500 hover:text-white">
                <span class="">

                    <font-awesome-icon class="hover:text-white text-2xl cursor-pointer ml-1 " :icon="['fas', 'sign-out-alt']" />
                </span>
                <span class="ml-2"> Logout</span>
            </a>
            <a v-else href="/login" class="flex items-center p-4 hover:bg-teal-500 hover:text-white">
                <span class="">

                    <font-awesome-icon class="hover:text-whitetext-2xl cursor-pointer ml-1 " :icon="['fas', 'sign-out-alt']" />
                </span>
                <span class="ml-2"> Login</span>
            </a>

        </aside>
    </nav>
    <LogoutConfirmation v-if="showLogOutModal" @logOut="logout" @cancel="showLogOutModal = !showLogOutModal" />
</div>
</template>

<script>
// import axios from 'axios';
import {
    mapActions,
    mapGetters
} from "vuex";
import LogoutConfirmation from '@/components/LogoutConfirmation.vue'
export default {

    data() {
        return {
            loggedIn: false,
            menuItems: [],
            isActive: false,
            isOpen: false,
            isBot: false,
            isZkloud: false,
            isCollection: false,
            collectionData: [],
            botData: [],
            botDisplay: false,
            zkloudDisplay: false,
            collectionDisplay: false,
            bot_limit: null,
            showLogOutModal: false
        }
    },
    components: {
        LogoutConfirmation
    },

    methods: {

        ...mapActions(["LogOut", "todoCacheClear"]),

        drawer() {
            this.isOpen = !this.isOpen;
        },
        bothover(value) {
            this.botDisplay = value;
        },
        zkloudhover(value) {
            this.zkloudDisplay = value;
        },
        collectionhover(value) {
            this.collectionDisplay = value;
        },
        collection() {
            this.collectionDisplay = !this.collectionDisplay;
            this.botDisplay = false;
            this.zkloudDisplay = false;
        },
        zbot() {
            this.botDisplay = !this.botDisplay;
            this.collectionDisplay = false;
            this.zkloudDisplay = false;

        },

        open_bot(bot_id) {
            this.$router.push("/bot/" + bot_id);
        },

        zkloud() {
            this.zkloudDisplay = !this.zkloudDisplay;
            this.botDisplay = false;
            this.collectionDisplay = false;
        },
        botClick() {
            this.isBot = !this.isBot;
            this.isZkloud = false;
            this.isCollection = false;
        },
        zkloudClick() {
            this.isZkloud = !this.isZkloud;
            this.isBot = false;
            this.isCollection = false;
        },
        collectionClick() {
            this.isCollection = !this.isCollection;
            this.isZkloud = false;
            this.isBot = false;
        },
        collectionArticleClick(collection_id) {
            this.$router.push("/collection/" + collection_id);
        },

        async logout() {
            // this.$router.push("/login");
            await this.LogOut();
            await this.todoCacheClear();
            this.showLogOutModal = !this.showLogOutModal
        },

    },
    watch: {
        isOpen: {
            immediate: true,
            handler(isOpen) {
                if (process.client) {
                    if (isOpen) document.body.style.setProperty("overflow", "hidden");
                    else document.body.style.removeProperty("overflow");
                }
            }
        }
    },
    computed: {
        ...mapGetters(["BotListInfo", "UserAssetsInfo"]),
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        },
        User: function () {
            return this.$store.getters.StateUser;
        },
        UserInfo: function () {
            return this.$store.getters.StateUserInfo;
        },
        Username: function () {
            if (this.$store.getters.StateUser) {
                return this.$store.getters.StateUser.split("@")[0];
            } else return "UserName";
        }

    },
    created: async function () {
        this.bot_limit = this.$store.getters.UserAssetsInfo.bot_count;
    },
    async mounted() {
        this.collectionDisplay = false;
        this.botDisplay = false;
        this.zkloudDisplay = false;
        this.isBot = false;
        this.isZkloud = false;
        this.isCollection = false;
        document.addEventListener("keydown", e => {
            if (e.keyCode == 27 && this.isOpen) this.isOpen = false;
        });

        if (this.isLoggedIn) {
            this.collectionData = this.$store.getters.CollectionListInfo.data
            this.botData = this.$store.getters.BotListInfo.data

        }

    }
}
</script>

<style>
.active {
    text-decoration: underline;
}
</style>
