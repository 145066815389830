<template>
<nav class="sm:flex flex-col md:hidden border-0 bg-gradient-to-b  absolute z-30 top-4">

    <div class="pt-2 ">
        <button class="bg-black opacity-50 rounded-r-md text-white border border-white" aria-label="Open Menu" @click="drawer">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8 text-white">
                <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
        </button>
    </div>
    <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100" leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
        <div @keydown.esc="isOpen = false" v-show="isOpen" class="z-10 fixed inset-0 transition-opacity">
            <div @click="isOpen = false" class="absolute inset-0 bg-black opacity-75" tabindex="0"></div>
        </div>
    </transition>

    <aside class="transform top-4 left-0  fixed h-full  ease-in-out transition-all duration-300 z-30 " :class="isOpen ? 'translate-x-0' : '-translate-x-full'">
        <div class="realtive mt-4 p-1   ">
            <ul class="text-sm">
                <!-- Dashboard -->
                <router-link to="/dashboard" title="Dashboard">
                    <li :class=' { "active": selected == "0" } ' class="mb-1 px-4 py-3 items-center gap-3  text-gray-100 flex flex-row  hover:text-black hover:bg-gray-100 hover:font-semibold rounded hover:rounded-md border border-gray-200 border-opacity-25 rounded-xl">
                        <span>
                            <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                                <path d="M16 20h4v-4h-4m0-2h4v-4h-4m-6-2h4V4h-4m6
                            4h4V4h-4m-6 10h4v-4h-4m-6 4h4v-4H4m0 10h4v-4H4m6
                            4h4v-4h-4M4 8h4V4H4v4z" />
                            </svg>
                        </span> <h1>Dashboard</h1>

                    </li>
                </router-link>

                <!-- Home -->

                <li @click="Homepage()" title="Home" class=" flex flex-row mb-1 px-4 py-3 items-center gap-3 cursor-pointer text-gray-100  hover:text-gray-800 hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border border-gray-200 border-opacity-25 rounded-xl ">
                    <span class="">
                        <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'home']" />  
                    </span><h1>Home</h1>

                </li>

                <!-- Bot click -->
                <li @click="isBot = !isBot; isCollection = false" :class=' { "active": selected == "1" } ' title="Botlist" class="mb-1 px-4 py-3 items-center gap-3 text-gray-100 flex flex-row border-gray-300 select-none hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border  border-opacity-25 cursor-pointer rounded-xl">
                    <span>
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                    </span><h1>My Bot</h1>

                </li>

                <div v-if=" isBot && BotListInfo!=null" class="absolute w-52 bg-gray-400 rounded rounded-sm overflow-hidden select-none z-50">

                    <span v-for="bot_name in BotListInfo.data" :key="bot_name" @click.prevent="botArticleClick(bot_name)" :class=" {'dropdown_active': bot_id == bot_name.bot.id} " class="flex bg-gray-50 border-solid border-b-2 border-gray-300 px-3 py-3 text-gray-900 items-center  hover:bg-gray-300 hover:font-bold cursor-pointer">
                        <span class="mr-2 ">
                            <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                        </span>
                        <div class="truncate">{{bot_name.bot.bot_name}} </div>
                    </span>
                    <!-- redirect to bot creation page -->
                    <div>
                        <span v-if="BotListInfo.count<bot_limit " @click="newbotpage()" class="border-solid bg-gray-50 flex text-black items-center p-3  hover:bg-gray-300 hover:font-bold cursor-pointer">
                            <span class="mr-2">
                                <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                            </span>
                            <span class="truncate">New Bot</span>
                        </span>

                        <span v-else class="border-solid bg-gray-50 flex text-black items-center p-3  hover:bg-gray-300 hover:font-bold cursor-pointer"><a href="/pricing">
                                <span class="mr-2">
                                    <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                                </span>
                                <span class="truncate text-green-500">Upgrade</span>
                            </a>
                        </span>

                    </div>
                </div>

                <!-- Collection -->
                <li title="Collections" @click="isCollection = !isCollection; isBot = false; isCiteman = false" :class=' { "active": selected == "2" } ' class="mb-1 px-4 py-3 items-center gap-3 text-gray-100 flex flex-row border-gray-300 select-none hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border border-gray-200 border-opacity-25 cursor-pointer rounded-xl">
                    <span>
                        <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'paste']" />
                    </span><h1>Collection</h1>

                </li>

                <div v-if="isCollection " class="absolute w-52 bg-gray-400 rounded rounded-sm overflow-hidden select-none z-50">
                    <span v-for="collection_name in CollectionListInfo.data" :key="collection_name" @click.prevent="collectionArticleClick(collection_name)" :class="{ 'dropdown_active': collection_id == collection_name.id }" class="flex bg-gray-50 border-solid border-b-2 border-gray-300 px-4 py-3 text-gray-800 items-center  hover:bg-gray-300 hover:font-bold cursor-pointer">
                        <span class="mr-2 ">
                            <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'paste']" />
                        </span>
                        <span class="truncate" v-text="collectionNameSplit(collection_name.name)"></span>
                    </span>
                </div>

                <!-- Citeman -->
                <li title="Citeman" @click="isCiteman = !isCiteman; isCollection = false; isBot = false" :class=' { "active": selected == "3" } ' class="mb-1 px-4 py-3 items-center gap-3 text-gray-100 flex flex-row border-gray-300 hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border border-opacity-25 cursor-pointer rounded-xl">
                    <span>
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'quote-right']" />
                    </span><h1>Citeman</h1>

                </li>
                <div v-if="isCiteman" class="absolute w-52 z-50 bg-gray-200 rounded rounded-sm overflow-hidden select-none">
                    <span v-for="Citeman_name in CitemanList.data" :key="Citeman_name" @click.prevent="citemanArticleClick(Citeman_name)" class="p-3 bg-gray-50 border-solid border-b-2 border-gray-200 flex text-gray-900 items-center hover:text-black hover:bg-gray-300 hover:font-bold cursor-pointer">
                        <span class="mr-2 ">
                            <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'quote-right']" /></span>
                        <span class="truncate" v-text="collectionNameSplit(Citeman_name.name)"></span>
                    </span>
                    <span class="border-solid bg-gray-400 flex text-black items-center p-3 hover:text-white hover:bg-gray-500 hover:font-bold cursor-pointer">
                        <span class="mr-2">
                            <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'quote-right']" />
                        </span>
                        <span class="truncate">New Citeman</span>
                    </span>
                </div>

                <!-- Todo Tasks -->

                <li title="Tasks" @click="routeTask(0)" :class=' { "active": selected == "10" } ' class=" flex flex-row mb-1 px-4 py-3 items-center gap-3 cursor-pointer text-gray-100 border-gray-50 hover:text-gray-800 hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border  border-opacity-25 rounded-xl">
                    <span>
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'list']" /> </span><h1>Tasks</h1>

                </li>

                <!-- Zkloud -->
                <router-link to="/zkloud" title="Zkloud">
                    <li :class=' { "active": selected == "5" } ' class="flex flex-row mb-1 px-4 py-3 items-center gap-3 text-gray-100 border-gray-50 hover:text-gray-700 hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border border-opacity-25 rounded-xl">
                        <span>
                            <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'cloud']" />
                        </span><h1>Zkloud</h1>

                    </li>
                </router-link>

                <!-- Plugin -->
                <router-link to="/plugin" title="Plugins">
                    <li :class=' { "active" : selected == "7" } ' class="mb-1 px-4 py-3 items-center gap-3 text-gray-100 flex flex-row border-gray-50 hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border  border-opacity-25 rounded-xl">
                        <span>
                            <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'swatchbook']" />
                        </span><h1>Plugin</h1>

                    </li>
                </router-link>

                <!-- Settings  -->
                <router-link to="/settings" title="Settings">
                    <li :class=' { "active" : selected == "6" } ' class="mb-1 px-4 py-3 items-center gap-3 text-gray-100 flex flex-row border-gray-50 hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border border-opacity-25 rounded-xl">
                        <span>
                            <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'users-cog']" />
                        </span><h1>Settings</h1>

                    </li>
                </router-link>

                <!-- Log out -->
                <li title="Logout" v-on:click="toggleLogOutModal()" class="px-4 py-3 items-center gap-3 text-gray-100 flex flex-row border-gray-50 hover:text-gray-700 hover:bg-gray-100 hover:font-bold border border-opacity-25 rounded  cursor-pointer rounded-xl">
                    <span>
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'sign-out-alt']" />
                    </span><h1>Log out</h1>
                    <a>

                    </a>
                </li>
            </ul>
        </div>
        
        <!-- Logout pop-up -->
        <!-- <div v-if="showLogOutModal" class="overflow-x-hidden overflow-y-auto bg-black bg-opacity-60 fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
        <div class="relative w-auto my-6 mx-auto max-w-sm">
          
            <div class="rounded-md shadow-2xl relative flex flex-col overflow-hidden w-full bg-white outline-none focus:outline-none">
          
                <div class="flex bg-gradient-to-r from-green-600 via-purple-700 to-blue-800 text-white items-start justify-center p-4 border-b border-solid border-gray-50">
                    <h3 class="text-xl font-semibold">Please confirm</h3>
                </div>
          
                <div class="relative pl-8 pr-8 pt-4 pb-4 flex-auto">
                    <p class="my-4 text-gray-700 text-md leading-relaxed">
                        Are you sure want to log out?
                    </p>
                </div>
          
                <div class="flex gap-8 items-center justify-center pb-6">
                    <button class="max-w-24 w-24 text-teal-500 bg-transparent border border-solid border-teal-500 hover:bg-teal-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="toggleLogOutModal()">Cancel</button>                    <button class="max-w-24 w-24 text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="submit">Exit</button>
                </div>
            </div>
        </div>
    </div> -->

    </aside>

</nav>
<LogoutConfirmation v-if="showLogOutModal" @logOut="submit" @cancel="this.showLogOutModal = !this.showLogOutModal" />

<!-- 1st Nav -->
<nav class="hidden md:flex overflow-y-auto flex-col border-0 bg-gradient-to-b from-teal-600 via-purple-600 to-indigo-900 max-w-56 h-screen px-2 ">

    <div class="flex flex-col w-52 mt-2 p-2 justify-start rounded-lg bg-gray-900 bg-opacity-25">
        <div>
            <img :src="$static.path(require('@/assets/profile-pic.png'))" class="w-14 h-14 rounded-full" />
        </div>
        <div @click="toggleMenu = !toggleMenu" class="pt-3">
            <div class="font-semibold text-white truncate">{{ Username }}</div>
            <p class="text-teal-300 text-sm xl:text-xs hover:text-green-400">Researcher</p>
        </div>

    </div>

    <div class="realtive mt-4 p-1 rounded-lg bg-gray-900 bg-opacity-25">
        <ul class="text-sm">
            <!-- Dashboard -->
            <router-link to="/dashboard">
                <li :class=' { "active": selected == "0" } ' class="mb-1 px-4 py-3 text-gray-100 flex flex-row border-gray-50 hover:text-black hover:bg-gray-100 hover:font-semibold rounded hover:rounded-md border-b border-gray-200 border-opacity-25">
                    <span>
                        <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                            <path d="M16 20h4v-4h-4m0-2h4v-4h-4m-6-2h4V4h-4m6
                            4h4V4h-4m-6 10h4v-4h-4m-6 4h4v-4H4m0 10h4v-4H4m6
                            4h4v-4h-4M4 8h4V4H4v4z" />
                        </svg>
                    </span>
                    <span class="ml-2 outline-none">Dashboard</span>
                </li>
            </router-link>

            <!-- Home -->

            <li @click="Homepage()" class=" flex flex-row mb-1 px-4 py-3 cursor-pointer text-gray-100 border-gray-50 hover:text-gray-800 hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25">
                <span class="">
                    <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'home']" />
                </span>
                <span class="ml-2 cursor-pointer">Home</span>

            </li>

            <!-- Bot click -->
            <li @click="isBot = !isBot; isCollection = false" :class=' { "active": selected == "1" } ' class="mb-1 px-4 py-3 text-gray-100 flex flex-row border-gray-50 select-none hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25 cursor-pointer">
                <span>
                    <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                </span>
                <span class="ml-2">Bot</span>
            </li>

            <div v-if=" isBot && BotListInfo!=null" class="flex flex-col absolute w-52 rounded rounded-sm overflow-x-hidden select-none z-50">

                <span v-for="bot_name in BotListInfo.data" :key="bot_name" @click.prevent="botArticleClick(bot_name)" :class=" {'dropdown_active': bot_id == bot_name.bot.id} " class="flex bg-gray-200 border-solid border-b-2 border-gray-50 px-3 py-3 text-gray-900 items-center  hover:bg-gray-300 hover:font-bold cursor-pointer">
                    <span class="mr-2 ">
                        <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                    </span>
                    <div class="truncate">{{bot_name.bot.bot_name}} </div>
                </span>
                <!-- redirect to bot creation page -->
                <div>
                    <span v-if="BotListInfo.count<bot_limit " @click="newbotpage()" class="border-solid bg-gray-200 flex text-black items-center p-3  hover:bg-gray-300 hover:font-bold cursor-pointer">
                        <span class="mr-2">
                            <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                        </span>
                        <span class="truncate">New Bot</span>
                    </span>

                    <span v-else class="border-solid bg-gray-200 flex text-black items-center p-3  hover:bg-gray-300 hover:font-bold cursor-pointer"><a href="/pricing">
                            <span class="mr-2">
                                <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'project-diagram']" />
                            </span>
                            <span class="truncate text-green-500">Upgrade</span>
                        </a>
                    </span>

                </div>
            </div>

            <!-- Collection -->
            <li @click="isCollection = !isCollection; isBot = false; isCiteman = false" :class=' { "active": selected == "2" } ' class="mb-1 px-4 py-3 text-gray-100 flex flex-row border-gray-50 select-none hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25 cursor-pointer">
                <span>
                    <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'paste']" />
                </span>
                <span class="ml-2">Collection</span> 
            </li>

            <div v-if="isCollection " class="absolute w-52 bg-gray-400 rounded rounded-sm overflow-hidden select-none z-50">
                <span v-for="collection_name in CollectionListInfo.data" :key="collection_name" @click.prevent="collectionArticleClick(collection_name)" :class="{ 'dropdown_active': collection_id == collection_name.id }" class="flex bg-gray-200 border-solid border-b-2 border-gray-50 px-4 py-3 text-gray-800 items-center  hover:bg-gray-300 hover:font-bold cursor-pointer">
                    <span class="mr-2 ">
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'paste']" />
                    </span>
                    <span class="truncate" v-text="collectionNameSplit(collection_name.name)"></span>
                </span>
            </div>

            <!-- Citeman -->
            <li @click="isCiteman = !isCiteman; isCollection = false; isBot = false" :class=' { "active": selected == "3" } ' class="mb-1 px-4 py-3 text-gray-100 flex flex-row border-gray-50 hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25 cursor-pointer">
                <span>
                    <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'quote-right']" />
                </span>
                <span class="ml-2">Citeman</span>
            </li>
            <div v-if="isCiteman" class="absolute w-52 z-50 bg-gray-200 rounded rounded-sm overflow-hidden select-none">
                <span v-for="Citeman_name in CitemanList.data" :key="Citeman_name" @click.prevent="citemanArticleClick(Citeman_name)" class="p-3 bg-gray-200 border-solid border-b-2 border-gray-50 flex text-gray-900 items-center hover:text-black hover:bg-gray-300 hover:font-bold cursor-pointer">
                    <span class="mr-2 ">
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'quote-right']" /></span>
                    <span class="truncate" v-text="collectionNameSplit(Citeman_name.name)"></span>
                </span>
                <span class="border-solid bg-gray-400 flex text-black items-center p-3 hover:text-white hover:bg-gray-500 hover:font-bold cursor-pointer">
                    <span class="mr-2">
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'quote-right']" />
                    </span>
                    <span class="truncate">New Citeman</span>
                </span>
            </div>

            <!-- Todo Tasks -->

            <li @click="routeTask(0)" :class=' { "active": selected == "10" } ' class=" flex flex-row mb-1 px-4 py-3 cursor-pointer text-gray-100 border-gray-50 hover:text-gray-800 hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25">
                <span>
                    <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'list']" /> </span>

                <span class="ml-2 cursor-pointer">Tasks</span>
            </li>

            <!-- Zkloud -->
            <router-link to="/zkloud">
                <li :class=' { "active": selected == "5" } ' class="flex flex-row mb-1 px-4 py-3 text-gray-100 border-gray-50 hover:text-gray-700 hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25">
                    <span>
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'cloud']" />
                    </span>
                    <span class="ml-2">Zkloud</span>
                </li>
            </router-link>

            <!-- Plugin -->
            <router-link to="/plugin">
                <li :class=' { "active" : selected == "7" } ' class="mb-1 px-4 py-3 text-gray-100 flex flex-row border-gray-50 hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25">
                    <span>
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'swatchbook']" />
                    </span>
                    <span class="ml-2">Plugins</span>
                </li>
            </router-link>

            <!-- Settings  -->
            <router-link to="/settings">
                <li :class=' { "active" : selected == "6" } ' class="mb-1 px-4 py-3 text-gray-100 flex flex-row border-gray-50 hover:text-black hover:bg-gray-100 hover:font-bold rounded hover:rounded-md border-b border-gray-200 border-opacity-25">
                    <span>
                        <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'users-cog']" />
                    </span>
                    <span class="ml-2">Settings</span>
                </li>
            </router-link>

            <!-- Log out -->
            <li v-on:click="toggleLogOutModal()" class="px-4 py-3 text-gray-100 flex flex-row border-gray-50 hover:text-gray-700 hover:bg-gray-100 hover:font-bold rounded rounded-md cursor-pointer">
                <span>
                    <font-awesome-icon class="  w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'sign-out-alt']" />
                </span>
                <a>
                    <span class="ml-2">Logout</span>
                </a>
            </li>
        </ul>
    </div>
    <LogoutConfirmation v-if="showLogOutModal" @logOut="submit" @cancel="this.showLogOutModal = !this.showLogOutModal" />
    <!-- Logout pop-up -->
    <!-- <div v-if="showLogOutModal" class="overflow-x-hidden overflow-y-auto bg-black bg-opacity-60 fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
        <div class="relative w-auto my-6 mx-auto max-w-sm">
          
            <div class="rounded-md shadow-2xl relative flex flex-col overflow-hidden w-full bg-white outline-none focus:outline-none">
          
                <div class="flex bg-gradient-to-r from-green-600 via-purple-700 to-blue-800 text-white items-start justify-center p-4 border-b border-solid border-gray-300">
                    <h3 class="text-xl font-semibold">Please confirm</h3>
                </div>
          
                <div class="relative pl-8 pr-8 pt-4 pb-4 flex-auto">
                    <p class="my-4 text-gray-700 text-md leading-relaxed">
                        Are you sure want to log out?
                    </p>
                </div>
          
                <div class="flex gap-8 items-center justify-center pb-6">
                    <button class="max-w-24 w-24 text-teal-500 bg-transparent border border-solid border-teal-500 hover:bg-teal-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="toggleLogOutModal()">Cancel</button>                    <button class="max-w-24 w-24 text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="submit">Exit</button>
                </div>
            </div>
        </div>
    </div> -->

    <div class="grid grid-cols-1 place-content-end h-full mb-1">
        <div class="flex flex-col place-content-end w-52 mt-2 p-1 justify-start rounded rounded-lg bg-gray-800 bg-opacity-30">
            <div class="">
                <img :src="$static.path(require('@/assets/faq.svg'))" class="bg-white w-52 rounded-md" />
            </div>
            <div class="flex flex-col p-3 justify-center conter-center">
                <div class="font-semibold text-white truncate">Need help?</div>
                <p class="text-teal-300 text-sm xl:text-xs hover:text-green-400">Read Documentation</p>
            </div>
        </div>
    </div>
    <div class="mb-4">
        <div class="flex flex-col w-52 rounded rounded-lg bg-gray-800 bg-opacity-50">
            <div class="flex flex-col p-3 justify-center content-center">
                <p class="tracking-wider text-gray-50 text-xs lg:text-md xl:text-xs hover:text-green-400">www.zealbots.com</p>
            </div>
        </div>
    </div>
</nav>
</template>

<script>
// import { ref } from 'vue';
import {
    mapActions,
    mapGetters
} from "vuex";
import LogoutConfirmation from '@/components/LogoutConfirmation.vue'
const fs = require('fs');

export default {
    data() {
        return {
            bot_list: [],
            showLogOutModal: false,
            isBot: false,
            isCollection: false,
            isCiteman: false,
            Citeman_list: [],
            toggleMenu: false,
            bot_limit: null,
            Collection_limit: null,
            isOpen: false,
        }
    },
    props: {
        selected: String,
        bot_id: String,
        collection_id: String,
    },
    components: {
        LogoutConfirmation
    },
    methods: {
        ...mapActions(["GetBotList", "GetCollectionList", "GetCiteman_list", "LogOut", "todoCacheClear", "logoutCognitoUser"]),
        async submit() {
            try {

                await this.logoutCognitoUser(this.awsApi)
                await this.LogOut();
                await this.todoCacheClear();
                this.$router.push("/");
                this.showError = false;
            } catch (error) {
                this.showError = true;
            }
        },

        newbotpage() {
            this.$router.push('/newbot/');
        },
        Homepage() {
            this.$router.push('/')
        },

        toggleLogOutModal() {
            this.showLogOutModal = !this.showLogOutModal
        },
        botArticleClick(botname) {
            console.log("/bot/" + botname.id)
            this.$router.push("/bot/" + botname.id);
        },
        collectionArticleClick(collection) {
            console.log("/collection/" + collection.id)
            this.$router.push("/collection/" + collection.id);
        },
        citemanArticleClick(Citeman_name) {
            console.log("/citeman/" + Citeman_name.id)
            this.$router.push("/citeman/" + Citeman_name.id);
        },
        collectionNameSplit(data) {
            return data.split(':')[0]
        },
        routeTask(id) {
            this.$router.push("/tasks/" + id)
        },
        drawer() {
            this.isOpen = !this.isOpen;
        },

        // For Removing given directory path recursively => Abandoned for now
        removeDir(path) {
            if (fs.existsSync(path)) {
                const files = fs.readdirSync(path)
                console.log({
                    files
                })

                if (files.length > 0) {
                    files.forEach(function (filename) {
                        let dirPath = `${path}/${filename}`
                        if (fs.statSync(dirPath).isDirectory()) {
                            fs.rmdirSync(dirPath, {
                                recursive: true
                            }, (err) => {
                                if (err) {
                                    throw err
                                }
                            })
                        } else {
                            fs.unlinkSync(dirPath)
                        }
                    })
                }
            }
        }
    },
    computed: {
        ...mapGetters(["logOutStatus", "awsApi", "BotListInfo", "CitemanList", "UserAssetsInfo","CollectionListInfo"]),
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        },
        Username: function () {
            if (this.$store.getters.StateUser) {
                return this.$store.getters.StateUser.split("@")[0];
            } else return "UserName";
        }
    },
    created: async function () {
        //this for User assets bot and Mumber collection count

        this.bot_limit = this.UserAssetsInfo.bot_count;
        this.Collection_limit = this.UserAssetsInfo.collection_count;

        var userInfo = this.$store.getters.StateUserInfo;
        var user_data = {
            user: userInfo.id,
            key: userInfo.key
        };
        var result = await this.GetBotList(user_data);
        if (result) {
            this.bot_list = result.data.data;
        }
        var citemanlist = await this.GetCiteman_list(user_data);
        if (citemanlist) {
            this.Citeman_list = citemanlist.data.data;

        }

    },

};
</script>

<style scoped>
.logout {
    cursor: pointer;
}

.active {
    background: rgb(241, 255, 253);
    color: black;
}

.dropdown_active {
    background: rgb(241, 255, 253);
    color: black;

}
</style>
