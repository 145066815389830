import axios from 'axios';
axios.defaults.withCredentials = false
axios.defaults.baseURL = 'https://api.zealbots.com/api/v1/';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// Things to do in future
// error handling 

const state = {
    notes: []
};

const getters = {
    todoInfo: (state) => state.notes
};

const actions = {
    // Getting all Notes from API
    getAllNotes({ commit }, data) {
        return new Promise((resolve, reject) => {
            var note_get_url = `notes/view/?article=${data.article_id}&Key=${data.user_key}&format=json`
            axios.get(note_get_url)
                .then(response => {
                    if (response && response.status == 200 && !("error" in response.data)) {
                        commit("setNotes", response.data.data)
                        console.log(response.data.data)
                        resolve(response.data.data);
                    } else {
                        commit("setNotes", [])
                        reject(response)
                    }
                }).catch(error => {
                    reject(error);
                })
        });
    },
    createNote({ commit }, data) {
        return new Promise((resolve, reject) => {
            var note_create_url = `notes/new/?Key=${data.user}`
            axios.post(note_create_url, data)
                .then(response => {
                    if (response && response.status == 200 && !("error" in response.data)) {
                        commit("newNote", response.data.data)
                        console.log(response.data.data)
                        resolve(response.data.data);
                    } else {
                        // commit("setNotes", [])
                        reject(response)
                    }
                }).catch(error => {
                    reject(error);
                })
        });
    },

};

const mutations = {
    setNotes(state, notes) {
        state.notes = notes
    },
    newNote(state, note) {
        state.notes = state.notes.unshift(note)
    },
    deleteNote(state, id) {
        state.notes = state.notes.filter((note) => note.id !== id)
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}