<template>
<div class="w-3/4 h-5/6 mx-auto bg-white rounded-xl overflow-y-auto px-24 py-8 ">
    <!--Header -->
        <h1 class="text-2xl font-semibold text-indigo-700">Add Article Manually</h1>

    <!--body -->
    <div class="grid grid-cols-3 gap-2 py-4">
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Type</label>
            <input type="text" class="bg-indigo-100 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder=""/>
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Title*</label>
            <input type="text" v-model="data.title" class="bg-indigo-100 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder=""/>
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">DOI</label>
            <input type="text" v-model="data.doi" class="bg-indigo-100 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder=""/>
        </div>
        <div class="col-start-1 col-end-3 flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Journal</label>
            <input type="text" v-model="data.journal" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Journal ISSN</label>
            <input type="text" v-model="data.journal_issn" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Volume</label>
            <input v-model="data.volume" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />

        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Issue</label>
            <input v-model="data.issue" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Pub date*</label>
            <input type="date" v-model="data.pub_date" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
        </div>

        <div class="col-start-1 col-end-3 flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Authors</label>
            <input v-model="data.authors" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Page number</label>
            <input v-model="data.pagenum" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
        </div>
        <div class="col-start-1 col-end-4 row-start-5 row-end-7 flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Abstract</label>
            <textarea v-model="data.abstract" rows="5" class="bg-indigo-600 rounded-md w-full p-2 text-white text-lg placeholder-indigo-800" placeholder=""></textarea>
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">Keyword</label>
            <input v-model="data.keywords" class="bg-indigo-600 rounded-md w-full h-12 p-2 text-white text-lg placeholder-indigo-800" placeholder="" />
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">PDF url</label>
            <input v-model="data.pdf_url" class="bg-indigo-600 rounded-md w-full h-12 p-2 text-white text-lg placeholder-indigo-800" placeholder="" />
        </div>
        <div class="flex flex-col">
            <label class="text-gray-500 text-lg font-semibold">HTML url</label>
            <input v-model="data.html_url" class="bg-indigo-600 rounded-md w-full h-12 p-2 text-white text-lg placeholder-indigo-800" placeholder="" />
        </div>

    </div>

    <!-- footer -->
    <div class="flex flex-row gap-4 p-6 justify-end pr-0 items-center">
        <button @click="$emit('closeBtn')" class="w-1/5 px-4 py-2 bg-indigo-200 text-gray-600 text-xl font-semibold rounded-md">Close</button>
        <button :disable="gotAllInput" @click="$emit('submitBtn', data)" class="w-1/5 px-4 py-2 bg-indigo-800 text-white text-xl font-semibold rounded-md">Submit</button>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            data: {
                title: '',
                doi: '',
                journal: '',
                volume: '',
                issue: '',
                pub_date: function (date){
                    return `${date.year()}-${date.month()}-${date.day()}`
                },
                html_url: '',
                authors: [],
                journal_issn: '',
                pagenum: '',
                abstract: '',
                keywords: [],
                pdf_url: '',

            },
            gotAllInput: false,
        }
    },

    emits: ["closeBtn", "submitBtn"],

    setup() {

        return {

        }
    }

}
</script>

<style>

</style>
