<template>
    <div @click="$emit('btnClick')" class="flex flex-col items-center cursor-pointer
    hover:transition duration-100 transform-gpu hover:scale-110">
        <img class="w-10" :src="imageSource"/>
        <button class="btn outline-none px-4 py-1 text-gray-600 text-sm font-bold rounded-lg">{{buttonText}}</button>
    </div>
</template>

<script>

export default ({
    props: ["imageSource", "buttonText"],
    emits: ["btnClick"]
})
</script>
<style scoped>

</style>