<template>
<div>
    <Navbar />
    <div class="py-20 bg-gray-50 radius-for-skewed" style="height:100vh;position: relative;
              background-image: url(https://i.pinimg.com/originals/b9/c8/f8/b9c8f893c9a782033a01f47e0c0b1d6e.jpg); 
               background-size: 100% 100%;background-position:top;">
        <div class="container mx-auto px-4">
            <div class="max-w-4xl mx-auto text-center mb-16 mt-6">
                <h4 class="text-3xl lg:text-5xl font-bold mb-10 font-heading text-white">Upgrade to accelerate innovation</h4>
                <span class="text-white text-lg font-semibold"> Get 2 Months free with yearly subscription </span>
            </div>
            <div class="flex flex-wrap -mx-4">
                <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                    <div class="p-8 bg-white shadow rounded border-0 hover:border-2 cursor-pointer">
                        <h4 class="mb-2 text-2xl font-bold font-heading">Student</h4> <span class="text-6xl font-bold">€4.99</span> <span class="text-gray-400 text-xs" data-config-id="02_note">/month</span>
                        <p class="mt-3 mb-6 text-gray-500 leading-loose">Ideal to complete your master's thesis.</p>
                        <ul class="mb-6 text-gray-500">
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>3 bots</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>4 Collections</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Basic Citeman</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span data-config-id="01_benefit4">Browser Plugin</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Word/Libreoffice plugin</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>50 GB Cloud storage</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Dashboard</span> </li>
                        </ul> <a @click="subscribe('Student', 'year')" class="inline-block text-center py-2 px-4 w-full rounded-l-xl rounded-t-xl hover:bg-blue-700 text-white font-bold leading-loose transition duration-200" href="#" style="background-color : #5f40a8">Subscribe</a>
                                <button @click="subscribe('Student','monthly')" style="min-width: 15vh;border-radius: 20px;color:#5f40a8;text-transform: none;"> <u> Pay monthly</u></button>
                    </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                    <div class="p-8 shadow rounded bg-white">
                        <h4 class="mb-2 text-2xl font-bold " data-config-id="02_title">Researcher</h4> <span class="text-6xl font-bold " data-config-id="02_price">€8.99</span> <span class="text-gray-500 text-xs" data-config-id="02_note">/month</span>
                        <p class="mt-3 mb-6 leading-loose text-gray-500" data-config-id="02_desc">Batteries included for PhD, PostDoc and scholars.</p>
                        <ul class="mb-6 text-gray-500">
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>8 bots</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>9 Collections</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Advanced Citeman</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Browser Plugin</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Word/Libreoffice plugin</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>100 GB Cloud storage</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Dashboard</span> </li>
                        </ul> <a @click="subscribe('Researcher', 'year')" class="inline-block text-center text-white py-2 px-4 w-full rounded-l-xl rounded-t-xl  hover:bg-gray-50 font-bold leading-loose transition duration-200" href="#" data-config-id="02_primary-action" style="background-color : #5f40a8">Subscribe</a>
                        <button @click="subscribe('Researcher','monthly')" style="min-width: 15vh;border-radius: 20px;color:#5f40a8;text-transform: none;"> <u> Pay monthly</u></button>
                    </div>
                </div>
                <div class="w-full lg:w-1/3 px-4">
                    <div class="p-8 bg-white shadow rounded">
                        <h4 class="mb-2 text-2xl font-bold font-heading" data-config-id="03_title">Enterprice</h4> <span class="text-6xl font-bold" data-config-id="03_price">€24.99</span> <span class="text-gray-400 text-xs" data-config-id="03_note">/month</span>
                        <p class="mt-3 mb-6 text-gray-500 leading-loose" data-config-id="03_desc">For professors and industry professionals.</p>
                        <ul class="mb-6 text-gray-500">
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>12 bots</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>13 Collections</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Advanced Citeman</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Browser Plugin</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Word/Libreoffice plugin</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>1 TB Cloud storage</span> </li>
                            <li class="mb-2 flex"> <svg class="mr-2 w-5 h-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="color : #5f40a8">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg> <span>Dashboard **</span> </li>
                        </ul> <a @click="subscribe('Enterprice', 'year')" class="inline-block text-center py-2 px-4 w-full rounded-l-xl rounded-t-xl  hover:bg-blue-700 text-white font-bold leading-loose transition duration-200" href="#" data-config-id="03_primary-action" style="background-color : #5f40a8"> Subscribe</a>
                        <button @click="subscribe('Enterprice','monthly')" style="min-width: 15vh;border-radius: 20px;color:#5f40a8;text-transform: none;"> <u> Pay monthly</u></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button @click="checkout">Check12 out</button> -->
        <Footer />
    </div>

</div>
</template>

<script>
import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import {loadStripe} from '@stripe/stripe-js';



export default {
    name: 'Pricing',
    components: {
        Navbar,
        Footer,
    },
    data() {
        this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
        return {
            posts: [],

            lineItems: [{
                price: 'some-price-id', // The id of the one-time price you created in your Stripe dashboard
                quantity: 1,
            }, ],

            loading: false,
            publishableKey: 'YourKey',
            items: [{
                sku: 'sku_FdQKocNoVzznpJ',
                quantity: 1
            }],
            successUrl: 'your-success-url',
            cancelUrl: 'your-cancel-url',
            pub_key: '',
            stripe: ''
        }

    },
    async mounted() {
        await this.getPubKey()
        this.stripe = await loadStripe(this.pub_key)
    },
    methods: {
        async getPubKey() {
             var userInfo = this.$store.getters.StateUserInfo;
            await axios.get(`https://api.zealbots.com/api/v1/stripekey/?Key=${userInfo.key}`)
                .then(response => {
                    console.log("pubkey:: ",response.data.pubkey)
                    this.pub_key = response.data.pubkey
                    this.stripe =  response.data.pubkey
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async subscribe(packages, plan) {
            let stripe = this.stripe
            const data = {
                package : packages,
                plan: plan
            }
            console.log(data)
            console.log("that.stripe::",stripe)
            var userInfo = this.$store.getters.StateUserInfo;
            await axios.post(`https://api.zealbots.com/api/v1/stripe/create_checkout_session/?Key=${userInfo.key}`, data)
            .then(async response => {
                console.log("sessionId::",response.data)
                this.stripeSession = response.data.sessionId;
                return  this.stripe.redirectToCheckout({
                    sessionId: this.stripeSession
                    })
            })
            .catch(error => {
                console.log('Error:', error)
            })
        },
    },
        created: async function () {
        await this.getPubKey();
    },

}
</script>

<style scoped>

</style>
