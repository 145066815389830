<template>
<div>
    <Navbar selected='1' :bot_id='botid' />
    <div class=" bg-gray-50 " style="position: relative; background-size: 100% 100%;background-position:top;">
        <div class=" mt-10 p-10">
            <div class="flex justify-center row justify-content-center">

                <div class="card col-sm-12 col-md-8 col-lg-7">
                    <h3 class="md:text-2xl lg:text-2xl font-bold sm:text-sm text-gray-500 py-1 text-center explore_h3 card-header">
                        <strong>Reconfigure Bot {{bot_list.bot_name}}</strong>
                    </h3>

                    <div class="grid grid-cols-3 gap-2 py-4">
                        <div class="flex flex-row gap-2 col-start-1 col-end-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="text-gray-500 text-lg font-semibold">Bot name</label>
                                <input v-model="bot_list.bot_name" type="text" class="bg-indigo-100 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                            </div>
                            <div class="w-1/2 flex flex-col">
                                <label class="text-gray-500 text-lg font-semibold">Project name*</label>
                                <input type="text" v-model="bot_list.project_name" class="bg-indigo-100 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                            </div>

                        </div>

                        <div class="col-start-1 col-end-3 flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Keywords</label>
                            <input type="text" v-model="bot_list.pos_keywords" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <div class="flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Keywords (Do not include)</label>
                            <input type="text" v-model="bot_list.neg_keywords" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>

                        <div class="col-start-1 col-end-3 flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold"> Journals</label>
                            <input type="text" v-model="bot_list.pos_journals" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <div class="flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Journals (Do not include)</label>
                            <input type="text" v-model="bot_list.neg_journals" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>

                        <div class="col-start-1 col-end-3 flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold"> Authors</label>
                            <input type="text" v-model="bot_list.pos_authors" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <div class="flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Authors (Do not include)</label>
                            <input type="text" v-model="bot_list.neg_authors" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <div class="col-start-1 col-end-3 flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Institute</label>
                            <input type="text" v-model="bot_list.pos_institute" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>

                        <div class="flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Institute (Do not include)</label>
                            <input type="text" v-model="bot_list.neg_institute" class="bg-indigo-200 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <div class="flex flex-row gap-2 col-start-1 col-end-4">
                            <div class="w-1/2 flex flex-col">
                                <label class="text-gray-500 text-lg font-semibold">Journal Impact Factor (from)</label>
                                <input v-model="bot_list.impact_from" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                            </div>
                            <div class="w-1/2 flex flex-col">
                                <label class="text-gray-500 text-lg font-semibold">Journal Impact Factor (To)</label>
                                <input v-model="bot_list.impact_until" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                            </div>
                        </div>
                        <div class="col-start-1 col-end-2 flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold"> Number of articles to send </label>
                            <input v-model="bot_list.top_n" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <div class="flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Re-run every* days</label>
                            <input v-model="bot_list.run_interval" class="bg-indigo-300 rounded-md w-full h-12 p-2 text-indigo-800 text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <div class="flex flex-col">
                            <label class="text-gray-500 text-lg font-semibold">Send updates every * days</label>
                            <input v-model="bot_list.keywords" class="bg-indigo-600 rounded-md w-full h-12 p-2 text-white text-lg placeholder-indigo-800" placeholder="" />
                        </div>
                        <!-- footer -->

                    </div>
                    <div class="flex  text-gray-500 text-lg font-semibold justify-end gap-4">
                        <button @click="cancel()" class=" h-12 p-2 px-4  bg-indigo-200 text-gray-600 text-xl font-semibold rounded-md">Close</button>
                        <button @click="botedit()" class="h-12 p-2 px-4 bg-indigo-800 text-white text-xl font-semibold rounded-md">Submit</button>
                    </div>

                </div>
            </div>

        </div>
        <Success v-if="notification==true" />
        <Footer />
    </div>

</div>
</template>

<script>
import axios from "axios";
import searchSortAnimate from '@/mixins/searchSortAnimate'
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'
import Success from '@/components/success.vue';

export default {
    name: "Bot",
    components: {
        Footer,
        Navbar,
        Success
    },
    mixins: [searchSortAnimate],
    data() {
        return {
            bot_list: {},
            notification: false,
        };
    },

    methods: {
        botListFunction: async function (bucket_id) {
            var userInfo = this.$store.getters.StateUserInfo;
            console.log("userInfo", userInfo, bucket_id)

            axios
                .get(`/zbot/?id=${this.botid}&Key=${this.$store.getters.StateUserInfo.key}&format=json`)
                .then(response => {
                    if (response && response.data) {
                        this.bot_list = response.data.data[0]
                        console.log("response", response.data.data[0])
                    } else {
                        console.log("Some error")
                    }
                })
                .catch(error => {
                    console.log(error);
                })

        },
        cancel: function(){
            history.back()
        },
        botedit: async function () {
            const pass = 'pass';
            let data = this.bot_list

            // pos_keywords
            if (data.pos_keywords != null && this.bot_list.pos_keywords.length >= 1 && typeof this.bot_list.pos_keywords === "object") {
                pass
            } else if (this.bot_list.pos_keywords.length >= 1 && typeof this.bot_list.pos_keywords === "string") {
                this.bot_list.pos_keywords = this.bot_list.pos_keywords.split(",")
            } else {
                this.bot_list.pos_keywords = null
            }
            // neg_keywords
            if (data.neg_keywords != null && this.bot_list.neg_keywords.length >= 1 && typeof this.bot_list.neg_keywords === "object") {
                pass
            } else if (data.neg_keywords != null && this.bot_list.neg_keywords.length >= 1 && typeof this.bot_list.neg_keywords === "string") {
                this.bot_list.neg_keywords = this.bot_list.neg_keywords.split(",")
            } else {
                this.bot_list.neg_keywords = null
            }
            // pos_journals
            if (data.pos_journals != null && this.bot_list.pos_journals.length >= 1 && typeof this.bot_list.pos_journals === "object") {
                pass
            } else if (data.pos_journals != null && this.bot_list.pos_journals.length >= 1 && typeof this.bot_list.pos_journals === "string") {
                this.bot_list.pos_journals = this.bot_list.pos_journals.split(",")
            } else {
                this.bot_list.pos_journals = null
            }
            // neg_journals
            if (data.neg_journals != null && this.bot_list.neg_journals.length >= 1 && typeof this.bot_list.neg_journals === "object") {
                pass
            } else if (data.neg_journals != null && this.bot_list.neg_journals.length >= 1 && typeof this.bot_list.neg_journals === "string") {
                this.bot_list.neg_journals = this.bot_list.neg_journals.split(",")
            } else {
                this.bot_list.neg_journals = null
            }
            // pos_authors
            if (data.pos_authors != null && this.bot_list.pos_authors.length >= 1 && typeof this.bot_list.pos_authors === "object") {
                pass
            } else if (data.pos_authors != null && this.bot_list.pos_authors.length >= 1 && typeof this.bot_list.pos_authors === "string") {
                this.bot_list.pos_authors = this.bot_list.pos_authors.split(",")
            } else {
                this.bot_list.pos_authors = null
            }
            // neg_authors
            if (data.neg_authors != null && this.bot_list.neg_authors.length >= 1 && typeof this.bot_list.neg_authors === "object") {
                pass
            } else if (data.neg_authors != null && this.bot_list.neg_authors.length >= 1 && typeof this.bot_list.neg_authors === "string") {
                this.bot_list.neg_authors = this.bot_list.neg_authors.split(",")
            } else {
                this.bot_list.neg_authors = null
            }
            // pos_institute
            if (data.pos_institute != null && this.bot_list.pos_institute.length >= 1 && typeof this.bot_list.pos_institute === "object") {
                pass
            } else if (data.pos_institute != null && this.bot_list.pos_institute.length >= 1 && typeof this.bot_list.pos_institute === "string") {
                this.bot_list.pos_institute = this.bot_list.pos_institute.split(",")
            } else {
                this.bot_list.pos_institute = null
            }
            // neg_institute
            if (data.neg_institute != null && this.bot_list.neg_institute.length >= 1 && typeof this.bot_list.neg_institute === "object") {
                pass
            } else if (data.neg_institute != null && this.bot_list.neg_institute.length >= 1 && typeof this.bot_list.neg_institute === "string") {
                this.bot_list.neg_institute = this.bot_list.neg_institute.split(",")
            } else {
                this.bot_list.neg_institute = null
            }

            let response = axios.put(`/zbot/edit/${this.botid}/?Key=${this.$store.getters.StateUserInfo.key}&format=json`, data)
            try {
                console.log("wait")
                if (response && response.data) {
                    this.bot_list = response.data
                    this.botListFunction(this.botid);

                } else {
                    this.notification = true
                    setTimeout(() => {
                        this.botListFunction(this.botid);
                        this.notification = false
                        history.back()
                    }, 5000)
                }
            } catch (error) {
                this.botListFunction(this.botid);
                this.notification = true
                setTimeout(() => {
                    this.notification = true

                }, 5000)
            }
        },

    },
    created: async function () {
        this.botid = this.$route.params.id
        this.botListFunction(this.botid);

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.form-control:focus {
    box-shadow: none;
}

.bot-font {
    color: grey;
}
</style>
