import axios from 'axios';

const serverPort = 'http://localhost:3131';

const state = {
    files: [],
}

const getters = {
    files: (state) => state.files,
    awsApi: (state) => state.awsApi,
}

const actions = {
    async getAwsApi({ commit }, userInfo) {
        const aws = await axios.get(`https://api.zealbots.com/api/v1/awsview/?Key=${userInfo.key}&format=json`)
        if (aws && aws.data.data) {
            let data = aws.data.data
            commit('setAwsApi', data)
        } else {
            commit('setAwsApi', '500 Error')
        }
    },

    async getS3Files({ commit }, data){
        console.log("goind to get the files")
        console.log(data)
        let headersList = {
            "userFolder": data
        }
        
        let reqOptions = {
            url: `${serverPort}/s3/`,
            method: "GET",
            headers: headersList,
        }
        
        axios.request(reqOptions).then(function (response) {
            console.log(response.data);
            commit('sets3Files', response.data)
        })
    },
}

const mutations = {
    setAwsApi: (state, data) => state.awsApi = data,
    sets3Files: (state, data) => {
        function fetchFiles(data){
            return data.map(file => {
                return {
                    name: file.fileName,
                    size: file.fileSize,
                    mtime: file.fileDate,
                    filePath: file.fileName,
                    directory: file.fileSize
                }
            })
        }
        state.files = fetchFiles(data)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
