<template>
  <Navbar />


  <div class="flex flex-col h-screen ">
    
    <div class="">
      <div class="flex flex-row justify-center items-center ">
      <!-- <font-awesome-icon  class="text-green text-4xl cursor-pointer mr-8" :icon="['fas', 'user-tie']"/> -->
        <div class="sm:text-4xl text-3xl font-bold text-green-900  "> {{}}
        </div>
  </div>


      <!-- <div class="mb-10"></div> -->

  
    <div class="flex flex-row ">
      <div class="mt-3 flex flex-col " style="flex:3">
        <div class="w-full flex flex-row">
            <div  id="articles_details" class="m-2 flex flex-col w-full ml-16 ">
                <p class="font-bold text-2xl text-green-500">Publications</p>

                
              <div  v-for="pub in author_publications" :key="pub" class="m-3 flex flex-col py-4 border rounded-md shadow-lg px-4 cursor-pointer mt-5 ">
                <div class=" h-auto flex flex-row">
                  <div class="w-full lg:w-11/12 flex flex-row item-center justify-start">
                    <h1 @click="detail_view(pub)" class="text-justify text-md md:text-lg lg:text-xl text-blue-800 py-1">{{pub.title}}</h1>
                  </div>
                  <div class="hidden lg:w-1/12 lg:flex lg:flex-row lg:justify-evenly lg:self-top">
                    
                    
                      <div v-if="pub.pdf_url" @click="pdf_url(pub)"  class="w-7 h-7 bg-green-100 flex flex-row item-center justify-center  rounded-full shadow-lg border border-green-400 hover:bg-green-300">
                        <font-awesome-icon class="text-sm text-green-600 self-center" :icon="['fas', 'download']"/>
                      </div>
                    
                    <div class="w-7 h-7 bg-green-100 flex flex-row item-center justify-center  rounded-full shadow-lg border border-green-400 hover:bg-green-300">
                      <font-awesome-icon class="text-sm text-green-600 self-center" :icon="['fas', 'plus-circle']"/>
                    </div>
                    
                    <div v-if="pub.html_url" @click="html_url(pub)" class="w-7 h-7 bg-green-100 flex flex-row item-center justify-center  rounded-full shadow-lg border border-green-400 hover:bg-green-300">
                      <font-awesome-icon class="text-sm text-green-600 self-center" :icon="['fas', 'link']"/>
                    </div>
                    
                  </div>
                </div>
                <div>
                  <div class="text-letf">
                    <h1 class="text-left text-sm md:text-md lg:text-md text-green-700 py-1">
                      <font-awesome-icon class="text-xs ml-1 mr-1" :icon="['fas', 'quote-right']"/>
                    </h1> 
                  </div>
                  <div class="py-1">
                    <div class="text-left h-6 md:h-auto overflow-hidden">  
                      <span class="text-xs md:text-md lg:text-md">{{pub.journal}}</span>
                    </div>
                  </div>

                                  <div>
                <div class="hover:bg-green-100 w-max bg-gray-100 text-sm items-center px-2 py-0.8 text-right" >
                  <div @click="abstract_show(pub.id)" style="color: #00b2b2" class="flex flex-row text-letf">
                    Abstract
                  </div>
                </div>
                
                  <div v-if="isHidden && abstract_id == pub.id" >
                    <p>{{ pub.abstract }}</p>
                  </div>
                
              </div>

                </div>
              </div>
              
              
            </div>
            </div>
            </div>

            <!-- <div class="flex flex-col w-1/12"></div> -->

            

        
      </div>
    <Footer />
    </div>

  </div>
  
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import axios from "axios";

export default {
  name: 'AuthorPage',
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      author_name: "",
      author_affilications: [],
      author_publications: [],
      author_pub_list : [],
      author_journal : [],
      isHidden: false,
      abstract_id: 0,
      bots:[],
      
      
      
      }
  },
  async mounted(){

    await axios.get(`https://api.zealbots.com/api/v1/zbot_article/?id=${this.$route.params.bot_id}&Key=${process.env.VUE_APP_COMMON_API_KEY}&format=json`) 
      .then((result) => {

          this.bots = result.data.data[0].recommendation
          console.log("this.bots",this.bots)
        
      })    
    

  for (var pub in  this.bots) {
      console.log(this.bots[pub])

    await axios.get(`https://api.zealbots.com/api/v1/publication/?id=${this.bots[pub]}&Key=${process.env.VUE_APP_COMMON_API_KEY}&format=json`)
      .then((result) => {
        if (result.data.data[0] != null) {
          this.author_publications.push(result.data.data[0])
        }
        
      })
    }      

  
  },
  
  methods: {
    
    detail_view: function(pub) {
      this.$router.push('/'+pub.title+'/'+pub.id);
    },
    pdf_url: function(pub) {
        window.open(pub.pdf_url);    
    },
    html_url: function(pub) {
        window.open(pub.html_url);    
    },
    affiliations_view: function(affiliation) {
      this.$router.push('/institue'+'/'+affiliation.name+'/'+affiliation.id);
    },
    abstract_show(pub_id) {

          if (this.abstract_id == pub_id) {
            this.isHidden = !this.isHidden;
          } else {
            this.abstract_id = pub_id;
            this.isHidden = true;
          }
        },
    
  },

computed: {
  },
}   

</script>
