<template>
  <Navbar />
  <div class="h-auto min-h-screen flex flex-col mt-20">
    <div class="w-full lg:w-6/12 flex flex-row self-center justify-center text-3xl font-bold text-blue-500 px-4">
          Terms and Conditions
    </div>

    <div class="w-full lg:w-6/12 flex flex-row self-center justify-start px-4 py-2 text-justify text-gray-700">
      &emsp; This privacy policy has been compiled to better serve those who are concerned with how their 
      'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy 
      law and information security, is information that can be used on its own or with other information 
      to identify, contact, or locate a single person, or to identify an individual in context. Please 
      read our privacy policy carefully to get a clear understanding of how we collect, use, protect or 
      otherwise handle your Personally Identifiable Information in accordance with our website.
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        What personal information do we collect from the people that visit our blog, website or app?
      </p>
      <p class="text-gray-700">
        &emsp; When ordering or registering on our site, as appropriate, you may be asked to enter your name, 
        email address, credit card information or other details to help you with your experience.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        When do we collect information?
      </p>
      <p class="text-gray-700">
        &emsp;We collect information from you when you register on our site, place an order, subscribe
         to a newsletter or enter information on our site.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        How do we use your information?
      </p>
      <p class="text-gray-700">
        &emsp;We may use the information we collect from you when you register, make a purchase, sign up 
        for our newsletter, respond to a survey or marketing communication, surf the website, or 
        use certain other site features in the following ways:
      </p>
      <ul class="ml-4 flex flex-col list-disc list-outside mt-2 text-gray-700">
        <li class="ml-4">
          To personalize your experience and to allow us to deliver the type of content and product 
          offerings in which you are most interested.
        </li>
        <li class="ml-4">
          To allow us to better service you in responding to your customer service requests.
        </li>
        <li class="ml-4">
          To quickly process your transactions.
        </li>
        <li class="ml-4">
          To send periodic emails regarding your order or other products and services.
        </li>
        <li class="ml-4">
          To follow up with them after correspondence (live chat, email or phone inquiries)
        </li>
      </ul>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        How do we protect your information?
      </p>
      <p class="text-gray-700">
        &emsp;Our website is scanned on a regular basis for security holes and known vulnerabilities 
        in order to make your visit to our site as safe as possible.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        We use regular Malware Scanning.
      </p>
      <p class="text-gray-700">
        &emsp;Your personal information is contained behind secured networks and is only accessible by a limited
        number of persons who have special access rights to such systems, and are required to keep the 
        information confidential. In addition, all sensitive/credit information you supply is encrypted 
        via Secure Socket Layer (SSL) technology.
      </p>  
      <p class="text-gray-700 py-1">  
        &emsp; We implement a variety of security measures when a user places an order enters, submits, or accesses 
        their information to maintain the safety of your personal information.
      </p>  
      <p class="text-gray-700 py-1">
        All transactions are processed through a gateway provider and are not stored or processed on our servers.
      </p>  
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Do we use 'cookies'?
      </p>
      <p class="text-gray-700">
        &emsp; Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive
         through your Web browser (if you allow) that enables the site's or service provider's systems to recognize
          your browser and capture and remember certain information. For instance, we use cookies to help us 
          remember and process the items in your shopping cart. <br> &emsp; They are also used to help us understand your preferences
           based on previous or current site activity, which enables us to provide you with improved services. 
           We also use cookies to help us compile aggregate data about site traffic and site interaction so that we 
           can offer better site experiences and tools in the future.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        We use cookies to:
      </p>
      
      <ul class="ml-4 flex flex-col list-disc list-outside mb-2 mt-2 text-gray-700">
        <li class="ml-4">
          Help remember and process the items in the collection.
        </li>
        <li class="ml-4">
          Understand and save user's preferences for future visits.
        </li>
        <li class="ml-4">
          Compile aggregate data about site traffic and site interactions in order to offer better site experiences and 
          tools in the future. We may also use trusted third-party services that track this information on our behalf.
        </li>
        <li class="ml-4">
          To send periodic emails regarding your order or other products and services.
        </li>
        <li class="ml-4">
          To follow up with them after correspondence (live chat, email or phone inquiries)
        </li>
      </ul>
      <p class="text-gray-700">
        &emsp; You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies.
         You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn
          the correct way to modify your cookies.
      </p>
      <p class="text-gray-700">
        &emsp; If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.
        It won't affect the user's experience that make your site experience more efficient and may not function properly.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Third-party disclosure!
      </p>
      <p class="text-gray-700">
        &emsp; We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we 
        provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating 
        our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. 
        We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours 
        or others' rights, property or safety. <br>
        &emsp;However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Third-party links
      </p>
      <p class="text-gray-700">
        &emsp; Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites 
        have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of 
        these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Google
      </p>
      <p class="text-gray-700">
        &emsp;Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive 
        experience for users. <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en" class="text-blue-500">
        https://support.google.com/adwordspolicy/answer/1316548?hl=en</a><br>
        We use Google AdSense Advertising on our website. <br>
      </p>  
      <p class="text-gray-700 py-1">  
        &emsp; Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to 
        our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by 
        visiting the Google Ad and Content Network privacy policy.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        We have implemented the following:
      </p>
      
      <ul class="ml-4 flex flex-col list-disc list-outside mb-2 mt-2 text-gray-700">
        <li class="ml-4">
          Remarketing with Google AdSense
        </li>
        <li class="ml-4">
          Demographics and Interests Reporting
        </li>
      </ul>

      <p class="text-gray-700">
        &emsp;We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies 
        (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions 
        and other ad service functions as they relate to our website.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Opting out:
      </p>
      <p class="text-gray-700">
        &emsp;Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the 
        Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        California Online Privacy Protection Act
      </p>
      <p class="text-gray-700">
        &emsp;CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches 
        well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally 
        Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected 
        and those individuals or companies with whom it is being shared. <br> 
        See more at: <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf" class="text-blue-500">
        http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</a>
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        According to CalOPPA, we agree to the following:
      </p>
      <p class="text-gray-700">
        Users can visit our site anonymously.
      </p>
      <p class="text-gray-700">
        Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website. 
        Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.
      </p>
      <p class="text-gray-700">
        You will be notified of any Privacy Policy changes:
      </p>
      <ul class="ml-4 flex flex-col list-disc list-outside mb-2 mt-2 text-gray-700">
        <li class="ml-4">
          On our Privacy Policy Page
        </li>
        <li class="ml-4">
          By logging in to your account
        </li>
        <li class="ml-4">
          How does our site handle Do Not Track signals?
        </li>
      </ul>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        How does our site handle Do Not Track signals?
      </p>
      <p class="text-gray-700">
        &emsp;We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Does our site allow third-party behavioral tracking?
      </p>
      <p class="text-gray-700">
        It's also important to note that we allow third-party behavioral tracking
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        COPPA (Children Online Privacy Protection Act)
      </p>
      <p class="text-gray-700">
        &emsp;When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) 
        puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators 
        of websites and online services must do to protect children's privacy and safety online.
      </p>  
      <p class="text-gray-700 py-1">
        We do not specifically market to children under the age of 13 years old.
      </p>  
      <p class="text-gray-700 py-1">
        Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        Fair Information Practices
      </p>
      <p class="text-gray-700">
        &emsp; The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role 
        in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is 
        critical to comply with the various privacy laws that protect personal information.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
      </p>
      <ul class="ml-4 flex flex-col list-disc list-outside mb-2 mt-2 text-gray-700">
        <li class="ml-4">
          We will notify you via email Within 1 business day
        </li>
        <li class="ml-4">
          We will notify the users via in-site notification Within 1 business day
        </li>
      </ul>
      <p class="text-gray-700">
        &emsp;We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors 
        and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals 
        have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        CAN SPAM Act
      </p>
      <p class="text-gray-700">
        &emsp;The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped 
        from being sent to them, and spells out tough penalties for violations.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        We collect your email address in order to:
      </p>
      <ul class="ml-4 flex flex-col list-disc list-outside mb-2 mt-2 text-gray-700">
        <li class="ml-4">
          Send information, respond to inquiries, and/or other requests or questions
        </li>
        <li class="ml-4">
          Process orders and to send information and updates pertaining to orders.
        </li>
        <li class="ml-4">
          Send you additional information related to your product and/or service
        </li>
        <li class="ml-4">
          Market to our mailing list or continue to send emails to our clients after the original transaction has occurred.
        </li>
      </ul>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        To be in accordance with CANSPAM, we agree to the following:
      </p>
      <ul class="ml-4 flex flex-col list-disc list-outside mb-2 mt-2 text-gray-700">
        <li class="ml-4">
          Not use false or misleading subjects or email addresses.
        </li>
        <li class="ml-4">
          Identify the message as an advertisement in some reasonable way.
        </li>
        <li class="ml-4">
          Include the physical address of our business or site headquarters.
        </li>
        <li class="ml-4">
          Monitor third-party email marketing services for compliance, if one is used.
        </li>
        <li class="ml-4">
          Honor opt-out/unsubscribe requests quickly.
        </li>
        <li class="ml-4">
          Allow users to unsubscribe by using the link at the bottom of each email.
        </li>
      </ul>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-lg py-1 text-gray-800">
        If at any time you would like to unsubscribe from receiving future emails, you can email us at
      </p>
      <p class="text-gray-700">
        Follow the instructions at the bottom of each email. and we will promptly remove you from all correspondence.
      </p>
    </div>

    <div class="w-full lg:w-6/12 flex flex-col self-center justify-start px-4 py-2 text-justify">
      <p class="font-semibold text-xl py-1 text-gray-800">
        Contacting Us
      </p>
      <p class="text-gray-700">
        If there are any questions regarding this privacy policy, you may contact us using the information below.
      </p>
      <p class="text-gray-700">
        Pyykkiojankatu 2C 27, Tampere
      </p>
      <p class="text-gray-700">
        Finland, 33710
      </p>
      <p class="text-blue-600">
        <u>hello@zealbots.com</u>
      </p>
    </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Navbar,
    Footer,
  }
} 
</script>

<style scoped>

</style>