import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home.vue'

import Pub_detail from '@/components/Pub_detail.vue'
import Search from '@/components/Search.vue'
import Download from '@/components/Download.vue'
import Scholarly from '@/components/Scholarly.vue'
import AuthorPage from '@/components/AuthorPage.vue'
import InstitutePage from '@/components/InstitutePage.vue'
import Settings from '@/components/Settings.vue'

import Pricing from '@/components/Pricing.vue'
import Login from '@/components/login.vue'
import signup from '@/components/signup.vue'

import About from '@/components/static/About_us.vue'
import Contact from '@/components/static/Contact_us.vue'
import Help from '@/components/static/Help.vue'
import HowTo from '@/components/static/How_to_use.vue'
import Privacy from '@/components/static/Privacy.vue'
import Tech from '@/components/static/Tech_company.vue'
import Terms from '@/components/static/Terms.vue'

import University from '@/components/static/University.vue'
import Zealbots from '@/components/static/Zealbots.vue'
import WhyZkloud from '@/components/static/WhyZkloud.vue'
import BotCreation from '@/components/BotCreation.vue'
import Bot from '@/components/Bot.vue'
import Botpage from '@/components/Botpage.vue'
import Collectionpage from '@/components/Collectionpage.vue'
import Citeman from '@/components/Citeman.vue'

//From Desktop
import store from "../store";
import Dashboard from '@/components/Dashboard.vue'
import LeftNavigation from '@/components/LeftNavigation.vue'
import Plugin from '@/components/Plugin.vue'
import Zkloud from '@/views/Zkloud.vue'
import TodoTasks from '@/components/TodoTasks.vue'
import Collection from '@/components/Collection.vue'
import PageNotFound from '@/components/PageNotFound.vue'
import PopupArticle from '@/components/PopupArticle.vue'

import Bot_edit from '@/components/Bot_edit.vue'
import PlansThankyou from '@/components/PlansThankyou.vue'


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true },
  },
  {
    path :'/popuparticle',
    name : 'PopupArticle',
    component :PopupArticle
  },

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    name: 'signup',
    component: signup
  },

  {
    path: '/newbot',
    name: 'BotCreation',
    component: BotCreation,
    meta: { requiresAuth: true },
  },
  {
    path: '/citeman/:id',
    name: 'Citeman',
    component: Citeman,
    meta: { requiresAuth: true },
  },
  {
    path: '/about_us',
    name: 'About',
    component: About
  },
  {
    path: '/contact_us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/how_to_use',
    name: 'HowTo',
    component: HowTo
  },
  {
    path: '/privacy_policy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/tech_company',
    name: 'Tech',
    component: Tech
  },
  {
    path: '/terms_and_conditions',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/university',
    name: 'University',
    component: University
  },
  {
    path: '/why_zealbots',
    name: 'Zealbots',
    component: Zealbots
  },
  {
    path: '/why_zkloud',
    name: 'WhyZkloud',
    component: WhyZkloud
  },
  {
    path: "/download",
    name: "Download",
    component: Download
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing
  },
  {
    path: "/scholarly",
    name: "Scholarly",
    component: Scholarly
  },
  {
    path: '/search/:q',
    name: 'Search',
    component: Search
  },
  {
    path: '/:tilte/:id',
    name: 'Pub_detail',
    component: Pub_detail
  },
  {
    path: '/author/:authr_name/:author_id',
    name: 'AuthorPage',
    component: AuthorPage
  },
  {
    path: '/institue/:inst_name/:inst_id',
    name: 'InstitutePage',
    component: InstitutePage
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/botpage/:bot_id',
    name: 'Botpage',
    component: Botpage
  },
  {
    path: '/collectionpage/:collection_id',
    name: 'Collectionpage',
    component: Collectionpage
  },
  // From Desktop
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/leftnav',
    name: 'LeftNavigation',
    component: LeftNavigation,

  },
  {
    path: '/bot/:id',
    name: 'Bot',
    component: Bot,
    props: (route) => ({ extraValue: true, botId: route.params.id }),
    meta: { requiresAuth: true },
  },

  {
    path: '/plugin',
    name: 'Plugin',
    component: Plugin
  },
  {
    path: '/zkloud',
    name: 'Zkloud',
    component: Zkloud
  },
  {
    path: '/tasks/:id',
    name: 'TodoTasks',
    component: TodoTasks,
    props: (route) => ({ botId: route.params.id }),
    meta: { requiresAuth: true },
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: Collection,
    meta: { requiresAuth: true },

  },
  {
    path: '/:pagenotfound(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/bot_edit/:id',
    name: 'bot_edit',
    component: Bot_edit,
    props: (route) => ({ extraValue: true, botId: route.params.id }),
    meta: { requiresAuth: true },
  },
  {
    path: '/thankyou',
    name: 'PlansThankyou',
    component: PlansThankyou,
    meta: {
      requireLogin: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes

})

router.beforeEach((to, from, next) => {
  // console.log("router inner to: ", to, from)
  // console.log("router inner from: ", from)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next("/login");
    }

  } else if (to.matched.some((record) => record.meta.guest)) {
    // already login directly pass to dashboard
    if (store.getters.isAuthenticated) {
      next("/dashboard");
      next("/newbot");
    } else {
      next();
    }

  } else {
    next();
  }
});

export default router;