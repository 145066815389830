<template>
<div class="flex flex-row h-full row w-screen bg-gray-200">
    <LeftNavigation selected='2' :collection_id='$route.params.id' />

    <main class="flex-1 bg-gray-200 overflow-y-auto transition duration-500 ease-in-out">
        <div class="text-gray-700 h-screen dark:text-gray-500 transition duration-500 ease-in-out">
            <div class="flex-1 flex-col h-full row">
                

                <!-- Main Dev for two col -->
                <div v-if="publication_list.length" class="flex h-full text-gray-700 dark:text-gray-500 transition duration-500 ease-in-out">
                    <!-- left column start -->
                    <div class="w-full md:w-5/12 h-screen z-0 overflow-y-scroll scrollbar-thin scrollbar-track-indigo-200 scrollbar-thumb-indigo-600
                    bg-gradient-to-tr from-blue-200 via-indigo-200 to-pink-200">
                        <div class="flex flex-col justify-center items-center h-2/12 bg-teal-600 p-2">
                            <div class="w-full flex flex-row z-10 ">
                                <div class="w-full flex justify-center items-center">
                                    <h2 class="text-center text-white">{{collectionNameSplit(collectionTitle)}} ( {{currentArticleCount}} / {{totalCount}} )</h2>
                                </div>
                            </div>
                            <div class="w-full flex flex-row z-10 ">
                                <input @keyup="search" v-model="searchbox" class="rounded-md h-8 w-full py-3 px-6 text-gray-800 leading-tight focus:outline-none" type="text" placeholder="Search" />
                            </div>
                        </div>
                        <div class="w-full h-10/12 overflow-y-scroll scrollbar-thin scrollbar-teal-teal-200 scrollbar-thumb-teal-300
                                bg-gradient-to-tr from-blue-200 via-indigo-200 to-pink-200">

                            <div v-if="display_muliple_option" class="flex justify-center">
                                <!-- check box check and then show container start -->
                                <div class="relative inline-block text-left">
                                    <!-- add to collection btn -->
                                    <button @click="movetoCollectionbtn()" class="w-auto px-2 ring bg-white ring-blue-500 ring-offset-2 rounded-sm text-blue-600 py-1 font-bold m-3 transform hover:scale-105 motion-reduce:transform-none">
                                        Move to collection
                                    </button>

                                    <div v-bind:class="[multipledropdown ? 'block':'hidden']" class="origin-top-right z-10 absolute right-15 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <div class="py-1" role="none">
                                            <a v-for="collection in collection_list" :key="collection" @click="toggleMultipleMovetoCollectionModal(collection)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                                <!-- article move time show container -->
                                                <div class=" font-medium flex flex-row">
                                                    <span v-text="collectionNameSplit(collection.name)" class="cursor-pointer"></span>
                                                    <div>

                                                    </div>
                                                </div>
                                                <!-- end article move time show container -->

                                            </a>
                                            <!-- start multiple collection modal -->
                                            <div v-if="showmultipleCollectionModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                                                <div class="relative w-auto my-6 mx-auto max-w-sm">
                                                    <!--content-->
                                                    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                        <!--header-->
                                                        <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                                            <h3 class="text-3xl font-semibold">Add to Collection</h3>
                                                        </div>
                                                        <!--body-->
                                                        <div class="relative p-6 flex-auto">
                                                            <p class="my-4 text-gray-600 text-lg leading-relaxed">
                                                                <b>Are you sure want to move into another collection?</b>

                                                                <br />
                                                            </p>
                                                        </div>
                                                        <!--footer-->
                                                        <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                                            <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1  model-close" type="button" style="transition: all .15s ease" v-on:click="closeMultipleMovetoCollectionModal()">Not now</button>
                                                            <button class="text-blue-500 bg-transparent border border-solid border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="MultipleArticlesMovetoCollection()">Yes</button>
                                                        </div>
                                                        <!-- </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end multiple collection modal -->

                                        </div>
                                    </div>
                                </div>
                                <button @click="toggleMultipleDeleteModal()" class="w-auto px-2 ring bg-white ring-blue-500 ring-offset-2 rounded-sm text-blue-600 py-1 font-bold m-3 transform hover:scale-105 motion-reduce:transform-none">
                                    Articles Delete
                                </button>
                                <!-- start multiple delete modal -->
                                <div v-if="showmultipleDeleteModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                                    <div class="relative w-auto my-6 mx-auto max-w-sm">
                                        <!--content-->
                                        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            <!--header-->
                                            <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                                <h3 class="text-3xl font-semibold">Articles Delete</h3>
                                            </div>
                                            <!--body-->
                                            <div class="relative p-6 flex-auto">
                                                <p class="my-4 text-gray-600 text-lg leading-relaxed">
                                                    <b> Are you sure want to delete the collection articles?</b>
                                                    <br />
                                                </p>
                                            </div>
                                            <!--footer-->
                                            <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                                <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1  model-close" type="button" style="transition: all .15s ease" v-on:click="toggleMultipleDeleteModal()">Not now</button>
                                                <button class="text-blue-500 bg-transparent border border-solid border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="multipleAticleDelete()">Yes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end multiple delete modal -->
                            </div>
                            <div v-if="publication_list.length" class="px-2">
                                <transition-group :css="false" @before-enter="botBeforeEnter" @enter="botEnter" @leave="botLeave">
                                <div v-for="publication in publication_list" :key="publication"  class="card_article_list w-full h-20 flex flex-row my-2 rounded-md shadow-sm bg-white border hover:shadow-lg hover:bg-gray-200">
                                    <div class="w-1/12 relative h-12 rounded-l-md flex flex-row justify-center items-center">
                                        <!-- <img class="absolute h-24 w-24 rounded-l-md" v-if="publication.journal_dictionary" v-bind:src="publication.journal_dictionary.journal_logo" alt="">-->
                                        <!-- <img class=" h-24 w-24 rounded-l-md p-1" src="https://gatewaychristiancenter.net/wp-content/uploads/2020/07/arti.png" alt /> -->
                                        <div v-if="publication.pdf_url" class="hidden md:flex w-2/6 h-12 text-red-500 rounded-l-md p-8"><span class="inline-block align-baseline ...">
                                            <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'file-pdf']" />
                                            </span></div>
                                        <div v-else class="hidden md:flex w-2/6 h-12 text-indigo-500 rounded-l-md p-8"><span class="inline-block align-baseline ...">
                                            <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'book']" />
                                            
                                            </span></div>
                                        <div class="w-2/6">
                                            <input v-on:click="checkbox" v-bind:data-id="publication.id" class="rounded articles-checkbox absolute left-1 z-1 h-4 w-4 mr-2 outline-none active:outline-none" type="checkbox" name id />
                                        </div>
                                    </div>
                                    <div class="w-11/12 rounded-r-md flex flex-col place-content-center" v-on:click="openPublication(publication) ; articleShow = !articleShow;">
                                        <div class="w-full text-sm font-medium text-gray-900 px-2 text-left truncate article_title">{{publication.title}}</div>
                                        <div class="w-full text-sm font-light text-pink-700 px-2 text-left truncate">{{publication.journal}}</div>
                                        <div class="w-full text-xs font-thin px-2 text-left truncate">
                                            <span v-for="author in publication.authors" :key="author">{{author.name_full}},</span>
                                        </div>
                                    </div>
                                </div>
                                </transition-group>
                            </div>
                            <div v-else>
                                <div class="w-full h-24 flex flex-row mb-2 mt-2 rounded-md shadow-sm border hover:shadow-lg hover:border-purple-600 cursor-pointer">No record founded</div>
                            </div>
                        </div>
                    </div>
                    <!-- left column closed -->
                    <!-- right column starts -->
                    <div class="w-0 md:w-7/12 h-screen overflow-y-scroll scrollbar-thin scrollbar-track-indigo-200 scrollbar-thumb-indigo-600 bg-white">
                        <div class="w-full h-auto px-4 py-2 pr-5 bg-gradient-to-br from-teal-700 via-purple-600 to-indigo-900 sticky inset-x-0 z-10 left-0 mr-3 flex flex-row items-center">
                            <div class="w-11/12 min-h-20 h-20 font-medium px-4 text-gray-50 text-left text-lg">
                                {{currentPublication.title}}
                            </div>
                            <div class="w-12 font-medium flex flex-row justify-end items-center">
                                <div>
                                    <span @click="showDeleteModal = !showDeleteModal" class="w-12 h-12 bg-gray-900 bg-opacity-50 rounded-full justify-center items-center flex flex-row text-white font-bold hover:bg-gray-700 cursor-pointer">
                                       
                                       <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'trash']" />

                                    </span>
                                    <div>
                                        <div v-if="showDeleteModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                                            <div class="relative w-auto my-6 mx-auto max-w-sm">
                                                <!--content-->
                                                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                    <!--header-->
                                                    <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                                        <h3 class="text-3xl font-semibold">Delete</h3>
                                                        <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="showDeleteModal = !showDeleteModal">
                                                            <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                                        </button>
                                                    </div>
                                                    <!--body-->
                                                    <div class="relative p-6 flex-auto">
                                                        <p class="my-4 text-gray-600 text-lg leading-relaxed">
                                                            <b> Are you sure want to delete the collection articles?</b>
                                                            <br />
                                                            {{currentPublication.title}}
                                                        </p>
                                                    </div>
                                                    <!--footer-->
                                                    <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                                        <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="showDeleteModal = !showDeleteModal">Not now</button>
                                                        <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="CollectionArticlesDelete([currentPublication.id])">Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div v-if="showDeleteModal" class="bg-opacity-70 fixed inset-0 z-80 bg-black"></div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-8 text-md font-medium text-left py-2 bg-gray-200 rounded m-2 lg:mr-4">
                            <span class="text-white items-center pr-4">
                                <button class="cursor-pointer rounded text-red-500" v-if="currentPublication.pdf_url" @click="pdfViewer(currentPublication.pdf_url)">
                                   <font-awesome-icon class=" text-2xl cursor-pointer " :icon="['fas', 'file-pdf']" />
                                </button>
                            </span>
                            Published : {{currentPublication.pub_date}}. {{currentPublication.journal}}.
                            <span class="text-teal-500 pl-5">
                                <a class="cursor-pointer" v-if="currentPublication.pdf_url" @click="pdfViewer(currentPublication.pdf_url)">Visit PDF Articles</a>
                            </span>
                        </div>
                        <div class="px-8 py-2 w-10/12 text-lg font-bold text-blue-500 text-justify" v-if="currentPublication.abstract">Abstract</div>
                        <div class="px-8 py-2 w-11/12 text-md text-justify">{{currentPublication.abstract}}</div>
                        <div v-if="currentPublication.doi" class="px-8 py-2 w-10/12 text-md font-medium text-blue-500 text-justify">DOI:{{currentPublication.doi}}</div>

                        <div class="px-8 py-2 w-10/12 text-md font-bold text-blue-500 flex flex-start">

                            <div class="relative inline-block text-left">
                                <div>
                                    <!-- add to collection btn -->
                                    <button @click="collectionBtn" class="w-auto px-2 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100" id="options-menu" aria-expanded="true" aria-haspopup="true">
                                        Move to collection
                                    </button>
                                </div>
                                <div v-bind:class="[dropdown ? 'block':'hidden']" class="origin-top-right absolute right-15 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    <div class="py-1" role="none">
                                        <!-- collection name display dropdown-->
                                        <a v-for="collection in collection_list" :key="collection" @click="toggleSingleMovetoCollectionModal(collection)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                            <div class=" font-medium flex flex-row">
                                                
                                                <span v-text="collectionNameSplit(collection.name)" class="cursor-pointer"></span>
                                            </div>
                                        </a>
                                        <!-- end collection name display dropdown -->
                                        <!-- single articles add to collection modal -->
                                        <div v-if="showSingleCollectionModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                                            <div class="relative w-auto my-6 mx-auto max-w-sm">
                                                <!--content-->
                                                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                    <!--header-->
                                                    <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                                        <h3 class="text-3xl font-semibold">Add to Collection</h3>
                                                    </div>
                                                    <!--body-->
                                                    <div class="relative p-6 flex-auto">
                                                        <p class="my-4 text-gray-600 text-lg leading-relaxed">
                                                            <b>Are you sure want to move into another collection?</b>
                                                            <br />
                                                            {{currentPublication.title}}
                                                        </p>
                                                    </div>
                                                    <!--footer-->
                                                    <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                                        <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="closeSingleMovetoCollectionModal()">Not now</button>
                                                        <button class="text-blue-500 bg-transparent border border-solid border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="movetocollection([currentPublication.id])">Yes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end single articles add to collection modal -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="px-8 py-2 w-full text-sm font-bold text-blue-500 flex flex-row justify-start items-center flex-wrap">
                            Tags:
                            <span class="m-2 h-auto w-auto px-2 py-1 rounded-md border text-gray-500 border-gray-400 flex flex-row justify-center items-center">
                                my_tags_1
                                <span class="w-5 h-5 bg-gray-400 border hover:border-gray-600 hover:bg-white pb-1 text-white hover:text-gray-600 font-bold rounded-full text-xs ml-3 flex flex-row justify-center items-center">x</span>
                            </span>
                            <button class="font-bold px-2 py-1 bg-teal-300 text-teal-600 hover:text-white hover:bg-teal-700 border border-teal-500 rounded-md hover:shadow-lg">Add tags</button>
                        </div> -->
                        <!-- collection articles note list display here  -->
                        <NoteTaking :note_list="noteList" :current="currentPublication.id" />

                        <!-- <div class="px-8 py-2 w-10/12 text-md font-bold text-blue-500 flex flex-start">
                            <button class="w-auto px-2 ring ring-blue-500 ring-offset-2 rounded-sm text-blue-600 py-1 font-bold m-3 transform hover:scale-105 motion-reduce:transform-none">Assign-to-bot</button>
                        </div> -->
                        <div class="px-8 py-2 w-10/12 text-md flex flex-col" v-if="currentPublication.authors.length>0">
                            <h1 class="px-2 py-2 text-lg font-bold text-blue-500 text-justify">Authors</h1>
                            <span v-for="author in currentPublication.authors" :key="author" class="px-2 py-1 text-sm text-gray-700 text-left">{{author.name_full}}</span>
                        </div>
                        <div class="px-8 py-2 w-10/12 text-md flex flex-col" v-if="currentPublication.institutes.length>0">
                            <h1 class="px-2 py-2 text-lg font-bold text-blue-500 text-justify">Affliations</h1>
                            <p v-for="author in currentPublication.institutes" :key="author" class="px-2 py-1 text-sm text-gray-700 text-left">{{author.name}}</p>
                            <br />
                        </div>
                        <h1 class="px-8 py-2 w-full bg-gray-200 text-gray-700 font-normal text-lg text-left">Citation download</h1>
                        <br />
                        <div class="px-8 w-10/12 flex flex-start mb-8">
                            <a class="w-auto px-2 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100" :href="citation_download_bib(currentPublication.id)"> bibText </a>
                            <a class="w-auto px-2 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100" :href="citation_download_enw(currentPublication.id)"> EndNote </a>
                            <a class="w-auto px-2 bg-teal-500 rounded-sm text-white py-1 font-medium m-3 transform hover:scale-105 motion-reduce:transform-100" :href="citation_download_ref(currentPublication.id)"> Refman </a>
                        </div>
                    </div>
                    <!-- right column closed -->

                </div>
                <!-- full column closed -->
                <!-- start articles load time loader  -->
                
                    <div v-if="loading && !publication_list.length" class="flex h-screen">
                        <!-- <h2 class="text-center">{{collectionTitle}} ( {{currentArticleCount}} / {{totalCount}} )</h2> -->
                        <div class="m-auto">
                            <p class="w-full text-center p-5">
                                <!-- <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                                <span class="sr-only">Loading...</span> Loading Articles -->
                                <img class="h-20 mx-auto" :src="$static.path(require('@/assets/loading-animate.gif'))" alt="loading...">
                                Loading Articles
                            </p>
                        </div>
                    </div>
                
                <!-- end articles load time loader  -->
                <!-- start articles no record found  -->
                <div v-if="!loading && !publication_list.length">
                    <div class="flex h-screen">
                        <div class="m-auto">
                            <!-- <p class="w-full text-center p-5">No record founded</p> -->
                            <img class="w-56 h-56" :src="$static.path(require('@/assets/loading.svg'))"/>
                            <div class="text-center text-xl font-medium">
                                No records to show
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end articles no record found  -->
                <!-- start notification container -->
                <div v-if="notification" class="relative w-full -mt-5">
                    <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                        <p class="text-white font-bold">{{messagebody}}</p>
                        <font-awesome-icon class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
                    </div>
                </div>
                <!-- end notification container -->
            </div>
            <!-- row closed -->
        </div>
    </main>
</div>
<PopupArticle  class="flex-none md:hidden" :collectionpage ='true' 
v-if="articleShow" :collectiondata='currentPublication' 
@cancelArticle="articleShow = !articleShow" />
</template>

<script>
import LeftNavigation from "@/components/LeftNavigation.vue";
import NoteTaking from "@/components/NoteTaking.vue";
import PopupArticle from '@/components/PopupArticle.vue';
// const { BrowserWindow } = require("@electron/remote");
// import json from "../assets/data/pub.json";
import {
    mapActions
} from "vuex";
import $ from "jquery";
import axios from "axios";

export default {
    name: "Collection",
    components: {
        LeftNavigation,
        NoteTaking,
        PopupArticle
    },
    data() {
        return {
            publication_list: [],
            currentPublication: {},
            collection_list: [],
            collectionTitle: "",
            loopBreak: null,
            loading: false,
            totalCount: 0,
            currentArticleCount: 0,
            searchbox: "",
            showDeleteModal: false,
            notification: false,
            messagestatus: "",
            messagebody: "",
            showmultipleDeleteModal: false,
            multipledropdown: false,
            selectedcollection: "",
            dropdown: false,
            showSingleCollectionModal: false,
            showmultipleCollectionModal: false,
            currentCollection: "",
            display_muliple_option: false,
            note_list: [],
            articleShow : false,
        };
    },
    methods: {
        ...mapActions(["getAllNotes"]),
        ...mapActions(["GetSinglecollection"]),
        collectionListFunction: async function (new_id) {
            var that = this;
            this.currentCollection = new_id
            that.publication_list = [];
            that.currentPublication = {};
            this.loading = true;
            var userInfo = this.$store.getters.StateUserInfo;
            this.loopBreak = new_id;
            var collection_input = {
                collection_id: new_id,
                user: userInfo.id,
                key: userInfo.key
            };
            try {
                var collection_result = await this.GetSinglecollection(collection_input);
                var collection_data = collection_result.data.data[0];
                this.collectionTitle = collection_data.name;
                var collection_articles_list = collection_data.paper;
                var total_articles_count = collection_articles_list.length;
                this.totalCount = total_articles_count

                if (total_articles_count > 0) {
                    that.publication_list = []
                    for (var i = 0; i < total_articles_count; i++) {
                        var article = collection_articles_list[i];
                        that.currentArticleCount = i + 1;

                        var articles_url = `/publication/?id=${article}&fields=id,title,pub_type,doi,pub_date,journal,impact_factor,authors,institutes,abstract,html_url,pdf_url&Key=${this.$store.getters.StateUserInfo.key}&format=json`;
                        axios.get(articles_url)
                            .then(function (response) {
                                if (response && response.data) {
                                    that.publication_list = [
                                        ...that.publication_list,
                                        response.data.data[0]
                                    ];
                                    that.currentPublication = that.publication_list[0];
                                    that.noteTakingList(that.publication_list[0])
                                    that.loading = false;

                                } else {
                                    console.log("skiped details: ", response);
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                    console.log("result not empty", collection_articles_list)

                } else {
                    console.log("no result")
                    that.publication_list = [];
                    that.currentPublication = {};
                    that.loading = false;
                    that.currentArticleCount = 0
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }

        },
        openPublication(publication) {
            this.currentPublication = publication;
            this.noteTakingList(publication)

        },
        collectionNameSplit(data) {
            return data.split(':')[0]
        },
        search: function () {
            var input_value = this.searchbox;
            console.log("new function:", input_value);
            $(".article_title").each(function () {
                var str = $(this).text();
                if (input_value) {
                    if (str.toLowerCase().indexOf(input_value.toLowerCase()) >= 0) {
                        $(this)
                            .closest(".card_article_list")
                            .show();
                    } else {
                        $(this)
                            .closest(".card_article_list")
                            .hide();
                    }
                } else {
                    $(this)
                        .closest(".card_article_list")
                        .show();
                }
            });
        },
        pdfViewer: async function (data) {
            console.log("pdf preview url: ", data)
            var option = {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            }
            var result = await fetch(data, option)
            console.log(result)
            // let win = new BrowserWindow({
            //     width: 800,
            //     height: 600
            // })
            // win.loadURL(data)
        },
        citation_download_bib(currentPublication) {
            return "https://www.zealbots.com/library/download_bib/" + currentPublication
        },
        // This function is not yet used 
        botEditClick: function () {
            console.log("$route.params.id",this.$route.params.id)
            // console.log("Do you want Delete bot?", this.botid)
            this.$browser.openBrowser(`https://www.zealbots.com/dashboard/collection/${this.currentPublication.name}/${this.$route.params.id}`)
        },
        citation_download_enw(currentPublication) {
            return "https://www.zealbots.com/library/download_enw/" + currentPublication
        },

        citation_download_ref(currentPublication) {
            return "https://www.zealbots.com/library/download_ris/" + currentPublication
        },
        checkbox: function () {
            // checkbox select and unselect item. if any checkbox check that time addto collection and articles delete button show, otherwise button hide function
            var checkboxes2 = document.querySelectorAll('.articles-checkbox:checked');
            if (checkboxes2 && checkboxes2.length == 0) {

                this.display_muliple_option = false
            } else {
                this.display_muliple_option = true
            }
        },
        toggleMultipleDeleteModal: function () {
            this.showmultipleDeleteModal = !this.showmultipleDeleteModal
        },
        multipleAticleDelete: function () {
            // checkbox selected item find and multiple articles delete function
            var arraypublication = []
            var checkboxes = document.getElementsByClassName("articles-checkbox")
            for (var checkbox of checkboxes) {
                if (checkbox.checked) {
                    arraypublication.push(checkbox.getAttribute('data-id'))
                }
            }
            console.log("articles deleted", arraypublication)
            this.CollectionArticlesDelete(arraypublication)
        },

        CollectionArticlesDelete: function (remove_id) {
            var that = this
            var userInfo = this.$store.getters.StateUserInfo;
            console.log("collection articles delete ")
            var data = {}
            data['collection_id'] = this.$route.params.id
            data['article_id'] = remove_id
            data['user_id'] = userInfo.id
            try {
                var collection_delete_url = `collection/CollectionArticleDelete/?Key=${this.$store.getters.StateUserInfo.key}`;
                console.log("articles_url: ", data)
                axios.post(collection_delete_url, data)
                    .then(function (response) {
                        if (response && response.data) {
                            console.log("log : ", response.data);
                            that.showDeleteModal = false
                            that.notification = true
                            that.messagestatus = "success"
                            that.messagebody = `Article deleted successfully`
                        } else {
                            console.log("skiped details: ", response);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } catch (error) {
                that.notification = true
                that.messagestatus = "error"
                that.messagebody = error
            } finally {
                that.showmultipleDeleteModal = false
                that.showDeleteModal = false
                setTimeout(function () {
                    that.notification = false;
                    that.collectionListFunction(that.$route.params.id);
                }, 5000)
            }
        },
        collectionBtn: function () {
            console.log("before: ", this.dropdown);

            this.dropdown = !this.dropdown;
            console.log("after: ", this.dropdown);
        },
        toggleSingleMovetoCollectionModal: function (collection) {
            console.log("clicked toggleSingleAddtoCollectionModal", collection)
            this.selectedcollection = collection
            this.showSingleCollectionModal = !this.showSingleCollectionModal;
        },
        closeSingleMovetoCollectionModal: function () {
            this.showSingleCollectionModal = false
            this.dropdown = false
        },
        movetoCollectionbtn: function () {
            console.log("clicked collection", this.multipledropdown)
            this.multipledropdown = !this.multipledropdown;
            console.log("after clicked collection", this.multipledropdown)
        },
        toggleMultipleMovetoCollectionModal: function (collection) {
            console.log("clicked toggleMultipleMovetoCollectionModal", collection)
            this.selectedcollection = collection
            this.showmultipleCollectionModal = !this.showmultipleCollectionModal;
            console.log("after clicked collection", this.showmultipleCollectionModal)
        },
        closeMultipleMovetoCollectionModal: function () {
            this.showmultipleCollectionModal = false;
            this.multipledropdown = false
        },
        MultipleArticlesMovetoCollection: function () {
            // checkbox selected item find and multiple articles add into collection function
            console.log("MultipleArticlesAddtoCollection: ", this.selectedcollection)
            var arraypublication = []
            var checkboxes = document.getElementsByClassName("articles-checkbox")
            for (var checkbox of checkboxes) {
                if (checkbox.checked) {
                    arraypublication.push(checkbox.getAttribute('data-id'))
                }
            }
            console.log("final collection: ", this.selectedcollection, arraypublication)
            this.movetocollection(arraypublication)
        },
        movetocollection: async function (articles) {

            //    articles add into collection function
            var that = this
            var collection = this.selectedcollection.id
            console.log("collection id: ", collection, articles);
            var userInfo = this.$store.getters.StateUserInfo;

            var input_data = {
                from_collection_id: this.currentCollection,
                to_collection_id: collection,
                publication_id: articles,
                user_id: userInfo.id
            };
            console.log("move articles data: ", input_data);
            
            await axios
            .post(`https://api.zealbots.com/api/v1/collection/article/move/?Key=${userInfo.key}`, input_data)
            .then(function (response) {
                console.log("log", response)
                that.notification = true
                that.messagestatus = "success"
                that.messagebody = response.data.message
                that.showSingleCollectionModal = false
                that.dropdown = false
                that.showmultipleCollectionModal = false
                that.display_muliple_option = false
                that.multipledropdown = false
                setTimeout( () => {
                    that.notification = false
                    that.collectionListFunction(that.currentCollection)
                }, 5000)
            })
            .catch ( error => {
                that.notification = true
                that.messagebody = error
            })
        },
        noteTakingList: async function (article) {
            // var that = this
            console.log("note :", article.id)
            this.note_list = []
            var userInfo = this.$store.getters.StateUserInfo;
            var note_input = {
                article_id: article.id,
                user_key: userInfo.key
            };
            var get_note_list = await this.getAllNotes(note_input);
            console.log("get_note_list", get_note_list)
            // var note_api_url = `notes/view/?article=${article.id}&Key=${userInfo.key}&format=json`
            // try {
            //     axios.get(note_api_url)
            //         .then(function (response) {
            //             if (response && response.status == 200 && !("error" in response.data)) {
            //                 console.log("log", response.data)
            //                 that.note_list = response.data.data
            //             } else {
            //                 console.log("no article matched notes", article.id)
            //                 console.log(response.data)
            //             }
            //         });
            // } catch (error) {
            //     console.log("error:", error)
            // } finally {
            //     console.log("finally todo list completed ")
            // }
        },

    },

    created: function () {
        // console.log('route', this.$route.params.id);
        this.collectionListFunction(this.$route.params.id);
    },
    computed: {
        noteList: function () {
            return this.$store.getters.todoInfo;
        },
    },
    mounted: async function () {
        // await this.collectionListFunction();
        this.collection_list = this.$store.getters.CollectionListInfo.data;
        console.log(this.collection_list)
    },
    beforeRouteUpdate(to, from, next) {
        this.collectionListFunction(to.params.id);
        next()
    },
    watch: {
        $route(to, from) {
            console.log(to, from, this.$route.path);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
