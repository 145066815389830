import { createStore } from 'vuex'
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";
axios.defaults.withCredentials = false
axios.defaults.baseURL = 'https://api.zealbots.com/api/v1/';
axios.defaults.headers.common['Content-Type'] = 'application/json';
import todos from './modules/todos';
import notes from './modules/notes';
import zkloud from './modules/zkloud';
// import zkloudSync from './modules/zkloudSync';

const store = {
    state: {
        user: null,
        userinfo: {
            key: null,
            id: null,
        },
        botlist: [],
        bucket_list: [],
        singlebucket: null,
        collectionlist: [],
        singlecollection: [],
        citeman: [],
        citemanlists: [],
        UserAssets :null,
        ProfileData : null

    },
    getters: {

        isAuthenticated: state => state.user,
        StateUser: state => state.user,
        StateUserInfo: state => state.userinfo,
        BotListInfo: state => state.botlist,
        BucketList: state => state.bucket_list,
        BucketListid: state => state.bucket_list,
        CollectionListInfo: state => state.collectionlist,
        SingleCollectionInfo: state => state.singlecollection,
        CitemanList: state => state.citeman,
        citemanlistsInfo: state => state.citemanlists,
        UserAssetsInfo : state => state.UserAssets,
        ProfileDataInfo : state => state.ProfileData,


    },
    actions: {
        async LogIn({ commit }, User) {
            return new Promise((resolve, reject) => {
                axios.post('login/', User)
                    .then(response => {
                        if (response && response.data && response.data.message) {
                            commit('setUser', User.email)
                            commit("setUserId", response.data.id)
                            commit("setUserKey", response.data.Key)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        async LogOut({ commit }) {
            commit('LogOut')
        },

        async GetCitemanList({ commit }, data) {
            var Citeman_url = `/citeman/?Key=${data.key}&format=json`
            return new Promise((resolve, reject) => {
                axios.get(Citeman_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('CitemanLists', response.data)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        reject(error);
                    })

            });
        },

        async GetBucketListid({ commit }, data) {
            return new Promise((resolve, reject) => {
                var bucket_url = `/zbot_article/?user=${data.user}&fields=id&Key=${data.key}&format=json`
                axios.get(bucket_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('BucketListid', response.data)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });
        },

        async GetCollectionList({ commit }, data) {
            console.log({data})
            var collection_url = `/collection/?author=${data.id}&fields=id,name,paper&Key=${data.key}&format=json`
            return new Promise((resolve, reject) => {
                axios.get(collection_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('CollectionList', response.data)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });
        },
        async GetCiteman_list({ commit }, data) {
            var citeman_url = `/citeman/?author=${data.user}&fields=id,name,article&Key=${data.key}&format=json`
            return new Promise((resolve, reject) => {
                axios.get(citeman_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('CitemanListinfo', response.data)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });
        },
        async GetSingleCiteman({ commit }, data) {
            var collection_url = `/citeman/?author=${data.user}&id=${data.collection_id}&fields=id,name,article&Key=${data.key}&format=json`
            return new Promise((resolve, reject) => {
                axios.get(collection_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('SingleCollection', response.data)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });
        },

        async GetSinglecollection({ commit }, data) {
            var collection_url = `/collection/?author=${data.user}&id=${data.collection_id}&fields=id,name,paper&Key=${data.key}&format=json`
            return new Promise((resolve, reject) => {
                axios.get(collection_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('SingleCollection', response.data)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });
        },
        async GetBotList({ commit }, data) {
            return new Promise((resolve, reject) => {
                var bucket_url = `/zbot_article/?user=${data.user}&fields=id,articles,bot&Key=${data.key}&format=json`
                axios.get(bucket_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('BotList', response.data)
                            resolve(response);
                        } else {
                            resolve(response);
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })

            });
        },
        async GetSingleBucket({ commit }, data) {

            return new Promise((resolve, reject) => {
                var bucket_url = `/zbot_article/?user=${data.user}&id=${data.bucket_id}&Key=${data.key}&format=json`
                axios.get(bucket_url)
                    .then(response => {
                        if (response && response.data) {
                            commit('SingleBucket', response.data)
                            resolve(response);
                        } else if (response && response.data.error) {
                            let message = 'Response error'
                            resolve(response);
                            commit('SingleBucket', message)
                        } else if (response == undefined) {
                            let message = '500 Error'
                            commit('SingleBucket', message)
                        }

                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },

        //UserAsset Info Bot and Collection Limitations
       async Set_UserAssetsInfo({ commit }, data ) {
            const response = await axios.get(`https://api.zealbots.com/api/v1/userassets/${data.id}/?Key=${data.key}&format=json`)
            if (response && response.data){
                commit('UserAssets', response.data)
            } else if(response && response.data.error) {
                let message = 'Response error'
                commit('UserAssets', message)
            } else if (response == undefined) { 
                let message = "500 Error"
                commit('UserAssets', message)
            } else {
                let message = 'Please check your internet connection'
                commit('UserAssets', message)
            }

          
        },
        //Profile Info data collection information
        async Set_ProfileInfo({ commit }, data ) {
            const response = await axios.get(`https://api.zealbots.com/api/v1/login_details/${data.id}/?Key=${data.key}&format=json`)
            if (response && response.data){
                commit('ProfileData', response.data)
            } else if(response && response.data.error) {
                let message = 'Response error'
                commit('ProfileData', message)
            } else if (response == undefined) { 
                let message = "500 Error"
                commit('ProfileData', message)
            } else {
                let message = 'Please check your internet connection'
                commit('ProfileData', message)
            }
          
        },

    },
    mutations: {
        setUser: (state, email) => { state.user = email },
        setUserId: (state, id) => { state.userinfo.id = id },
        setUserKey: (state, key) => { state.userinfo.key = key },
        LogOut: (state) => {
            state.user = null
            state.userinfo.id = null
            state.userinfo.key = null
            state.botlist = null
            state.bucket_list = []
            state.singlebucket = []
            state.collectionlist = []
            state.singlecollection = []
            state.UserAssets = null
            state.citeman = []
            state.ProfileData = null

          

        },
        CollectionList: (state, collectionlist) => { state.collectionlist = collectionlist },
        CitemanListinfo: (state, citeman) => { state.citeman = citeman },
        citemanlistsInfo: (state, citemanlists) => { state.citemanlists = citemanlists },
        SingleCollection: (state, collection) => { state.singlecollection = collection },
        BotList: (state, botlist) => { state.botlist = botlist },
        SingleBucket: (state, botlist) => { state.singlebucket = botlist },
        BucketListid: (state, bucket_listid) => { state.bucket_listid = bucket_listid },
        // This was not used, we need to apply in features
        Bucketlist: (state, botlist, bucket_list) => {
            //[{botlist:bucket_list}]
            var sample = {}
            sample[botlist.toString()] = bucket_list
            state.bucket_list.unshift(sample)
        },
        UserAssets :(state, data) => {state.UserAssets = data},
        ProfileData : (state, data) => {state.ProfileData = data},
        
        
    
       
    
    }
}

export default createStore({
    modules: {
        store,
        todos,
        notes,
        zkloud,
        // zkloudSync
    },
    plugins: [createPersistedState()],
})