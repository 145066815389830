<template>
<Navbar />
<div class="h-auto min-h-screen flex flex-col mt-20 mb-20">
    <div class="w-full lg:w-6/12 flex flex-row self-center justify-center text-3xl font-bold text-blue-500 px-4">
        Help Page
    </div>
    <div class="relative w-2/3 p-4 mt-24 pb-12 mx-auto bg-white rounded-lg shadow-xl" v-for="post in overalldata" :key="post">
        <h1 class="text-xl font-semibold text-indigo-600"> {{post.question}}</h1>

        <p>{{post.com_answer}}</p>

        <div>

            <div class="absolute right-0 bottom-0  m-2 w-40 h-14 flex gap-2 justify-start items-center">
                {{post.like}}
                <svg @click="like_rating(post.id)" class="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 172 172" style=" fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                        <path d="M0,172v-172h172v172z" fill="none"></path>
                        <g fill="#2ecc71">
                            <path d="M89.69531,16.125l-1.67969,1.51172l-36.44922,36.11328h-24.69141v86h90.53516c7.57959,0 14.19336,-5.35401 15.78906,-12.76562l11.58984,-53.75c2.1206,-9.93115 -5.64795,-19.48437 -15.78906,-19.48437h-30.90625l1.00781,-4.03125c1.0918,-0.83984 1.78467,-1.19678 3.35938,-3.35937c2.51953,-3.44336 5.03906,-8.77636 5.03906,-15.95703c0,-7.66357 -6.92871,-14.27734 -15.62109,-14.27734zM93.55859,27.37891c2.26758,0.44092 3.19141,1.36474 3.19141,3.02344c0,4.8501 -1.46972,7.85254 -2.85547,9.74219c-1.38574,1.88965 -2.35156,2.35156 -2.35156,2.35156l-1.84766,1.00781l-0.67187,2.18359l-3.19141,12.09375l-1.67969,6.71875h44.84766c3.54834,0 5.9419,3.08643 5.20703,6.55078l-11.42187,53.75c-0.5459,2.51953 -2.81347,4.19922 -5.375,4.19922h-58.28516v-67.69141zM37.625,64.5h10.75v64.5h-10.75z"></path>
                        </g>
                    </g>
                </svg>

                <svg @click="unlike_rating(post.id)" class="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 172 172" style=" fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                        <path d="M0,172v-172h172v172z" fill="none"></path>
                        <g fill="#e74c3c">
                            <path d="M54.58984,32.25c-7.57959,0 -14.19336,5.35401 -15.78906,12.76563l-11.58984,53.75c-2.1206,9.93115 5.64795,19.48438 15.78906,19.48438h30.90625l-1.00781,4.03125c-1.0918,0.83984 -1.78467,1.19678 -3.35937,3.35938c-2.51953,3.44336 -5.03906,8.77637 -5.03906,15.95703c0,7.66357 6.92871,14.27734 15.62109,14.27734h2.18359l1.67969,-1.51172l36.44922,-36.11328h24.69141v-86zM54.58984,43h58.28516v67.69141l-34.43359,33.92969c-2.26758,-0.44092 -3.19141,-1.36474 -3.19141,-3.02344c0,-4.8501 1.46973,-7.85254 2.85547,-9.74219c1.38574,-1.88965 2.35156,-2.35156 2.35156,-2.35156l1.84766,-1.00781l0.67188,-2.18359l3.19141,-12.09375l1.67969,-6.71875h-44.84766c-3.54834,0 -5.9419,-3.08643 -5.20703,-6.55078l11.42188,-53.75c0.5459,-2.51953 2.81348,-4.19922 5.375,-4.19922zM123.625,43h10.75v64.5h-10.75z"></path>
                        </g>
                    </g>
                </svg>
                {{post.unlike}}

                <p class="w-full font-semibold text-sm text-indigo-600">{{post.ratings.length}} ratings</p>
                {{post.ratings}}
            </div>

            <div v-if=" post.answer">
                <div class="hover:bg-green-100 w-max bg-gray-100 text-sm items-center px-2 py-0.8 text-right">
                    <div @click="abstract_show(post.id)" style="color: #00b2b2" class="flex flex-row text-letf">
                        Abstract
                    </div>
                </div>

                <div v-if="isHidden && post.id == post.id">
                    <p>{{ post.answer }}</p>
                </div>

            </div>

        </div>
    </div>

</div>

<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import axios from "axios";
export default {
    name: 'About',
    components: {
        Navbar,
        Footer,
    },
    data: () => ({
        singel_citeman_data: [],
        overalldata: [],
        answerratings: [],
        Answer_detail: [],
        isHidden: false,

    }),
    methods: {
        abstract_show(pub_id) {
            console.log(pub_id)
            this.overalldata.forEach((entry) => {
                if (entry.id == pub_id) {
                    this.isHidden = !this.isHidden;
                } else {
                    entry.id = pub_id;
                    this.isHidden = true;
                }
            });

        },
        like_rating: async function (id) {

            var user_id = this.$store.getters.StateUserInfo.id;
            var user_key = this.$store.getters.StateUserInfo.key;

            var data = {}
            data['useful'] = true
            data['email'] = ""
            data['user'] = user_id
            console.log(data)

            axios.post(`https://api.zealbots.com/help/questionratings/${id}/?Key=${user_key}&format=json`, data)
                .then((response) => {
                    if (response) {
                        // console.log(id)
                        // this.overalldata.forEach((el) => {
                        //     if (el.id == id) {
                        //         ++el.like
                        //     }
                        // })
                        console.log("response.data", response.data)
                        this.questionratingsdetail()
                    } else {
                        console.log("value Err")
                    }

                })

        },
        unlike_rating: async function (id) {

            var user_id = this.$store.getters.StateUserInfo.id;
            var user_key = this.$store.getters.StateUserInfo.key;

            var data = {}
            data['useful'] = false
            data['email'] = ""
            data['user'] = user_id
            console.log(data)

            axios.post(`https://api.zealbots.com/help/questionratings/${id}/?Key=${user_key}&format=json`, data)
                .then((response) => {
                    if (response) {
                        // console.log(id)
                        // this.overalldata.forEach((el) => {
                        //     if (el.id == id) {
                        //         ++el.like
                        //     }
                        // })
                        console.log("response.data", response.data)
                        this.questionratingsdetail()
                        return response.data
                    } else {
                        console.log("value Err")
                    }

                })

        },
        async question_help_detail() {
            var user_key = this.$store.getters.StateUserInfo.key;
            var profileDetailsUrl = `https://api.zealbots.com/api/v1/question_help_detail/?Key=${user_key}&format=json`;
            await axios.get(profileDetailsUrl)
                .then((response) => {
                    this.singel_citeman_data = response.data.results
                    response.data.results.forEach((el) => {
                        let data = {
                            id: el.id,
                            question: el.title,
                            com_answer: el.body,
                            answer: "",
                            ratings: '',
                            like: "",
                            unlike: ""
                        }
                        this.overalldata.push(data)
                    })
                })
        },

        async Answer_help_detail() {
            var user_key = this.$store.getters.StateUserInfo.key;
            var Answer_detail = `https://api.zealbots.com/api/v1/Answer_help_detail/?Key=${user_key}&format=json`;
            await axios.get(Answer_detail)
                .then((response) => {
                    response.data.results.forEach((el) => {
                        this.overalldata.map((element) => {
                            if (el.query == element.id) {
                                element.answer = el.body
                            }

                        })
                    })
                    this.Answer_detail = response.data.results
                })
        },
        async questionratingsdetail() {
            var user_key = this.$store.getters.StateUserInfo.key;
            var answerratings = `https://api.zealbots.com/api/v1/questionratingsdetail/?Key=${user_key}&format=json`;
            await axios.get(answerratings)
                .then((response) => {
                    response.data.data.data.forEach((el) => {
                        this.overalldata.map((element) => {
                            if (el.id == element.id) {
                                element.like = el.useful
                                element.unlike = el.notuse
                                element.ratings = el.totalrating
                            }
                        })
                    })
                    this.answerratings = response.data.data.data
                    console.log(this.overalldata)
                })

        }

    },
    async created() {
        this.question_help_detail()
        this.Answer_help_detail()
        this.questionratingsdetail()

        // console.log(this.overalldata)

    },
};
</script>

<style scoped>

</style>
