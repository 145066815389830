<template>
<div class="flex flex-row h-full w-screen bg-gray-200">

    <LeftNavigation />
    <main class="flex-1 bg-gray-200 dark:bg-gray-900 overflow-y-auto transition duration-500 ease-in-out">
        <div class="text-gray-700 h-screen dark:text-gray-500 transition duration-500 ease-in-out">
            <div class="w-full h-full flex flex-row">

                <div class="w-1/6 flex flex-col bg-gray-100">
                    <div class="flex justify-around pt-4 pb-5 bg-gray-300">
                        <div class="flex-initial">
                            <span class="text-black-500 text-md font-medium tracking-widest">Your folders</span>
                        </div>
                    </div>

                    <div class="mt-4 ">
                        <!-- <ul>
                            <transition-group :css="false" @before-enter="fileTreeBeforeEnter" @enter="fileTreeEnter" @leave="fileTreeLeave">
                                <li v-for="item in filteredFiles" :key="item" class="">

                                    <div v-if="item.directory" @click="open(item.name)" class="p-1  border-b border-gray-300 hover:border-gray-50 pb-2 pt-2 bg-gray-100 cursor-pointer hover:bg-gray-200 w-full justify-start items-center">
                                        <div class="inline-flex">
                                            <i class="pl-2 fas text-gray-400 fa-folder"></i>
                                            <span class="text-sm 2xl:text-md text-gray-800 text-left px-4 w-56 font-medium truncate">{{ item.name }}</span></div>
                                    </div>
                                </li>
                            </transition-group>
                        </ul> -->
                    </div>
                </div>
                <!-- Main body -->
                <div class="w-5/6 flex flex-col bg-gray-200">
                    <div class="w-full flex flex-row bg-gray-300">
                        <div class="w-7/12 flex flex-row justify-start bg-gray-300">
                            <div class="w-full p-2">
                                <input class="w-full h-10 rounded-md bg-white text-gray-800 py-2 mt-0 mb-1 border-1 border-gray-200 text-sm" placeholder="Search files from your cloud" v-model="searchString" type="text" />
                            </div>
                        </div>
                        <div class="w-5/12 flex flex-row gap-2 pl-4 items-center">
                            <div class="w-48 flex flex-col items-center p-2  border-gray-600 rounded">
                                <label @click="fileUploadModal = !fileUploadModal" class="flex flex-row items-center cursor-pointer
                                        hover:transition duration-100 transform-gpu hover:scale-110" for="file">
                                    <img class="w-10" src="https://img.icons8.com/color-glass/96/000000/upload-to-cloud.png" />
                                    <span class="text-gray-600 text-sm text-center font-bold p-2 text-md">Upload file</span>
                                </label>
                                <!-- <input id="file" type='file' class="hidden" multiple @change="fileUpload" /> -->
                            </div>
                        </div>
                    </div>
                    <FileUpload v-if="fileUploadModal" @cancel="fileUploadModal = false"/>

                    <!-- <div class="relative">
                        <input class="absolute bg-gray-300" type="file" @change="uploadInput" placeholder="Choose a image to upload">
                    </div>
                    <button @click="getPreSignedUrl">Upload image</button> -->
                    <h1 class="text-center text-2xl font-semibold h-screen flex justify-center items-center">
                        Coming soon
                    </h1>

                </div>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import LeftNavigation from '@/components/LeftNavigation.vue'
import FileUpload from '@/components/FileUpload.vue'
import axios from 'axios';
// import fs from 'fs';
import { ref, computed } from 'vue';
import { mapActions, mapGetters, useStore } from 'vuex';
import searchSortAnimate from '@/mixins/searchSortAnimate'
const atob = require('atob');
// const homedir = require('os').homedir();
// let zkloud_path = homedir + '/data'
export default {
    mixins: [searchSortAnimate],
    computed: mapGetters(["awsApi"]),
    setup(){
        const store = useStore();
        const searchString = ref('')
        const filesfromVuex = computed(() => {
            return store.getters["files"]
        })
        const filteredFiles = computed(() => {
            return searchString.value ?
                filesfromVuex.value.filter(s => s.name.toLowerCase().includes(searchString.value.toLowerCase())) :
                filesfromVuex.value
        })
        return {
            filteredFiles,
            searchString,
            store
        }
    },
    data: () => {
        return {
            image: '',
            uploadURL: '',
            fileUploadModal: false,
        }
    },
    components: {
        LeftNavigation,
        FileUpload
    },
    methods: {
        ...mapActions(["getS3Files", "fetchAllFiles"]),
        fileUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0])
        },
        createImage(file) {
            // var image = new Image()
            let reader = new FileReader()
            reader.onload = (e) => {
                console.log('length: ', e.target.result.includes('data:image/jpeg'))
                if (!e.target.result.includes('data:image/jpeg')) {
                    return alert('Wrong file type - JPG only.')
                }
                this.image = e.target.result
            }
            reader.readAsDataURL(file)
        },
        async getPreSignedUrl() {
            let image = this.image
            let headersList = {}

            let reqOptions = {
                url: "https://akslg8zhld.execute-api.eu-central-1.amazonaws.com/default/getPreSignedURL",
                method: "GET",
                headers: headersList,
            }
            axios.request(reqOptions).then(async function (response) {
                let binary = atob(image.split(',')[1])
                let array = []
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i))
                }
                let blobData = new Blob([new Uint8Array(array)], {
                    type: 'image/jpeg'
                })
                console.log(blobData)
                console.log('Uploading to: ', response.data.uploadURL)
                const result = await fetch(response.data.uploadURL, {
                    method: 'PUT',
                    body: blobData
                })
                console.log('Result: ', result)
                // Final URL for the user doesn't need the query string params
                this.uploadURL = response.data.uploadURL.split('?')[0]
            })

        }
    },
    created: function () {
        // this.getS3Files(this.awsApi.USER_NAME)
    }

}
</script>

<style>

</style>
