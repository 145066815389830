<template>
  <!-- component -->
  <div v-if="!loggedInn" class=" flex items-center h-screen bg-gray-100 justify-center">
    <div class="flex flex-col md:flex-row lg:flex-row justify-between border border-indigo-900  max max-w-screen-md">
      <div class="p-4 py-6 text-white hidden md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly" style="background-color : #274472">
        <div class="my-3 text-4xl font-bold tracking-wider text-center">
          <div class="flex justify-center">
            <img src="../assets/img/logo.png" class="w-20 h-20" />
          </div>
          <a href="#">Login</a>
        </div>
        <p class="mt-6 font-normal text-center text-white-300">
          Make your research relevant with the help of our semantic AI. ZealBots
          will find the best publications for you.
        </p>
      </div>
      
        <div class="lg:hidden md:hidden">
          <div class="my-3 text-4xl font-bold tracking-wider text-center">
          <div class="flex justify-center">
            <img src="../assets/img/logo.png" class="w-20 h-20" />
          </div>
          <a href="#" class="text-gray-600">Login</a>
        </div>

        </div>

      <div class="p-10">
        <p class="font-normal text-center text-green-600" v-if="info=='success'">
          <span>{{msg}}</span>
        </p>
        <p class="font-normal text-center text-red-600" v-if="info=='error'">
          <span>{{msg}}</span>
        </p>


        <form class="flex flex-col space-y-5">
          <div class="flex flex-col space-y-1">
            <label for="email" class="text-sm font-semibold text-gray-500">Email</label>
            <input
              type="email"
              v-model="email"
              v-on:keyup="login_trigger"
              id="email"
              autofocus
              class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
            />
          </div>
          <div class="flex flex-col space-y-1">
            <div class="flex items-center justify-between">
              <label for="password" class="text-sm font-semibold text-gray-500">Password</label>
            </div>
            <input
              type="password"
              v-model="password"
              v-on:keyup="login_trigger"
              id="password"
              class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
            />
          </div>
          <div class="flex items-center space-x-2">
            <input
              type="checkbox"
              id="remember"
              class="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200"
            />
            <label for="remember" class="text-sm font-semibold text-gray-500">Remember me</label>
          </div>

          <div>
          <button type="button" v-on:click="submit" class="group relative w-full flex  justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            LOGIN
          </button>
        </div>

          <a
            href="#"
            class="text-xs text-right  hover:underline"
          >Forgot Password?</a>

          <div class="flex flex-col space-y-5">
            <span class="flex items-center justify-center space-x-2">
              <span class="h-px bg-gray-400 w-14"></span>
              <span class="font-normal text-gray-500">or</span>
              <span class="h-px bg-gray-400 w-14"></span>
            </span>
            
             <div @click="myFunction" class="flex flex-row justify-center" >
              <button type="button" class="group relative font-bold w-1/4 flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-500 border-gray-400 focus:outline-none focus:ring-2 bg-gray-200 hover:bg-gray-100 ">
                  <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
              </button>
            </div>
            
            <a href="/signup" class="text-xs text-right underline ">Create New Account</a>
          
          </div>
        </form>
      </div>
    </div>
  </div>
  <CollectingData v-if="loggedInn" />

</template>

<script>
// import axios from "axios";
import { mapActions,
    mapGetters } from "vuex";
import CollectingData from '@/components/CollectingData.vue'
// import { userEntry } from "@/services/zkloudServices.js"

export default {
  data: () => ({
    email: "",
    password: "",
    info: "",
    msg: "",
    stateUserInfoData : null,
    loggedInn: false,

  }),
  components:{ CollectingData },
   computed: mapGetters(["StateUserInfo","UserAssetsInfo","CollectionListInfo"]),
  mounted() {},
  methods: {
    ...mapActions(["LogIn","Set_UserAssetsInfo" , "Set_ProfileInfo", "userEntry","GetCollectionList"]),
    async submit() {
      const User = { email: this.email, password: this.password };
      
      try {
        var response = await this.LogIn(User); 
        if (response && response.data && response.data.message) {
          this.info = "success";
          this.msg = response.data.message;
           this.loggedInn = true;

          // Run store functions for the data we need neccassarily.
          // userEntry(this.StateUserInfo.id)
          await this.Set_UserAssetsInfo(this.StateUserInfo)
          await this.Set_ProfileInfo(this.StateUserInfo)
          await this.GetCollectionList(this.StateUserInfo)

          setTimeout(() => {
            this.$router.push({name: 'Dashboard'});
          },5000)
        } else {
          this.info = "error";
          this.msg = response.data.error;
        }
      } catch (error) {
        if (!navigator.onLine) {
          this.info = "error";
          this.msg = "no internet, please check your internet connection";
        } else {
          this.info = "error";
          this.msg = "Server Maintenance";
        }
      }
    },
  
    login_trigger: function(e) {
      if (e.keyCode === 13) {
        this.submit();
      }
    },
    
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
