<template>
<div class="popup-background">
    <div class="w-3/4 h-3/4 md:w-4/5 rounded-xl p-8 mx-auto bg-white">
        <h1 class="text-2xl mb-4 font-bold">
            Upload files
        </h1>

        <!-- <span class="float-right text-5xl">
            &times;
        </span> -->
        <div class="flex flex-row gap-2 h-5/6 lg:flex-row md:flex-col">
            <div class="drag-area flex flex-col p-2 justify-center items-center border-2 border-dashed border-yellow-700 rounded-lg
            w-1/2 lg:w-1/2 md:w-full">
                
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" viewBox="0 0 172 172" style=" fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                            <path d="M0,172v-172h172v172z" fill="none"></path>
                            <g fill="#ffb349">
                                <path d="M99.76,15.5875c-17.93906,0 -33.12344,10.73656 -40.42,25.9075c-3.88344,-2.67406 -8.385,-4.6225 -13.4375,-4.6225c-12.81937,0 -23.04531,10.14531 -23.7575,22.79c-12.81937,4.6225 -22.145,16.58188 -22.145,30.96c0,18.32875 14.88875,33.2175 33.2175,33.2175h28.7025c1.23625,0.01344 2.39188,-0.63156 3.02344,-1.70656c0.61813,-1.075 0.61813,-2.39187 0,-3.46687c-0.63156,-1.075 -1.78719,-1.72 -3.02344,-1.70656h-28.7025c-14.62,0 -26.3375,-11.73094 -26.3375,-26.3375c0,-12.16094 8.19688,-22.40031 19.35,-25.4775c1.51844,-0.44344 2.53969,-1.86781 2.4725,-3.44c-0.01344,-0.48375 0,-0.72562 0,-0.7525c0,-9.55406 7.64594,-17.2 17.2,-17.2c4.78375,0 9.1375,1.92156 12.255,5.0525c0.81969,0.79281 1.98875,1.14219 3.10406,0.90031c1.12875,-0.24188 2.05594,-1.02125 2.48594,-2.08281c5.41531,-14.62 19.47094,-25.155 36.0125,-25.155c21.23125,0 38.3775,17.15969 38.3775,38.3775c0,1.57219 -0.12094,3.1175 -0.3225,4.73c-0.12094,0.9675 0.17469,1.935 0.80625,2.67406c0.63156,0.73906 1.55875,1.16906 2.52625,1.19594h0.215c13.15531,0 23.7575,10.60219 23.7575,23.7575c0,13.15531 -10.60219,23.7575 -23.7575,23.7575h-31.2825c-1.23625,-0.01344 -2.39187,0.63156 -3.02344,1.70656c-0.61813,1.075 -0.61813,2.39187 0,3.46687c0.63156,1.075 1.78719,1.72 3.02344,1.70656h31.2825c16.8775,0 30.6375,-13.76 30.6375,-30.6375c0,-15.65469 -12.01312,-28.09781 -27.1975,-29.885c0.05375,-0.83312 0.215,-1.6125 0.215,-2.4725c0,-24.94 -20.3175,-45.2575 -45.2575,-45.2575zM86,74.2825l-2.4725,2.365l-17.2,17.2c-1.37062,1.37063 -1.37062,3.57438 0,4.945c1.37063,1.37063 3.57438,1.37063 4.945,0l11.2875,-11.2875v60.415c-0.01344,1.23625 0.63156,2.39188 1.70656,3.02344c1.075,0.61813 2.39187,0.61813 3.46687,0c1.075,-0.63156 1.72,-1.78719 1.70656,-3.02344v-60.415l11.2875,11.2875c1.37063,1.37063 3.57438,1.37063 4.945,0c1.37063,-1.37062 1.37063,-3.57437 0,-4.945l-17.2,-17.2z"></path>
                            </g>
                        </g>
                    </svg>
                
                <header class="font-medium text-xl md:text-lg">
                    Drag & drop to upload file
                </header>
                <span class="text-xl font-medium my-4 md:text-lg">OR</span>
                <button class="bg-yellow-700 text-white md:text-xs">
                    Browse File
                </button>
                <input ref="" type="file" hidden />
            </div>
            <div class="w-1/2 px-4">
                <table class="w-full">
                    <tr class="text-xl font-semibold">
                        <td>
                            File Name
                        </td>
                        <td>
                            Status
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Vignmesh
                        </td>
                        <td>
                            Success 
                        </td>
                    </tr>
                </table>
            </div>

        </div>
        <button @click="$emit('cancel')" class="float-right text-semibold text-yellow-600 border-2 px-8 py-2 rounded-lg border-yellow-600
        hover:bg-yellow-600 hover:text-white">
            Close
        </button>

    </div>
</div>
</template>

<script>
export default {
    emits: ["cancel"]


}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.drag-area {
    background: #fff9f2;
    font-family: "Poppins", sans-serif;
}

.drag-area.active {
    border: 2px solid #fff;
}

.drag-area button {
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    /* background: #fff; */
    /* color: #5256ad; */
    border-radius: 5px;
    cursor: pointer;
}

.drag-area img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    border-radius: 5px;
}

button:hover{
  /* background: #2E94E3; */
  box-shadow: 0 0 30px #fab60a;
}

td, tr {
  text-align: left;
  padding: 8px;
}

</style>
