<template>
<div class="h-auto flex flex-col">
    <Navbar />
    <div class="w-full flex flex-col lg:flex-row justify-center items-center gap-4 lg:gap-8 mt-24">
        <p class="w-full lg:w-6/12 flex flex-col">
            <span class="text-3xl md:text-3xl text-center font-bold text-blue-600">"Zero Knowledge Cloud"</span>
            <span class="text-xl lg:text-2xl text-center  font-normal text-blue-900">That means zero worries about your data privacy.</span>
            <span class="text-xl lg:text-2xl text-center  font-normal text-blue-900">Welcome to the age of fearless collaboration!</span>
        </p>
        <a href="/dashboard" class="relative w-5/12 lg:w-1/12 text-center bg-green-300 h-10 top-2 mt-10 lg:mt-0">
            <div class="absolute -top-2 -left-2 w-full lg:w-full h-full bg-white text-center px-4 py-1 border-2 border-green-600 font-semibold text-green-600">
                Getting started
            </div>
        </a>
    </div>
    <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 p-2 md:p-8">
            <img src="../../assets/server_status.svg" alt="">
        </div>
        <div class="w-full lg:w-1/2 flex flex-col justify-center gap-2">
            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/fluency/96/4a90e2/hdd.png" alt="" />
                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        5 GB
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Get 5 GB of free cloud storage when you sign up.
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/fluency/96/4a90e2/share.png" alt="" />
                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        Contribute and Share
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Create your files and share them with peers from anywhere,anytime.
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center  items-center">
                    <img src="https://img.icons8.com/color/96/4a90e2/connection-sync--v1.png" alt="" />
                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        Keep in Sync
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Always available. Every platform and every device.
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 flex flex-col justify-center gap-2">
            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/grand-master-key.png" alt="" />

                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        Encrypted
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        End-to-end encrypted data transmission. Don't take our word for it; bring your own encryption keys if you wish.
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/code.png" alt="" />

                </div>
                <div class="w-10/12 flex flex-col justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        The Web is your workplace.
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        On-the-fly code editing and collaboration available with our ultimate web IDE browser.
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/nolan/96/time-card.png" alt="" />

                </div>
                <div class="w-10/12 flex flex-col  justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        Time machine for your files
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Every version of your files will be kept safe and tidy in-place.
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full lg:w-1/2 p-2 md:p-8">
            <img src="../../assets/4867780.jpg" alt="">
        </div>
    </div>

    <div class="w-full lg:w-8/12 self-center mt-10 flex flex-col md:flex-row justify-center p-4 lg:p-10">
        <div class="w-full lg:w-1/2 p-2 md:p-8">
            <img src="../../assets/safe.svg" alt="">
        </div>
        <div class="w-full lg:w-1/2 flex flex-col justify-center gap-2">
            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center items-center">
                    <img src="https://img.icons8.com/offices/96/4a90e2/lock-2.png" alt="" />

                </div>
                <div class="w-10/12 flex flex-col  justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        Safekeeping
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        Complete data protection with Zero-Knowledge implementation.
                    </div>
                </div>
            </div>

            <div class="w-full flex flex-row">
                <div class="w-2/12 p-0 lg:p-2 flex justify-center  items-center">
                    <img src="https://img.icons8.com/fluency/144/4a90e2/microsoft-tips.png" alt="" />

                </div>
                <div class="w-10/12 flex flex-col  justify-center">
                    <div class="flex px-4 py-1 justify-start items-center font-semibold text-gray-700 text-xl">
                        Get creative
                    </div>
                    <div class="flex px-4 py-1 justify-start items-center font-normal text-gray-700 text-md">
                        View and edit more than 30 file types.
                    </div>
                </div>
            </div>

        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'About',
    components: {
        Navbar,
        Footer,
    }
}
</script>

<style scoped>

</style>
