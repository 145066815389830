<template>
  <Navbar />
  <div class="h-screen flex flex-row justify-center items-center">
    <h1 class="sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
      This is Scholarly articles page
      </h1>
  </div>
  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Navbar,
    Footer,
  }
} 
</script>

<style scoped>

</style>