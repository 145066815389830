import Velocity from 'velocity-animate';

export default {
    methods: {
        // File directory in zkloud page
        fileTreeBeforeEnter: function (el) {
            el.style.opacity = 0
            el.style.height = 0
        },
        fileTreeEnter: function (el, done) {

            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 1,
                    height: '3rem'
                }, {
                    complete: done
                }
                )

            }, delay)
        },
        fileTreeLeave: function (el, done) {
            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 0,
                    height: 0
                }, {
                    complete: done
                }
                )
            }, delay)
        },
        // For bot page list articles
        botBeforeEnter: function (el) {
            el.style.opacity = 0
            el.style.height = 0
        },
        botEnter: function (el, done) {

            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 1,
                    height: '5rem'
                }, {
                    complete: done
                }
                )

            }, delay)
        },
        botLeave: function (el, done) {
            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 0,
                    height: 0
                }, {
                    complete: done
                }
                )
            }, delay)
        },
        // For Zkloud file list
        fileListBeforeEnter: function (el) {
            el.style.opacity = 0
            el.style.height = 0
        },
        fileListEnter: function (el, done) {

            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 1,
                    height: '3rem'
                }, {
                    complete: done
                }
                )

            }, delay)
        },
        fileListLeave: function (el, done) {
            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 0,
                    height: 0
                }, {
                    complete: done
                }
                )
            }, delay)
        },
        // For Zkloud grid view
        gridViewBeforeEnter: function (el) {
            el.style.opacity = 0
            el.style.width = 0
        },
        gridViewEnter: function (el, done) {

            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 1,
                    width: '8.5rem'
                }, {
                    complete: done
                }
                )

            }, delay)
        },
        gridViewLeave: function (el, done) {
            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el, {
                    opacity: 0,
                    width: 0
                }, {
                    complete: done
                }
                )
            }, delay)
        }
    }
}