import axios from 'axios';
// Things to do in future
// error handling 

const state = {
    todos: "null",
    attrs:[ {
            key: 'today',
            bar: 'pink',
            dates: [],
        },
        {
            highlight: {
                color: 'green',
                fillMode: 'outline'
            },
            dates: new Date(),
        },
    ],

};

const getters = {
    allTodos: (state) => state.todos,
    attrs: (state) => state.attrs
};

const actions  = {
    // Getting all todos from API
    async fetchTodos({ commit }, data) {
        const response = await axios.get(`https://api.zealbots.com/api/v1/calendar/view/?user=${data.user}&Key=${data.key}&format=json`)
        if (response && response.data.data){
            commit('setTodos', response.data.data)
        } else if(response && response.data.error) {
            let message = 'Nothing to show'
            commit('setTodos', message)
        } else if (response == undefined) { 
            let message = "500 Error"
            commit('setTodos', message)
        } else {
            let message = 'Please check your internet connection'
            commit('setTodos', message)
        }
    },

    // Add new Todos
    async addTodo({ commit }, data){
        const response = await axios.post(`https://api.zealbots.com/api/v1/calendar/new/?user=${data.user}&Key=${data.key}&format=json`, data.obj)
        console.log(response)
        let dataDup = {
            'name': data.obj.name,
            date: data.obj.date,
            'content': data.obj.type,
            'status': data.status
        }
        commit("newTodo", dataDup)
    },

    // Delete todo
    async deleteTodo({ commit }, data){
        await axios.delete(`https://api.zealbots.com/api/v1/calendar/delete/${data.obj.id}/?user=${data.user}&Key=${data.key}&format=json`, data.obj)
        commit("deleteTodo", data.obj)
    },

    // status toggle
    async checkboxToggle({ commit }, data){
        data.obj.status = !data.obj.status
        await axios.patch(`https://api.zealbots.com/api/v1/calendar/edit/${data.obj.id}/?Key=${data.key}&format=json`, data.obj)
        commit("statusUpdate", data.obj)
    },

    // List fetch for todo marks in calender
    async markTodo({ commit }){
        commit("updateMark")
    },

    updateTodayDate({commit}, date){
        commit('updateDate', date)
    },

    todoCacheClear({commit}){
        commit('todoCacheClear')
    }

};

const mutations = {
    setTodos: (state, todos) => (state.todos = todos),
    newTodo: (state, todo) => {
        if (state.todos != Object){ state.todos = new Array(); }
        state.todos.push(todo)
    },
    deleteTodo: (state, data) => state.todos = state.todos.filter((todo) => todo.id !== data.id),
    statusUpdate: (state, data) => state.todos.status = !data.status,
    updateMark: (state) => {
        state.attrs[0].dates.length = 0;
        if(typeof(state.todos) == 'object'){
            state.todos.forEach((element) => state.attrs[0].dates.push(element.date)); } 
    },
    updateDate: (state, date) => state.attrs[1].dates = date,
    todoCacheClear: (state) => {
        state.attrs.dates = new Array()
        state.todos="null"
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}