<template>
<div class="">
    <Navbar />
    <div class=" flex justify-center h-screen  ">
        <div class=" flex justify-center mt-20  w-11/12">
            <!--actual component start-->
            <div class=" w-full">
                <ul class="hidden md:flex  flex-row justify-center  mb-20 ">
                    <div class=" text-center w-full" v-for="(tab, index) in tabs" :key="index">

                        <li v-if="activeTab===index " class=" cursor-pointer py-2  border-b-8 text-indigo-900 font-bold border-indigo-900" @click="activeTab = index ">{{tab}} </li>

                        <li v-else class=" lg:w-full cursor-pointer py-2 text-gray-500 border-b-8" @click="activeTab = index">{{tab}}</li>

                    </div>
                </ul>

                <div class="w-800 h-72 text-center  ">
                    <div v-if="activeTab===0" class="  animate__animated  animate__fadeInTopLeft">
                        <p v-if="Create_Botname == botzz" class="text-red-500">{{message}}</p>
                        <span class="text-xl font-bold font-sans text-indigo-900">
                            Hi ! I am your research Assistant. Give me a name.
                        </span>

                        <div class="m-10">

                            <div class=" flex flex-row justify-center  ">

                                <div class="">
                                    <input type="text" v-model="Create_Botname" name="bot_name" class="px-4 py-2  border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200" placeholder="Name" size="15" maxlength="15">
                                </div>
                            </div>

                        </div>

                    </div>

                    <div v-if="activeTab===1" class=" animate__animated  animate__fadeInTopLeft">
                        <span class="text-xl font-bold font-sans text-indigo-900">

                            Wow!
                            <span class="text-red-600">{{Create_Botname}}
                            </span>
                            sounds cool. I can note down your preferences in Keywords?
                        </span>

                        <div class="m-10  ">
                            <div class="flex flex-row justify-center">

                                <input type="text" class="w-1/2 px-20 py-2 border border-gray-300  rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-300  " placeholder="Eg. Protien, Machine learning, Agronomy.. etc " size="50" maxlength="60">

                                <button class=" ml-5 text-indigo-800 font-bold b border-red rounded focus:border-transparent hover:bg-blue-500 hover:text-white hover:shadow focus:outline-none" v-on:click="seen = !seen">Advanced Configuration</button>

                            </div>

                        </div>

                    </div>
                    <div v-if="activeTab===2" class=" animate__animated  animate__fadeInTopLeft">
                        <span class="text-xl font-bold font-sans text-indigo-900">
                            Kindly select your preference for recommendation of article
                        </span>

                        <div class="mt-10 flex flex-col items-center justify-center text-sm md:text-base lg:text-xl">
                            <div class="flex flex-col">
                                <label class="inline-flex items-center mt-3">
                                    <input type="checkbox" class="form-checkbox h-5 w-5 " checked><span class="ml-2 text-gray-700">Latest article only</span>
                                </label>

                                <label class="inline-flex items-center mt-3">
                                    <input type="checkbox" class="form-checkbox h-5 w-5  " checked><span class="ml-2 text-gray-700">Historical records too</span>
                                </label>

                                <label class="inline-flex items-center mt-3">
                                    <input type="checkbox" class="form-checkbox h-5 w-5  " checked><span class="ml-2 text-gray-700">Do you want to send the recommendations through mail?</span>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div v-if="activeTab===3" class="  animate__animated  animate__fadeInTopLeft">
                        <div class="">

                            <h1 class="text-center font-bold text-indigo-900 text-xl">Here is your <span class="text-red-500"> {{Create_Botname}}'s </span> configuration,</h1>

                            <div v-if="keywords || authors || institutes" class="mt-2 p-2 flex flex-col md:flex-col lg:flex-row justify-between border">

                                <div class=" p-2  text-left w-6/12 ">
                                    <h1 class="text-center font-bold text-indigo-700">INCLUDE</h1>
                                    <div v-if="keywords" class="">
                                        <p class="py-1 text-sm break-words">Keywords :</p>
                                        <div class="px-2 flex flex-wrap gap-2 ">
                                            <span class="  text-xs" v-for="i in keywords.split(',')" :key="i">
                                                {{i}}
                                            </span>

                                        </div>
                                    </div>

                                    <div v-if="journals">
                                        <p class="py-1">Journals : </p>
                                        <div class="px-2 flex flex-wrap gap-2">
                                            <span class="text-xs" v-for="i in journals.split(',')" :key="i">
                                                {{i}}
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="authors">

                                        <p class="py-1">Authors : </p>
                                        <div class="px-2 flex flex-wrap gap-2">
                                            <span class=" text-xs" v-for="i in authors.split(',')" :key="i">
                                                {{i}}
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="institutes">
                                        <p class="py-1">Institutes : </p>
                                        <div class="px-2 flex flex-wrap gap-2">
                                            <span class=" text-xs" v-for="i in institutes.split(',')" :key="i">
                                                {{i}}
                                            </span>

                                        </div>
                                    </div>

                                </div>

                                <div class=" p-2 w-6/12  text-left  ">
                                    <h1 class="text-center font-bold text-indigo-700">EXCEPT</h1>

                                    <div v-if="nokeywords">
                                        <p class="py-1">Keywords : </p>
                                        <div class="px-2 flex flex-wrap gap-2">
                                            <span class=" text-xs" v-for="i in nokeywords.split(',')" :key="i">
                                                {{i}}
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="nojournals">
                                        <p class="py-1">Journals : </p>
                                        <div class="px-2 flex flex-wrap gap-2">
                                            <span class=" text-xs" v-for="i in nojournals.split(',')" :key="i">
                                                {{i}}
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="noauthors">
                                        <p class="py-1">Authors : </p>
                                        <div class="px-2 flex flex-wrap gap-2">
                                            <span class=" text-xs" v-for="i in noauthors.split(',')" :key="i">
                                                {{i}}
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="noinstitutes">
                                        <p class="py-1">Institutes : </p>
                                        <div class="px-2 flex flex-wrap gap-2">
                                            <span class=" text-xs" v-for="i in noinstitutes.split(',')" :key="i">
                                                {{i}}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="py-20 flex gap-4 justify-center">

                                <button type="submit" class="py-2 px-4 border rounded-md border-blue-600 text-indigo-900 cursor-pointer uppercase text-sm font-bold hover:bg-blue-500 hover:text-white hover:shadow" @click="activeTab--" v-if="activeTab>0 && activeTab<4">Back</button>
                                <a href="/dashboard">
                                    <button @click="BotCreation()" type="submit" class="py-2 px-4 border rounded-md border-blue-600 text-indigo-900 cursor-pointer uppercase text-sm font-bold hover:bg-blue-500 hover:text-white hover:shadow">
                                        Done
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="flex gap-4 justify-center  p-4">
                        <button type="submit" class="py-2 px-4 border rounded-md border-blue-600 text-indigo-900 cursor-pointer uppercase text-sm font-bold hover:bg-blue-500 hover:text-white hover:shadow" @click="activeTab--" v-if="activeTab>0 && activeTab<3">Back</button>
                        <button class="py-2 px-4 border rounded-md border-blue-600 text-indigo-900 cursor-pointer uppercase text-sm font-bold hover:bg-blue-500 hover:text-white hover:shadow" @click="activeTab++" v-if="activeTab<tabs.length-1 && Create_Botname != botzz">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="seen" class="mt-40  w-full  flex-row  absolute  flex bg-black bg-opacity-70 justify-center items-center inset-0 z-50 outline-none overflow-x-hidden overflow-y-hidden">

            <div class="h-8/12 bg-gray-50 justify-center border box-border">
                <div class="">
                    <div class="">
                        <div class="flex flex-row justify-center">
                            <h5 class="m-2 text-indigo-900 font-semibold text-center">Advanced Configuration</h5>
                        </div>

                        <div class=" text-left text-sm">
                            <div class="flex flex-row justify-around ">
                                <div class="py-2">
                                    <label class="text-xs text-gray-500">Keywords</label>
                                    <div class=" ">
                                        <input v-model="keywords" class=" border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                                <div class=" py-2">
                                    <label class="text-xs text-gray-500">Keywords (Do not include)</label>
                                    <div>
                                        <input v-model="nokeywords" class="bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-row justify-around">
                                <div class="py-2">
                                    <label class="text-xs text-gray-500">Journals</label>
                                    <div>
                                        <input v-model="journals" class=" bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                                <div class="py-2">
                                    <label class="text-xs text-gray-500 ">Journals (Do not include)</label>
                                    <div>
                                        <input v-model="nojournals" class="bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-row justify-around">
                                <div class="py-2">
                                    <label class="text-xs text-gray-500">Authors</label>
                                    <div>
                                        <input v-model="authors" class="bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                                <div class="py-2">
                                    <label class="text-xs text-gray-500">Authors (Do not include)</label>
                                    <div>
                                        <input v-model="noauthors" class="bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-row justify-around">
                                <div class="py-2">
                                    <label class="text-xs text-gray-500">Institutes</label>
                                    <div>
                                        <input v-model="institutes" class="bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                                <div class="py-2">
                                    <label class="text-xs text-gray-500">Institutes (Do not include)</label>
                                    <div>
                                        <input v-model="noinstitutes" class="bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="text" autocomplete="off">
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-row justify-around ">

                                <div class="py-2 text-right">
                                    <label class=" text-xs text-gray-500">Number of Articles to send</label>
                                    <div class="flex justify-center">
                                        <input class="w-6/12 bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="number" min="0" max="100" autocomplete="off">
                                    </div>
                                </div>

                                <div class="py-2 text-center">
                                    <label class="text-xs text-gray-500">Re-run every* days</label>
                                    <div class="flex justify-center">
                                        <input class="w-6/12 bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="number" min="0" max="100" autocomplete="off">
                                    </div>
                                </div>

                                <div class="py-2 text-left">
                                    <label class=" text-xs text-gray-500">Send updates every* days</label>
                                    <div class="flex justify-left">
                                        <input class=" w-6/12 bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="number" min="0" max="100" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class=" py-4 text-center">
                                <label class="text-xs text-gray-500">Journal Impact Factor(0-100)</label>
                                <div class="py-2 flex flex-row justify-center">
                                    <input class="m-2 w-1/4 bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" min="0" max="99" type="number" name="min_year">
                                    <input class="m-2 w-1/4 bg-white border border-blue-200 focus:ring-2 focus:ring-blue-600" type="number" name="max_year" min="1" max="100">

                                </div>
                            </div>
                            <div class="flex gap-4 justify-center  p-4">
                                <div>
                                    <button class="py-1 px-2 border rounded-md border-blue-600 text-indigo-900 cursor-pointer uppercase text-sm font-bold hover:bg-blue-500 hover:text-white hover:shadow" @click="seen = !seen">Submit</button>
                                </div>
                                <div>
                                    <button class="py-1 px-2 border rounded-md border-blue-600 text-indigo-900 cursor-pointer uppercase text-sm font-bold hover:bg-blue-500 hover:text-white hover:shadow" @click="removedata() ">
                                        Close
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import axios from "axios";
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Navbar,
        Footer,
    },
    data: () => ({
        activeTab: 0,
        tabs: [
            "Assign a bot",
            "Give a Keyword",
            "Your preference",
            "Finalize",
        ],
        Create_Botname: "",
        keywords: "",
        journals: "",
        authors: "",
        institutes: "",
        nokeywords: "",
        nojournals: "",
        noauthors: "",
        noinstitutes: "",
        seen: false,
        botzz: "",
        message: "",
    }),
    mounted() {},
    methods: {

        removedata() {
            this.seen = !this.seen
            this.keywords = "";
            this.journals = "";
            this.authors = "";
            this.institutes = "";
            this.nokeywords = "";
            this.nojournals = "";
            this.noauthors = "";
            this.noinstitutes = "";

        },

        BotCreation() {
            var botName = this.Create_Botname;
            var descriptionValue = this.keywords;
            var PosKeywords = this.keywords.split(",");
            var NegKeywords = this.nokeywords.split(",");
            var PosJournals = this.journals.split(",");
            var NegJournals = this.nojournals.split(",");
            var PosAuthors = this.authors.split(",");
            var NegAuthors = this.noauthors.split(",");
            var PosInstitutes = this.institutes.split(",");
            var NegInstitutes = this.noinstitutes.split(",");

            var UserID = this.$store.getters.StateUserInfo.id;
            var tock = this.$store.getters.StateUserInfo.key;
            var currentdate = new Date();
            var date = currentdate.getFullYear() + "-" +
                (currentdate.getMonth() + 1) + "-" +
                currentdate.getDate();
            var datetime = currentdate.getFullYear() + "-" +
                (currentdate.getMonth() + 1) + "-" +
                currentdate.getDate() + "T" +
                currentdate.getHours() + ":" +
                currentdate.getMinutes() + ":" +
                currentdate.getSeconds() + "Z";

            if (this.Create_Botname == "") {
                this.activeTab = 0;
                this.message = 'Please Enter The Bot Name'

            } else {

                if (UserID != null) {

                    var dataBot = {
                        bot_name: botName,
                        project_name: "",
                        slug: "",
                        description: descriptionValue,
                        pos_keywords: PosKeywords,
                        pos_key_weight: null,
                        neg_keywords: NegKeywords,
                        neg_key_weight: null,
                        pos_journals: PosJournals,
                        pos_journal_weight: null,
                        neg_journals: NegJournals,
                        neg_journal_weight: null,
                        pos_authors: PosAuthors,
                        pos_author_weight: null,
                        neg_authors: NegAuthors,
                        neg_author_weight: null,
                        pos_institute: PosInstitutes,
                        pos_institue_weight: null,
                        neg_institute: NegInstitutes,
                        neg_institue_weight: null,
                        year_from: null,
                        year_until: null,
                        pubdate_weight: null,
                        impact_from: null,
                        impact_until: null,
                        impact_weight: null,
                        created_at: date,
                        updated_at: datetime,
                        last_run: datetime,
                        use_anchor: false,
                        top_n: 5,
                        run_interval: 1,
                        update_interval: 1,
                        active: true,
                        user: UserID
                    }

                    console.log("dataBot", dataBot);

                    axios.post(`https://api.zealbots.com/api/v1/zbot/new/?Key=${tock}`, dataBot)
                        .then(response => {
                            if (response && response.data) {
                                console.log(response)
                                console.log(response.data)
                            } else {
                                console.log(response)
                                console.log(response.data)
                            }

                        })

                }
            }

        },
    }
};
</script>
