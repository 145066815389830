<template>
<div class="h-auto flex flex-col">
    <Navbar />
    <div v-if="isAuthenticated && superuser == true " class="flex flex-col m-2 mt-20">
        <h3 class="block text-xl text-center text-blue-700 font-semibold mb-3">Downloads Status Page</h3>
        <div class="flex flex-col lg:flex-row">
            <div class="w-full lg:w-1/2">
                <div class="flex flex-col items-center p-2 h-1/3 lg:p-6">
                    <div class="flex flex-col items-center justify-center pb-12">
                        <h3 class="font-bold text-lg text-green-600">Daily and total downloads</h3>
                        <!-- <p class="text-sm text-gray-500"></p> -->
                        <div id="box">
                            
                                <input type="date" v-model="formData.startDate" >

                                <input type="date" v-model="formData.endDate">
                            
                            <button @click="getDownloadChartData" class="w-auto px-2 ring bg-white ring-blue-500 ring-offset-2 rounded-sm text-blue-600 py-1 font-bold m-3 transform hover:scale-105 motion-reduce:transform-none">
                                search
                            </button>
                            <!-- <span>{{form.date2}}</span> -->
                        </div>

                    </div>

                    <apexchart width="100%" type="line"   ref="realtimeChart" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
            <div class="w-full lg:w-1/2">
                <div class="flex flex-col items-center p-2 h-full lg:p-6">
                    <div class="flex flex-col items-center justify-center pb-12">
                        <h3 class="font-semibold text-lg text-green-600">Browser Downloads</h3>
                        <!-- <p class="text-sm text-gray-700"></p> -->
                    </div>

                    <apexchart type="pie" width="700" :options="chartOptions1" :series="series1"></apexchart>

                    <!-- <apexchart type="line" :options="chartOptions2" :series="series2"></apexchart> -->
                </div>
            </div>
        </div>
    </div>
    <div class="w-full flex flex-row justify-center items-center bg-blue-50 mx-auto p-2">
        <div class="w-full lg:w-10/12 flex flex-col md:flex-row justify-center p-5 lg:p-10 gap-4">
            <div class="w-full md:6/12 lg:w-5/12 flex flex-col">
                <h1 class="text-3xl text-blue-600 font-bold">Get started with Zealbots Desktop!</h1>
                <p class="mt-8 text-md lg:text-md text-gray-800 text-justify">
                    &emsp; With our cloud powered Zealbots desktop application you can store, organize, share and cite publications.
                    We will recommend you articles, events, funding opportunities, technologies or any information that will inspire
                    and accelerate your research vision. You can evaluate our recommendations and our Zealbots will adapt quickly to
                    fetch that which matters to you the most with consistency and so will be your progress.
                </p>
            </div>
            <div class="w-full md:6/12 lg:w-4/12 flex items-center">
                <img class="img_fluid opacity-80" src="../assets/desktop.png" />
            </div>
        </div>
    </div>

    <div class="mt-20 grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 justify-center p-2 w-full lg:w-3/5 mx-auto">
        <!--first  -->
        <div class="flex flex-row p-2 bg-white shadow-md hover:shadow-xl rounded-md hover:bg-gray-50 text-gray-600 hover:text-gray-800">
            <div class="w-30 my-auto m-3">
                <img src="https://img.icons8.com/ios-glyphs/90/000000/mac-os.png" class="opacity-50" />
            </div>

            <div class="w-full">
                <div class="p-4 text-gray-800">
                    <span>MacOS</span>
                </div>

                <div class="p-4">
                    <a href="https://www.zealbots.com/download/macos" class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white">Download
                    </a>
                </div>
            </div>
        </div>

        <!-- second -->
        <div class="flex flex-row p-2 bg-white shadow-md hover:shadow-xl rounded-md hover:bg-gray-50 text-gray-600 hover:text-gray-800">
            <div class="w-30 my-auto m-3">
                <img src="https://img.icons8.com/ios-glyphs/60/000000/windows-10.png" class="opacity-50" />
            </div>
            <div class="w-full">
                <div class="p-4 text-gray-800">
                    <span>Windows </span>
                </div>
                <div class="flex flex-row ">
                    <div class="p-4">
                        <a class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" href="https://www.zealbots.com/download/windows/win_32">
                            Download 32bit
                        </a>
                    </div>
                    <div class="p-4">
                        <a class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" href="https://www.zealbots.com/download/windows/win_64">
                            Download 64bit
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Three -->
        <div class="flex flex-row p-2 bg-white shadow-md hover:shadow-xl rounded-md hover:bg-gray-50 text-gray-600 hover:text-gray-800">
            <div class="w-30 h-20 my-auto ">
                <img src="https://img.icons8.com/ios-glyphs/60/000000/ubuntu--v2.png" class="opacity-50" />
            </div>
            <div class="w-full">
                <div class="p-4 text-gray-800">
                    <span> Ubuntu / Debian</span>
                </div>

                <div class="flex flex-row ">
                    <div class="p-4">
                        <a class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" href="https://www.zealbots.com/download/linux/debian_32">
                            Download 32bit
                        </a>
                    </div>
                    <div class="p-4">
                        <a class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" href="https://www.zealbots.com/download/linux/debian_64">
                            Download 64bit
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- fourth -->
        <div class="flex flex-row p-2 bg-white shadow-md hover:shadow-xl rounded-md hover:bg-gray-50 text-gray-600 hover:text-gray-800">
            <div class="w-30 h-20 my-auto ">
                <img src="https://img.icons8.com/ios-glyphs/60/000000/red-hat.png" class="opacity-50" />
            </div>
            <div class="w-full ">
                <div class="p-4 text-gray-800">
                    <span> Redhat / Fedora / openSuse Linux </span>
                </div>
                <div class="flex flex-row ">
                    <div class="p-4">
                        <a class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" href="https://www.zealbots.com/download/linux/rpm_32">
                            Download 32bit
                        </a>
                    </div>
                    <div class="p-4">
                        <a class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" href="https://www.zealbots.com/download/linux/rpm_64">
                            Download 64bit
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--slide 2  -->
    <div class="md:mt-20 w-full flex flex-col md:flex-row justify-center gap-4 p-4">
        <div class="w-full lg:w-1/3 flex flex-col items-center justify-center">
            <h1 class="text-3xl text-blue-600 font-bold">Updates On Time</h1>
            <p class="mt-5 flex-wrap text-justify">
                Worried about staying updated in your research zone? We at Zealbots will help you keep that
                worry at bay by giving you a shout via mails and notification if there is something new in your
                research zone in your preferred timings and zone.
            </p>
        </div>
        <div class="w-full lg:w-1/3 p-10">
            <img class="img_fluid opacity-80" src="https://www.zealbots.com/static/site/img/desktop_collection_wireframe.svg" />
        </div>
    </div>

    <!-- slide 3 -->
    <div class="md:mt-20 w-full flex flex-col md:flex-row  flex-reverse justify-center gap-4 p-4">
        <div class="w-full lg:w-1/3 p-10">
            <img class="img_fluid opacity-80" src="https://www.zealbots.com/static/site/img/zealbots_wireframe.svg" />
        </div>
        <div class="w-full lg:w-1/3 flex flex-col justify-center items-center">
            <h1 class="text-3xl text-blue-600 font-bold">Article Collections</h1>
            <p class="mt-5 flex-wrap text-justify">
                Store the needful references from our Zealbots recommended data into your collections.
                Add labels, take notes and annotate PDF on stored articles. Thus we ensure you a dedicated space
                and collection for each of your project that can be synced across all your devices to stay organized
                and productive.
            </p>
        </div>
    </div>

    <!-- slide 4 -->
    <div class="p-4 md:mt-20 w-full flex flex-col md:flex-row justify-center gap-4 bg-blue-50 bg-opacity-40">
        <div class="w-full lg:w-1/3 flex flex-col justify-center items-center">
            <h1 class="text-3xl text-blue-600 font-bold">Citation Manager</h1>
            <p class="mt-5 flex-wrap text-justify">
                Cite articles directly from your collection in your favorite writing tools.
                Built in tools integrated for MS word, LibreOffice and Latex.
                Import citations from websites and other reference managers.
            </p>
        </div>
        <div class="w-full lg:w-1/3 p-10">
            <img class="img_fluid opacity-80" src="https://www.zealbots.com/static/site/img/zealbots_wireframe.svg" />
        </div>
    </div>
    {{chartOptions.categories}}

    <!-- slide 5 -->
    <div class="md:mt-20 w-full flex flex-col md:flex-row justify-center gap-4 p-4">
        <div class="w-full lg:w-1/3 p-10">
            <img class="img_fluid opacity-80" src="https://www.zealbots.com/static/site/img/zealbots_wireframe.svg" />
        </div>
        <div class="w-full lg:w-1/3 flex flex-col justify-center items-center">
            <h1 class="text-3xl text-blue-600 font-bold">On Sync</h1>
            <p class="mt-5">
                Get a vast and secure storage space where you can store your research data,
                articles collections, citation files safely via cloud sync for your innovation.
            </p>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios';
import {reactive} from 'vue'
import {
    mapGetters
} from 'vuex'

export default {
    name: 'Download',
    components: {

        Navbar,
        Footer

    },
    setup() {

        const series = reactive([{
            name: 'Daily downloads',
            type: 'column',
            data: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"]
        }, {
            name: 'Total downloads',
            type: 'line',
            data: ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"]
        }])

        const chartOptions = reactive({
            chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 4]
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                    // position: 'bottom'
                },
                xaxis: {
                    type: 'category',
                    crosshair: !0
                },
                yaxis: [{
                    title: {
                        text: 'Daily downloads',
                    },

                }, {
                    opposite: true,
                    title: {
                        text: 'Total downloads'
                    }
                }]
        })
        return {
            series,
            chartOptions
        }
    },

    computed: mapGetters(["isAuthenticated"]),
    data() {
        return {
            superuser : "",
            formData: {
                startDate: "",
                endDate: ""
            },
            series1: [44, 55, 13, 43, 22],
            chartOptions1: {
                chart: {
                    width: 200,
                    type: 'pie',
                },
                legend: {
                    position: 'bottom'
                },
                labels: ["Firefox", "chrome", "Safari", "internet_explorer", "other_browser"],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

        }
    },
    methods: {
        formatDateForChart(arr) {
            let result = new Array();
            for (let i in arr) {
                let wholeDate = new Date(arr[i])

                let date = wholeDate.getDate() > 10 ? wholeDate.getDate().toString() : `0${wholeDate.getDate()}`
                let month = wholeDate.toString().slice(4, 7)
                let year = wholeDate.getFullYear()

                result.push(`${date} ${month} ${year}`)
            }
            return result;
        },

        async getPieChartData() {
            let response = await axios.get(`https://api.zealbots.com/api/v1/browser/?Key=${this.$store.getters.StateUserInfo.key}&format=json`)
            // console.log(response.data)
            let browser = ["Firefox", "chrome", "Safari", "internet_explorer", "other_browser"]
            if (response && response.data) {
                this.series1 = new Array()
                for (let i in response.data) {
                    if (browser.includes(i)) {
                        this.series1.push(response.data[i])
                    }
                }
                this.chartOptions1.labels = browser
            }
        },

        dateForInput (input){
            let month = input.getMonth()+1 < 10 ? `0${input.getMonth()+1}` : input.getMonth()+1
            let day = input.getDate() < 10 ? `0${input.getDate()}` : input.getDate()
            return `${input.getFullYear()}/${month}/${day}`
        },

        async getDownloadChartData() {

            let startDateFormat = new Date(this.formData.startDate)
            let endDateFormat = new Date(this.formData.endDate)
            const startDate = () => {
                let month = startDateFormat.getMonth() < 10 ? `0${startDateFormat.getMonth()}` : startDateFormat.getMonth()
                let day = startDateFormat.getDay() < 10 ? `0${startDateFormat.getDay()}` : startDateFormat.getDay()
                return `${startDateFormat.getFullYear()}/${month}/${day}`
            }

            const endDate = () => {
                let month = endDateFormat.getMonth() < 10 ? `0${endDateFormat.getMonth()}` : endDateFormat.getMonth()
                let day = endDateFormat.getDay() < 10 ? `0${endDateFormat.getDay()}` : endDateFormat.getDay()
                return `${endDateFormat.getFullYear()}/${month}/${day}`
            }

            console.log(endDate())
            let response = await axios.get(`https://api.zealbots.com/api/v1/download/month/${startDate()}/${endDate()}/?Key=${this.$store.getters.StateUserInfo.key}&format=json`)
            if (response) {
                this.series[0].data = response.data.daily
                this.series[1].data = response.data.total
                let chartLables = await this.formatDateForChart(response.data.date)
                console.log("chartLables", chartLables.length)
                this.$refs.realtimeChart.updateOptions({"labels": chartLables.reverse()})
            } else {
                console.log("some error")
            }
        },

    },

    created: function () {
        var user_id = this.$store.getters.ProfileDataInfo;
        this.superuser = user_id.is_superuser
        
        var ourDate = new Date();
        this.formData.startDate =  this.dateForInput(ourDate)

        ourDate.setDate(ourDate.getDate()-15)
        this.formData.endDate = this.dateForInput(ourDate)

        this.getDownloadChartData()
        this.getPieChartData()
    },
    mounted: function () {

    }
}
</script>

<style scoped>

</style>
