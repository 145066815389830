<template>
    <div class="px-8 py-2 bg-gray-100 w-full">
        <div>
            Notes:
        </div>
        <div v-if="notenotification" class="w-full mt-5">
            <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                <p class="text-white font-bold">{{messagebody}}</p>
                <font-awesome-icon @click="close()" class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
            </div>
        </div>
        <div class="w-full text-sm text-gray-500 flex flex-col md:flex-row justify-start items-center flex-wrap ">
        <!-- start note list container -->
            <div v-for="note in note_list" :key="note" class="w-5/6 md:w-auto">
                <div class="w-full flex flex-col border p-1 md:p-2 border-gray-300 bg-white text-black rounded-md mb-1">
                    <div class="w-full clearfix leading-tight">
                        <div class="float-left flex flex-row">
                            <span class="">
                                <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                                    <path d="M12 4a4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4 4 4 0
                                014-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4
                                8-4z" />
                                </svg>
                            </span>
                            <span class="ml-2 text-blue-400 text-semibold">{{ Username }}</span>
                        </div>
                        <div class="float-right">
                            <span class="text-blue-400 mr-3 bg-white text-semibold hover:text-blue-600 cursor-pointer" @click="noteEdit(note)">
                                Edit
                            </span>
                            <span class="text-red-400 mr-3 bg-white text-semibold hover:text-red-600 cursor-pointer" @click="noteDelete(note)">
                                Delete
                            </span>
                        </div>
                    </div>
                    <div class="w-full text-gray-500 text-normal ">
                        {{ note.notes.slice(0,20) }} <span @click="shownotes=!shownotes; noteid=note.id" class="cursor-pointer text-blue-400">.....More</span>
                        
                    </div>
                </div>
                
            </div>
            <div v-for="note in note_list" :key="note" class="absolute bg-white shadow-xl z-50">
                <div v-if="shownotes && noteid==note.id" >
                    <h1 class=" font-bold text-black p-2">Notes Details :</h1>
                <p  class=" flex flex-col  justify-center  h-full  text-black p-2 text-justify">{{note.notes}} </p>
                <span @click="shownotes=!shownotes" class="float-right text-right  m-4 p-2 text-white font-bold cursor-pointer hover:text-blue-500 hover:bg-gray-200 border bg-blue-500">Close</span>
                    
                </div>

            </div>
            <!-- create note container -->
            <div class="mb-8 w-full flex flex-wrap bg-grey-light mt-2">
                <div class="w-full">
                    <textarea class="w-full border-gray-300 rounded-md" name="" id="" cols="30" rows="4" v-model="note_content"></textarea>
                    <button v-if="editBtnClick" class="font-bold float-right px-2 py-1 bg-teal-300 text-teal-600 hover:text-white hover:bg-teal-700 border border-teal-500 rounded-md hover:shadow-lg outline-none" type="button" @click="noteCreate()">Save</button>
                    <button v-else class="font-bold float-right px-2 py-1 bg-teal-50 text-teal-600 hover:text-white hover:bg-teal-700 border border-teal-500 rounded-md hover:shadow-lg outline-none" type="button" @click="noteCreate()">Add</button>
                </div>
            </div>
        </div>
        <!-- <div v-if="notenotification" class="relative w-full mt-5">
            <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                <p class="text-white font-bold">{{messagebody}}</p>
                <font-awesome-icon @click="close()" class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
            </div>
        </div> -->
    </div>
</template>

<script>
import axios from "axios";
import { mapActions } from 'vuex';

export default {
    props: {
        note_list: { type: Array, default: () => [] },
        current: Number
    },
    data() {
        return {
            note_content: "",
            notenotification: false,
            messagebody: "",
            editBtnClick: false,
            currentnote: "",
            shownotes:false,
            noteid : "",
        }
    },
    methods: {
        ...mapActions(['getAllNotes']),
        close: function () {
            this.notenotification = false
        },
        noteCreate: async function () {
            console.log(this.current)
            var that = this
            var userInfo = this.$store.getters.StateUserInfo;
            var note_input = {
                article_id: this.current,
                user_key: userInfo.key
            };
            // console.log("created info: ",note_input)

            if (this.editBtnClick == false) {
                var sample_content = {}
                sample_content['user'] = userInfo.id
                sample_content['article'] = this.current
                sample_content['notes'] = this.note_content
                var note_create_url = `notes/new/?Key=${userInfo.key}`
                try {
                    axios.post(note_create_url, sample_content)
                        .then(function (response) {
                            if (response.status == 200 && !("error" in response.data)) {
                                console.log("log", response.data)
                                that.notenotification = true
                                that.getAllNotes(note_input)
                                that.messagebody = `note added successfully`
                            } else {
                                console.log("no article matched notes")
                                console.log(response.data)
                            }
                        });
                } catch (error) {
                    console.log("error:", error)
                } finally {
                    this.note_content = ""
                    setTimeout(function () {
                        that.notenotification = false;
                    }, 5000)
                }
            } else {
                var input_content = {}
                input_content['user'] = userInfo.id
                input_content['article'] = this.current
                input_content['notes'] = this.note_content
                var note_edit_url = `notes/edit/${this.currentnote}?Key=${userInfo.key}`
                try {
                    axios.put(note_edit_url, input_content)
                        .then(function (response) {
                            if (response.status == 200 && !("error" in response.data)) {
                                console.log("log", response.data)
                                that.notenotification = true
                                that.getAllNotes(note_input)
                                that.messagebody = `note updated successfully`
                            } else {
                                console.log("no article matched notes")
                                console.log(response.data)
                            }
                        });
                } catch (error) {
                    console.log("error:", error)
                } finally {
                    this.note_content = ""
                    that.editBtnClick = false
                    setTimeout(function () {
                        that.notenotification = false;
                    }, 5000)
                }
            }

        },
        noteEdit: function (note) {
            this.editBtnClick = true
            this.note_content = note.notes
            this.currentnote = note.id
        },
        noteDelete: function (note) {
            var that = this
            var userInfo = this.$store.getters.StateUserInfo;
            var sample_content = {}
            sample_content['user'] = userInfo.id
            sample_content['article'] = this.current
            sample_content['notes'] = this.note_content
            var note_api_url = `notes/edit/${note.id}?Key=${userInfo.key}`
            try {
                axios.delete(note_api_url, sample_content)
                    .then(function (response) {
                        console.log(response)
                        if (response && response.data == "") {
                            console.log("log", response.data)
                            that.notenotification = true
                            that.$store.commit('deleteNote', note.id)
                            that.messagebody = `note deleted successfully`
                        } else {
                            console.log("no article matched notes")
                            console.log(response.data)
                        }
                    });
            } catch (error) {
                console.log("error:", error)
            } finally {
                setTimeout(function () {
                    that.notenotification = false
                }, 5000)
            }
        }
    },
    computed: {
        Username: function () {
            if (this.$store.getters.StateUser) {
                return this.$store.getters.StateUser.split("@")[0];
            } else return "Current User:";
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>
