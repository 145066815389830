<template>
<!-- component -->
<div class="flex flex-row h-full w-screen bg-gray-200">
    <LeftNavigation selected='3' :isCiteman='true' :citeman_id='$route.params.id' />

    <main class="flex-1 bg-gradient-to-tr from-blue-200 via-indigo-200 to-pink-200 overflow-y-auto transition duration-500 ease-in-out">

        <div class="flex flex-col w-full h-full">

            <div class="flex w-full h-screen text-gray-700 dark:text-gray-500 transition duration-500 ease-in-out">
                <!-- left column -->
                <div class="w-full md:w-5/12 h-screen overflow-y-scroll scrollbar-thin scrollbar-track-indigo-200 scrollbar-thumb-indigo-600">

                    <!-- title -->
                    <div @mouseleave="dropdown=false;" class="flex flex-col z-30 sticky inset-x-0 top-0 left-0 bg-teal-600 p-4">
                        <h2 class="text-lg font-bold text-center text-white">{{singel_citeman.name}}</h2>

                        <div class="w-full flex">
                            <input @keyup="search" v-model="searchbox" class="rounded-md h-8 w-full py-3 px-6 text-gray-800 leading-tight focus:outline-none" type="text" placeholder="Search" />
                        </div>
                    </div>

                    <div class="flex justify-center items-center p-2">

                        <!-- add from Collection Button -->
                        <div class="relative">
                            <Button @btnClick="dropdown = !dropdown;" imageSource="https://img.icons8.com/fluency/96/000000/add-to-collection.png" buttonText="Add from collection" />
                            <div @mouseleave="dropdown = !dropdown;" v-bind:class="[dropdown ? 'block':'hidden']" class="absolute w-full mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <a v-for="collection in collection_list" :key="collection" @click="toggleSingleAddtoCollectionModal(collection)" class="block px-4 py-2 border text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                    <div class="font-medium flex flex-row">
                                        <span v-text="collectionNameSplit(collection.name)" class="cursor-pointer"></span>
                                    </div>
                                </a>
                            </div>

                        </div>

                        <!-- Import button -->
                        <Button @btnClick="toggleImportModal()" imageSource="https://img.icons8.com/color/96/000000/import.png" buttonText="import from file" />

                        <!-- Manually add button -->
                        <Button @btnClick="togglemanuallyModal()" imageSource="https://img.icons8.com/color/96/000000/add-to-inbox.png" buttonText="Add manually" />
                        <!-- Delete button -->
                        <Button v-if="display_muliple_option" @btnClick="toggleMultipleDeleteModal()" imageSource="https://img.icons8.com/fluency/96/000000/delete-trash.png" buttonText="Delete Article" />
                    </div>
                    <div class="w-full h-10/12 p-2">

                        <div class="px-2" v-if="singel_citeman.article != []">
                            <div v-for="(article1,index) in singel_citeman.article" :key="index " v-on:click="articleDetails(article1)">
                                <div class="card_article_list w-full h-20 flex flex-row my-2 rounded-md shadow-sm bg-white border hover:shadow-lg hover:bg-gray-200">
                                    <div class="w-full flex flex-row">
                                        <div class="w-1/12 relative h-12 rounded-l-md flex flex-row justify-center items-center">
                                            <div class="w-2/6 h-12 text-indigo-500 rounded-l-md p-8"><span class="inline-block align-baseline ..."><i class="fas fa-book"></i></span>
                                            </div>
                                            <div class="w-2/6">
                                                <input v-on:click="checkbox" v-bind:data-id="article1.id" class="rounded articles-checkbox absolute left-1 h-4 w-4 mr-2 outline-none active:outline-none" type="checkbox" name id />
                                            </div>
                                        </div>

                                        <div class="w-11/12 rounded-r-md flex flex-col place-content-center">
                                            <div class="w-full text-sm font-medium text-gray-900 px-2 text-left truncate article_title">{{article1.title}}</div>
                                            <div class="w-full text-xs font-thin px-2 text-left truncate">
                                                <strong>{{article1.journal}}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div v-else>
                            <div class="w-full h-24 flex flex-row mb-2 mt-2 rounded-md shadow-sm bg-white border hover:shadow-lg hover:border-purple-600 cursor-pointer">No records found</div>
                        </div>
                    </div>

                </div>
                <!-- Right column -->
                <!-- <transition name="fade" mode="out-in"> -->
                <div v-if="rightColumn" class="w-0 md:w-7/12 h-full">
                    <!-- title part -->
                    <div class="flex flex-row w-full h-auto px-4 py-2 items-center bg-gradient-to-br from-purple-800 to-indigo-900">
                        <div class="w-11/12 min-h-20 h-20 px-2 text-gray-50 text-left text-lg font-normal">{{currentarticle.title}}</div>
                        <div class="w-1/12 pr-2 font-medium flex flex-row justify-end items-center">
                            <span @click="toggleDeleteModal()" class="w-12 h-12 bg-gray-900 bg-opacity-50 rounded-full justify-center items-center flex flex-row text-white font-bold hover:bg-gray-700 cursor-pointer">
                               <font-awesome-icon class=" w-5 h-5 text-base  cursor-pointer " :icon="['fas', 'trash']" />
                            </span>
                        </div>
                    </div>
                    <div class="container my-2 flex flex-wrap p-4 flex-col md:flex-row items-center rounded-lg border-2 bg-white ">
                        <div class="px-4 py-2 m-2 bg-gray-200 rounded text-md font-medium text-left lg:mr-4">
                            <span class="text-white items-center pr-4">
                                <a :href="currentarticle.pdf_url" class="cursor-pointer rounded text-red-500" v-if="currentarticle.pdf_url">

                                    <font-awesome-icon class=" text-2xl cursor-pointer " :icon="['fas', 'file-pdf']" />
                                </a>
                            </span>
                            Published : {{currentarticle.pub_date}}. {{currentarticle.journal}}.
                            <span class="text-white over:scale-105">
                                <a :href="currentarticle.html_url" class="cursor-pointer rounded bg-teal-500 px-4 py-1" v-if="currentarticle.html_url" @click="$browser.openBrowser(currentarticle.html_url)">Visit journal</a>
                            </span>
                        </div>
                        <div class="px-8 py-2 w-10/12 text-lg font-bold text-blue-500 text-justify" v-if="currentarticle.abstract">Abstract</div>
                        <div class="px-8 py-2 w-11/12 text-md text-justify">{{currentarticle.abstract}}</div>
                        <div class="px-8 py-2 w-full text-md font-medium text-blue-500 text-justify" v-if="currentarticle.doi">
                            DOI:
                            <a :href="'https://www.doi.org/'+currentarticle.doi">{{currentarticle.doi}}
                            </a>
                            </div>

                        <div class="px-8 py-2 w-10/12 text-lg font-semibold text-blue-500 text-justify">Copy File
                            <font-awesome-icon class="animate animate-pulse mt-1 ml-5 text-blue-500 text-lg" :icon="['fas', 'download']" />
                        </div>
                        <div class="px-8 flex flex-row flex-wrap">
                            <div v-for="citeman_format in citeman_all_val" :key="citeman_format" class=" rounded-lg">
                                <!-- <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                            {{ citeman_format.data}}
                        </a> -->

                                <button v-on:click="myFunction123(citeman_format)" class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"> {{ citeman_format.format }} &nbsp;&nbsp;
                                    <i class="far fa-copy"></i>
                                </button>
                                &nbsp;&nbsp;
                            </div>
                        </div>
                    </div>

                </div>
                <!-- </transition> -->

                <!-- all pop-ups goes here -->

                <!-- File upload pop-up -->
                <div v-if="importmodal && upload_pop_option" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black bg-opacity-30 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                    <div class="relative w-auto my-6 mx-auto max-w-sm">
                        <!--content-->
                        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <!--header-->
                            <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                <h3 class="text-3xl font-semibold">File upload</h3>
                                <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleImportModal()">
                                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                </button>
                            </div>
                            <!--body-->
                            <div v-if="notification" class="items-center">
                                <div class="relative p-6 flex-auto">
                                    <p class="my-4 text-green-400">file already exists</p>
                                </div>
                            </div>
                            <div v-else>
                                <div class="relative p-6 flex-auto">

                                    <!-- pop for citman  -->

                                    <div id="importfileManual" class="modal">
                                        <!-- Modal content -->
                                        <div class="modal-content">
                                            <div class="modal-body">

                                                <!-- <h1>{{import_content_status}}<h1> -->
                                                <div id="upload_message" style="color: red;text-align: center;"></div>
                                                <label>Select file: <span id="message_display"></span></label>
                                                <input @change="selectedFile" type="file" name="file" accept=".bib,.bibtex,.enw,.ris" />
                                                <div v-if="display_copymessage_option" class="relative w-full -mt-5">
                                                    <div class="flex flex-row justify-between items-center bg-transparent absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                                                        <!-- <p class="text-green-400 font-bold">{{import_content_status.data.message}}</p> -->
                                                        <p class="text-green-400 font-bold">Articles Add successfully</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- pop main end -->

                                </div>
                                <!--footer-->
                                <div class="flex justify-end gap-4 p-6 rounded-b">
                                    <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="toggleImportModal()">Cancel</button>

                                    <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" @click="citeman_article_update_using_collection(result)">Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- File upload pop-up ends here -->

                <!-- Add manually popup start -->
                <!-- Add manually popup start -->
                <div v-if="manually_importmodal && Add_manually_pop_option" class="overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                    <AddManuallyModal @submitBtn="citeman_article_update_using_collection2" @closeBtn="Add_manually_pop_option = false" />
                </div>

                <!-- Add manually popup start ends here -->

                <!-- start multiple delete pop-up modal -->
                <div v-if="showmultipleDeleteModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black bg-opacity-30 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                    <div class="relative w-auto my-6 mx-auto max-w-sm">
                        <!--content-->
                        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <!--header-->
                            <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                <h3 class="text-3xl font-semibold">Articles Delete</h3>
                            </div>
                            <!--body-->
                            <div class="relative p-6 flex-auto">
                                <p class="my-4 text-gray-600 text-lg leading-relaxed">
                                    <b> Are you sure want to delete the publications?</b>
                                    <br />
                                    <span>{{currentarticle.title}}</span>
                                </p>
                            </div>
                            <!--footer-->
                            <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1  model-close" type="button" style="transition: all .15s ease" v-on:click="toggleMultipleDeleteModal()">Not now</button>
                                <button class="text-blue-500 bg-transparent border border-solid border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="multipleAticleDelete()">Yes</button>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- end multiple delete pop-up modal -->

                <!-- single delete pop-up modal -->
                <div class="font-medium flex flex-col justify-end items-end">
                    <div>

                        <div>
                            <div v-if="showDeleteModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center lg:justify-end items-center flex">
                                <div class="relative w-auto my-6 mx-auto max-w-sm">
                                    <!--content-->
                                    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        <!--header-->
                                        <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                            <h3 class="text-3xl font-semibold">Delete</h3>
                                            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleDeleteModal()">
                                                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                            </button>
                                        </div>
                                        <!--body-->
                                        <div v-if="notification" class="items-center">
                                            <div class="relative p-6 flex-auto">
                                                <p class="my-4 text-green-400">{{messagebody}}</p>
                                            </div>
                                            <div class="flex items-center justify-center p-6 border-t border-solid border-gray-300 rounded-b">

                                                <button class="text-blue-500 bg-transparent border border-solid border-blue-500 hover:bg-blue-500 hover:text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="toggleDeleteModal()">Close</button>

                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="relative p-6 flex-auto">
                                                <p class="my-4 text-gray-600 text-lg leading-relaxed">
                                                    Are you sure want to delete the publication
                                                    <br />

                                                    <b>{{currentarticle.title}}</b>

                                                    <br />
                                                </p>
                                            </div>
                                            <!--footer-->
                                            <div class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                                <button class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="toggleDeleteModal()">Not now</button>
                                                <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style="transition: all .15s ease" v-on:click="CitemanArticlesDelete([currentarticle.id])">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showDeleteModal" class="bg-opacity-70 fixed inset-0 z-40 bg-black"></div>
                        </div>
                    </div>
                </div>
                <!-- end single delete pop-up modal -->
            </div>

            <div v-if="display_copymessage_option" class="relative w-full -mt-5">
                <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                    <p class="text-white font-bold">{{copymessagebody}}</p>
                    <font-awesome-icon class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
                </div>

            </div>

            <div v-if="display_update_article_notification" class="relative w-full -mt-5">
                <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                    <p class="text-white font-bold">Article update</p>
                    <font-awesome-icon class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
                </div>

            </div>

            <!-- start articles load time loader  -->

            <div v-if="loading" class="flex h-screen">
                <!-- <h2 class="text-center">{{botTitle}} ( {{currentArticleCount}} / {{totalCount}} )</h2> -->
                <div class="m-auto">
                    <p class="w-full text-center p-5">
                        <img class="h-20 mx-auto" :src="$static.path(require('@/assets/loading-animate.gif'))" alt="loading...">
                        Loading Articles
                    </p>
                </div>
            </div>

            <!-- end articles load time loader  -->

            <!-- start articles no record found  -->
            <div v-if="loading" class="flex h-screen">
                <div class="m-auto">
                    <img class="w-56 h-56" :src="$static.path(require('@/assets/loading.svg'))" />
                    <div class="text-center text-xl font-medium">
                        No records to show
                    </div>
                </div>
            </div>
            <div v-else-if="bucket_result == '500 Error'" class="flex h-screen">
                500 Error
            </div>

            <!-- end articles no record found  -->
            <div v-else-if="notification" class="relative w-full -mt-5">
                <div class="flex flex-row justify-between items-center bg-green-600 absolute bottom-0 right-0 w-auto h-auto p-4 rounded-md mr-2">
                    <p class="text-white font-bold">{{messagebody}}</p>
                    <font-awesome-icon class="text-white ml-4 text-lg" :icon="['fas', 'times-circle']" />
                </div>
            </div>
        </div>
        <!-- </div> -->
    </main>
</div>
</template>

<script>
import LeftNavigation from "@/components/LeftNavigation.vue";
import Button from "@/components/Button.vue";
import AddManuallyModal from "@/components/AddManuallyModal.vue";
import copy from 'copy-to-clipboard';
import {
    mapActions
} from "vuex";
import $ from "jquery";
import axios from "axios";
const fs = require('fs');
// import pathModule from 'path'
// import { Console } from 'node:console';
export default {
    name: "Citeman",
    components: {
        LeftNavigation,
        Button,
        AddManuallyModal
    },
    inject: ['isDevelopment'],
    data: () => ({
        currentarticle: [],
        citeman_list: [],
        loading: false,
        singel_citeman: [],
        selected: "article",
        rowId: 10,
        showDeleteModal: false,
        notification: false,
        messagestatus: "",
        messagebody: "",
        display_muliple_option: false,
        showmultipleDeleteModal: false,
        dropdown: false,
        collection_list: [],
        add_citeman: [],
        citeman_all_val: [],
        current_citeman_format: {},
        copymessagebody: "",
        display_copymessage_option: false,
        upload_pop_option: false,
        importmodal: false,
        result: {},
        import_content_status: {},
        Add_manually_pop_option: false,
        manually_importmodal: false,
        display_update_article_notification: false,
        searchbox: "",
        manually_importmodal_value: {
            title: '',
            doi: '',
            journal: '',
            volume: '',
            issue: '',
            pub_date: '',
            html_url: '',
            authors: [],
            journal_issn: '',
            pagenum: '',
            abstract: '',
            keywords: [],
            pdf_url: '',

        },
        rightColumn: false

    }),

    props: {
        msg: String
    },

    mounted: function () {
        setTimeout(() => this.filters = ["Apache", "Cochise"], 3000)
        this.collection_list = this.$store.getters.CollectionListInfo.data;
    },
    methods: {

        ...mapActions(["GetCitemanList"]),
        collectionNameSplit(data) {
            return data.split(":")[0];
        },
        changeItem: function changeItem(rowId, event) {
            this.selected = event.target.value;
        },

        search: function () {
            var input_value = this.searchbox;
            console.log("new function:", input_value);
            $(".article_title").each(function () {
                var str = $(this).text();
                if (input_value) {
                    if (str.toLowerCase().indexOf(input_value.toLowerCase()) >= 0) {
                        $(this)
                            .closest(".card_article_list")
                            .show();
                    } else {
                        $(this)
                            .closest(".card_article_list")
                            .hide();
                    }
                } else {
                    $(this)
                        .closest(".card_article_list")
                        .show();
                }
            });
        },
        articleDetails: async function (article_list) {
            this.rightColumn = false
            this.citeman_mal = ""
            this.citeman_mal123 = ""
            this.currentarticle = article_list;
            var that = this

            // const defaultCwd = (electron.app || electron.remote.app).getPath('userData');
            // var file1 = pathModule.join(defaultCwd, 'thing.json');
            // console.log(file1)
            var format_list = ['nat', 'chi', 'apa', 'mla']
            var all_output_format = []

            for (var i = 0; i < format_list.length; i++) {
                let sampl_dit = {}
                let format = format_list[i]
                sampl_dit["format"] = format_list[i]
                that.current_citeman_format["format"] = format_list[i]
                console.log("article_list", article_list)
                var list_author = []
                if (article_list.authors) {
                    var authors_val = article_list.authors
                    authors_val.forEach(function (author) {
                        var ar_dic = {}
                        ar_dic['first_name'] = author
                        ar_dic['middle_names'] = ""
                        ar_dic['degree'] = ""
                        ar_dic['email'] = ""
                        ar_dic['orcid'] = ""
                        ar_dic['last_name'] = ""

                        if (author.lastIndexOf(" ") == -1) {
                            var first_name = author.trim()
                            var last_name = '';
                        } else {
                            first_name = author.substring(0, author.lastIndexOf(" ") + 1).trim();
                            last_name = author.substring(author.lastIndexOf(" ") + 1, author.length).trim();
                        }

                        ar_dic['first_name'] = first_name.replace('and ', '').trim();
                        ar_dic['last_name'] = last_name
                        list_author.push(ar_dic)
                    });

                    var keyword = article_list.keywords.join(', ')

                    var new_dic1 = {}
                    new_dic1["id"] = parseInt(article_list.id)
                    new_dic1["title"] = article_list.title
                    new_dic1["doi"] = article_list.doi
                    new_dic1["journal"] = article_list.journal
                    new_dic1["volume"] = article_list.volume
                    new_dic1["issue"] = article_list.issue
                    new_dic1["pub_date"] = article_list.pub_date
                    new_dic1["html_url"] = article_list.html_url
                    new_dic1["authors"] = list_author
                    new_dic1["journal_issn"] = article_list.journal_issn
                    new_dic1["pagenum"] = article_list.pagenum
                    new_dic1["abstract"] = article_list.abstract
                    new_dic1["keywords"] = keyword
                    new_dic1["pdf_url"] = article_list.pdf_url

                    var tmp_json = JSON.stringify(new_dic1)
                }

                let citeman_out1 = await this.citemanapi(format, tmp_json)
                sampl_dit["data"] = citeman_out1
                that.citeman_all_val = [
                    ...that.citeman_all_val,
                    sampl_dit
                ];
                all_output_format.push(sampl_dit)

            }
            that.citeman_all_val = all_output_format

            setTimeout(() => {
                this.rightColumn = true
            }, 500)
        },
        citemanapi: async function (format, article_list) {
            let va = article_list.replace("null", '""')
            let json = JSON.parse(va)
            console.log(json)

            let response = await axios.post(`https://citeman.zealbots.com/${format}`, json)
            if (response) {
                console.log("response.data", response.data)
                return response.data
            } else {
                console.log("value Err")
            }

        },
        citemanexe: async function (full_path, format, pat) {
            var source = "article"
            var output_format = "json"
            const util = require('util');
            const exec = util.promisify(require('child_process').exec);
            var executable_file = full_path + " --format " + format + " --input " + pat + " -s " + source + " --mode " + output_format
            return exec(executable_file)
                .then(function (response) {
                    if (response) {
                        return response.stdout
                    } else {
                        console.log("value Err")
                    }
                });
        },
        // myFunction: function (data) {
        //     shell.openExternal(data);
        // },
        captalize: function (data) {
            return data
                .trim()
                .toLowerCase()
                .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
        },
        toggleDeleteModal: function () {
            this.showDeleteModal = !this.showDeleteModal
        },
        toggleImportModal: function () {
            this.upload_pop_option = true
            this.importmodal = !this.importmodal
        },
        togglemanuallyModal: function () {
            this.Add_manually_pop_option = true
            this.manually_importmodal = !this.manually_importmodal
        },
        toggleMultipleDeleteModal: function () {
            this.showmultipleDeleteModal = !this.showmultipleDeleteModal
        },
        citemanListFunction: async function () {
            this.loading = true;
            var userInfo = this.$store.getters.StateUserInfo;
            this.loopBreak = this.$route.params.id;
            var bucket_data = {
                user: userInfo.id,
                key: userInfo.key
            };
            try {
                console.log("bucket_id", bucket_data)
                var bucket_result = await this.GetCitemanList(bucket_data);
                bucket_data = bucket_result.data.data;
                console.log("bucket_data", bucket_data)
                this.citeman_list = bucket_data;

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        citemanFunction: async function (id) {
            var that = this
            that.notification = false;
            that.showDeleteModal = false;
            that.display_muliple_option = false;
            that.showmultipleDeleteModal = false;

            var user_id = this.$store.getters.StateUserInfo.id;
            var user_key = this.$store.getters.StateUserInfo.key;
            var citeman_id = id
            console.log("id", id)
            try {

                var Citeman_url = `https://api.zealbots.com/api/v1/citeman/?author=${user_id}&id=${citeman_id}&fields=name,article&Key=${user_key}&format=json`
                axios.get(Citeman_url)
                    .then(response => {
                        if (response && response.data) {
                            console.log(response.data)
                            var singel_citeman_data = response.data.data
                            console.log("singel_citeman_data", singel_citeman_data[0])
                            this.singel_citeman = singel_citeman_data[0];
                        } else {
                            console.log(response)
                        }
                        this.articleDetails(this.singel_citeman.article[0]);
                    })
                    .catch(error => {
                        console.log(error)
                    })

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        CitemanArticlesDelete: function (data1) {
            var that = this
            var userInfo = this.$store.getters.StateUserInfo;
            console.log("collection articles delete ")
            var data = {}
            data['citeman_id'] = this.$route.params.id
            data['article_id'] = data1
            data['user_id'] = userInfo.id
            console.log("data", data)
            var collection_delete_url = `citeman/article/deleted/?Key=${this.$store.getters.StateUserInfo.key}`;
            axios.post(collection_delete_url, data)
                .then(function (response) {
                    if (response && response.data) {
                        console.log("log : ", response.data);
                        that.notification = true
                        that.messagestatus = "success"
                        that.messagebody = `Articles deleted successfully`
                        that.showmultipleDeleteModal = false;
                        that.showDeleteModal = false;
                    } else {
                        console.log("skiped details: ", response);
                    }
                });
            setTimeout(function () {
                that.notification = false;
                that.display_muliple_option = false;
                that.citemanFunction(that.$route.params.id);

            }, 5000)
        },

        checkbox: function () {
            // checkbox select and unselect item. if any checkbox check that time addto collection and articles delete button show, otherwise button hide function
            var checkboxes2 = document.querySelectorAll('.articles-checkbox:checked');
            if (checkboxes2 && checkboxes2.length == 0) {
                this.display_muliple_option = false
                this.showmultipleDeleteModal = false
            } else {
                this.display_muliple_option = true
            }
        },

        // collection article insertion , manualform insertion function using api
        citeman_article_update_using_collection: function (result) {
            var that = this
            var collection_delete_url = `https://api.zealbots.com/api/v1/citeman/update/?Key=${this.$store.getters.StateUserInfo.key}`;
            axios.post(collection_delete_url, result)
                .then(function (response) {
                    if (response && response.data) {
                        that.import_content_status = response
                        that.display_update_article_notification = true
                        setTimeout(function () {
                            that.display_update_article_notification = false
                            that.citemanFunction(that.$route.params.id);
                            that.upload_pop_option = false
                        }, 2000);
                    }
                });
        },
        multipleAticleDelete: function () {
            // checkbox selected item find and multiple articles delete function
            var arraypublication = []
            var checkboxes = document.getElementsByClassName("articles-checkbox")
            for (var checkbox of checkboxes) {
                if (checkbox.checked) {
                    arraypublication.push(checkbox.getAttribute('data-id'))
                }
            }
            console.log("articles deleted", arraypublication)
            this.CitemanArticlesDelete(arraypublication)
        },
        toggleSingleAddtoCollectionModal: function (collection) {
            // this.GetCitemanList1(collection.paper);
            var data = collection.paper
            var total = collection.paper.length;

            var user_id = this.$store.getters.StateUserInfo.id;
            var user_key = this.$store.getters.StateUserInfo.key;
            try {
                var that = this
                for (var index2 = 0; index2 < total; index2++) {
                    var article = data[index2]
                    var publication_url = `publication/?id=${article}&Key=${user_key}&format=json`
                    axios.get(publication_url)
                        .then(response => {
                            if (response && response.data) {
                                that.dropdown = false
                                var article_data = response.data.data[0]
                                var author_list = []
                                var author = article_data.authors
                                for (var i = 0; i < article_data.authors.length; i++) {
                                    author_list.push(author[i].name_full)
                                }
                                var new_dic1 = {}
                                new_dic1["title"] = article_data["title"]
                                new_dic1["journal"] = article_data["journal"]
                                new_dic1["pub_date"] = article_data["pub_date"]
                                new_dic1["doi"] = article_data["doi"]
                                new_dic1["journal_issn"] = ""
                                new_dic1["volume"] = article_data["volume"]
                                new_dic1["issue"] = article_data["issue"]
                                new_dic1["pagenum"] = article_data["pagenum"]
                                new_dic1["authors"] = author_list
                                new_dic1["abstract"] = article_data["abstract"]
                                new_dic1["keywords"] = [article_data["keywords"]]
                                new_dic1["pdf_url"] = article_data["pdf_url"]
                                new_dic1["html_url"] = article_data["html_url"]

                                var main_dic = {}
                                main_dic['id'] = that.$route.params.id
                                main_dic['name'] = that.singel_citeman.name
                                main_dic['user'] = user_id
                                main_dic['article'] = new_dic1
                                main_dic['category'] = "Article"

                                this.add_citeman = main_dic
                                this.citeman_article_update_using_collection(main_dic)
                            } else {
                                console.log(response)
                            }
                        })
                }

            } catch (error) {
                console.log(error);
            }
        },

        myFunction123: async function (citeman_format) {
            var copyText = citeman_format
            var that = this
            try {
                var copyText_format = copyText.format
                let copyText_data = copyText.data

                copy(copyText_data);
                this.copymessagebody = copyText_format + ' ' + `copy the successfully`

                this.display_copymessage_option = true
                setTimeout(function () {
                    that.display_copymessage_option = false
                }, 5000)
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        selectedFile: function (e) {
            let filename = e.target.files[0]
            let upload_file = filename.path

            var extension = upload_file.substr((upload_file.lastIndexOf('.') + 1));
            console.log("extension", extension)
            if (extension == "bib") {
                this.bib_to_json(upload_file)

            } else if (extension == "bibtex") {
                this.bib_to_json(upload_file)

            } else if (extension == "enw") {
                // enw_to_json(upload_file)
                this.enw_to_json(upload_file)
                // alert("enw coming soon")

            } else if (extension == "ris") {
                this.ris_to_json(upload_file)
                // alert("ris coming soon")

            } else {
                alert("file format not support")
            }

        },
        checker_author: function (data) {
            if (data) {
                return data.split(',')
            } else {
                return []
            }
        },

        // empty checker
        checker: function (data) {
            if (data) {
                return data
            } else {
                return ""
            }

        },

        citeman_article_update_using_collection2: function (data) {
            console.log("Expected Data output", data)
            var test = data;
            var user_id = this.$store.getters.StateUserInfo.id;
            var user_key = this.$store.getters.StateUserInfo.key;

            var new_dic1 = {}
            new_dic1["id"] = 1;
            new_dic1["title"] = test.title
            new_dic1["journal"] = test.journal
            new_dic1["pub_date"] = test.pub_date
            new_dic1["doi"] = test.doi
            new_dic1["journal_issn"] = test.journal_issn
            new_dic1["volume"] = test.volume
            new_dic1["issue"] = test.issue
            new_dic1["pagenum"] = test.pagenum
            new_dic1["authors"] = test.authors.split(",")
            new_dic1["abstract"] = test.abstract
            new_dic1["keywords"] = [test.keywords]
            new_dic1["pdf_url"] = test.pdf_url
            new_dic1["html_url"] = test.html_url
            var main_dic = {}
            main_dic['id'] = this.$route.params.id
            main_dic['user'] = user_id
            main_dic['article'] = new_dic1
            main_dic['category'] = "Article"

            var that = this
            fetch(`https://api.zealbots.com/api/v1/citeman/update/?Key=${user_key}`, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, cors, *same-origin
                    // credentials:'same-origin',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                    // body data type must match "Content-Type" header
                    body: JSON.stringify(main_dic),
                })

                .then(function (response) {
                    console.log("response", response)
                    that.import_content_status = response
                    console.log("import_content_status", that.import_content_status)
                    // that.display_copymessage_option = true

                    that.notification = true
                    that.messagestatus = "success"
                    that.messagebody = `Articles Add successfully`
                    setTimeout(function () {
                        // that.display_copymessage_option = false
                        that.togglemanuallyModal();
                        that.citemanFunction(that.$route.params.id);
                    }, 2000);
                });
        },

        bib_to_json: function (upload_file) {

            fs.readFile(upload_file, (err, data) => {
                if (err) throw err;
                let indata = data.toString()
                var lines = indata.trim().split("\n");

                var dic1 = {}
                for (var i = 0, len = lines.length; i < len; i++) {
                    let line = lines[i].trim()
                    if (line && line.includes('=')) {
                        line = line.replace('"', '').replace('"', '')
                        if (line.substr(-1) == ",") {
                            line = line.slice(0, -1)
                        }
                        let cite_key = line.split('=')

                        dic1[cite_key[0].toLowerCase().trim()] = cite_key[1].replace("{", "").replace("}", "").trim()
                    }

                }
                console.log("dic1", dic1)
                var user_id = this.$store.getters.StateUserInfo.id;

                let year = data['year'] || "2020"
                let month = dic1['month'] || "01"
                let day = dic1['date'] || "01"
                month = month.padStart(2, "0");
                day = day.padStart(2, "0");
                let pub_date = year + "-" + month + "-" + day

                var new_dic1 = {}
                new_dic1["id"] = 1;
                new_dic1["title"] = this.checker(dic1["title"])
                new_dic1["journal"] = this.checker(dic1["journal"])
                new_dic1["pub_date"] = pub_date
                new_dic1["doi"] = this.checker(dic1["doi"])
                new_dic1["journal_issn"] = this.checker(dic1["issn"])
                new_dic1["volume"] = this.checker(dic1["volume"])
                new_dic1["issue"] = this.checker(dic1["number"])
                new_dic1["pagenum"] = this.checker(dic1["pages"])
                new_dic1["authors"] = this.checker_author(dic1["author"])
                new_dic1["abstract"] = this.checker(dic1["abstract"])
                new_dic1["keywords"] = []
                new_dic1["pdf_url"] = ""
                new_dic1["html_url"] = this.checker(dic1["url"])
                var main_dic = {}
                main_dic['id'] = this.$route.params.id
                main_dic['user'] = user_id
                main_dic['article'] = new_dic1
                main_dic['category'] = "Article"

                console.log("new_dic1", new_dic1)

                this.result = main_dic

            })

        },

        enw_to_json: function (upload_file) {
            var user_id = this.$store.getters.StateUserInfo.id;
            fs.readFile(upload_file, (err, data) => {
                if (err) throw err;
                let indata = data.toString()
                var lines = indata.trim().split("\n");
                var dic1 = {}
                var author = []
                var jack = {
                    "%T": "title",
                    "%J": "journal",
                    "%D": "year",
                    "%8": "month_date",
                    "%V": "volume",
                    "%N": "issue",
                    "%X": "abstract",
                    "%R": "doi",
                    "%U": "html_url",
                    "%P": "pagenum"
                }
                for (var i = 0, len = lines.length; i < len; i++) {
                    var line = lines[i].trim()

                    var key = line.match(/(^%.)/gm)[0].trim()
                    var value = line.replace(/(^%.)/, '').trim()
                    if (key == "%A") {
                        author.push(value)
                    } else {
                        if (key in jack) {
                            dic1[jack[key]] = value
                        }
                    }
                }
                var year = dic1['year'] || "2020"
                var month_date = dic1['month_date'].length
                if (month_date == 4) {
                    var month = dic1['month_date'].slice(0, 2) || "01"
                    var day = dic1['month_date'].slice(2, 4) || "01 "
                } else {
                    month = dic1['month_date'] || "01"
                    day = dic1['month_date'] || "01 "
                }

                var pub_date = year + "-" + month + "-" + day

                dic1["id"] = 1;
                dic1["authors"] = author
                dic1["pub_date"] = pub_date
                dic1["keywords"] = []
                dic1["pdf_url"] = ""
                dic1["journal_issn"] = ""

                var main_dic = {}
                main_dic['id'] = this.$route.params.id
                main_dic['user'] = user_id
                main_dic['article'] = dic1
                main_dic['category'] = "Article"
                this.result = main_dic
            });
        },

        ris_to_json: function (upload_file) {
            var user_id = this.$store.getters.StateUserInfo.id;
            fs.readFile(upload_file, (err, data) => {
                if (err) throw err;
                let indata = data.toString()
                var lines = indata.trim().split("\n");
                var dic1 = {}
                var author = []
                var jack = {
                    "TI": "title",
                    "JO": "journal",
                    "DA": "pub_date",
                    "VL": "volume",
                    "IS": "issue",
                    "AB": "abstract",
                    "DO": "doi",
                    "UR": "html_url",
                    "SP": "pagenum"
                }
                for (var i = 0, len = lines.length; i < len; i++) {
                    var line = lines[i].trim()

                    var key = line.match(/(^\w+\s+-)/gm)[0].replace('-', '').trim()
                    var value = line.replace(/(^\w+\s+-)/, '').trim()
                    if (key == "AU") {
                        author.push(value)
                    } else {
                        if (key in jack) {
                            dic1[jack[key]] = value
                        }

                    }
                }
                dic1["id"] = 1;
                dic1["authors"] = author
                dic1["pub_date"] = dic1["pub_date"] || "2020-01-01"
                dic1["keywords"] = []
                dic1["pdf_url"] = ""
                dic1["journal_issn"] = ""

                var main_dic = {}
                main_dic['id'] = this.$route.params.id
                main_dic['user'] = user_id
                main_dic['article'] = dic1
                main_dic['category'] = "Article"
                this.result = main_dic
            });
        },

    },
    created: async function () {
        await this.citemanFunction(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next, citeman_format) {
        console.log("before", to, from, next, this.$route.path);
        this.citemanFunction(to.params.id);
        next();
        this.myFunction123(citeman_format);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.fade-enter {
    transform: translateY(5px);
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 1s ease;
}

.fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}

.some {
    color: rgb(0, 128, 0);
}

/* testgin *

/* testing end */
</style>
