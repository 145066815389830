<template>
    <div class="fixed right-4 flex flex-row items-center bg-green-100 p-4 w-1/4 shadown-2xl h-20 rounded-lg">
        <svg class="w-1/12 h-full ">
            <rect width="4" height="100" style="fill:#059669;" />
        </svg>
        <div class="flex flex-row items-center pl-2 gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 172 172" style=" fill:#000000;">
                <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g>
                        <path d="M157.66667,86c0,39.57792 -32.08875,71.66667 -71.66667,71.66667c-39.57792,0 -71.66667,-32.08875 -71.66667,-71.66667c0,-39.57792 32.08875,-71.66667 71.66667,-71.66667c39.57792,0 71.66667,32.08875 71.66667,71.66667z" fill="#4caf50"></path>
                        <path d="M123.9905,52.32383l-48.7405,48.72258l-20.07383,-20.0595l-10.02258,10.02258l30.09642,30.11075l58.7595,-58.77383z" fill="#ffffff"></path>
                    </g>
                </g>
            </svg>

            Successfully added to collection
        </div>

        <div class="absolute right-4 text-3xl text-gray-400">&times;</div>

    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>
