<template>
<Navbar />
<div class="flex flex-col h-screen justify-center items-center gap-4">
    <h1 class="not-found text-8xl font-bold">
        Oops!
    </h1>
    <h1 class="uppercase font-bold text-3xl">
        404 - Page not found
    </h1>
    <p class="w-1/3 text-center">The page you are looking for might have been removed had its name changed or is temporarily unavaliable</p>
    <a href="/" class="px-8 py-4 uppercase font-semibold text-xl text-white rounded-full bg-indigo-800 shadow-2xl">
        Go to homepage
    </a>
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  components: {
    Navbar
  }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

h1.not-found{
  font-size:200px;
  font-family: 'montserrat', sans-serif;
  color: white;
  background: url("https://colorlib.com/etc/404/colorlib-error-404-10/img/bg.jpg");
  background-size:cover;
  background-position: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
