<template>
<Navbar />
<div class=" flex flex-col container ">
    <div class="flex flex-col m-0 mt-20">
        <div class="column is-4">
            <p>Thank you for subscribing to a plan!</p>
        </div>
    </div>
    <div class="container">
        <div class="printer-top"></div>

        <div class="paper-container">
            <div class="printer-bottom"></div>

            <div class="paper">
                <div class="main-contents">
                    <div class="success-icon">&#10004;</div>
                    <div class="success-title">
                        Payment Complete
                    </div>
                    <div class="success-description">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                    </div>
                    <div class="order-details">
                        <div class="order-number-label">Order Number</div>
                        <div class="order-number">{{payment_id}}</div>
                    </div>
                    <div class="order-footer">Thank you!</div>
                </div>
                <div class="jagged-edge"></div>
            </div>
        </div>
    </div>
    <div class="flex flex-row pl-10">
        <div class="p-4">
            <a class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" href="/pricing">
                Pricing Page
            </a>
        </div>
        <div class="p-4">
            <a ref="bill" class="p-2 rounded border border-blue-500 text-blue-500 text-xs hover:bg-blue-500 hover:text-white" @click="download()">
                Download Bill
            </a>
        </div>
    </div>
</div>

<Footer />
</template>

<script>
import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
    name: 'PlansThankyou',
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            payment_id: '',
        }

    },
    mounted() {
        // axios
        //     .post('http://127.0.0.1:8000/api/v1/stripe/webhook/', {
        //         'session_id': this.$route.query.session_id
        //     })
        //     .then(response => {
        //         console.log(response)

        //         this.$store.commit('setTeam', {
        //             'id': response.data.id,
        //             'name': response.data.name,
        //             'plan': response.data.plan.name,
        //             'max_leads': response.data.plan.max_leads,
        //             'max_clients': response.data.plan.max_clients
        //         })
        //     })
        //     .catch(error => {

        //         console.log('Error', error)
        //     })
    },
    methods: {
        async subscribe() {
            console.log("this.$route.query.session_id",this.$route.query.session_id)
            const data = {
                'session_id': this.$route.query.session_id
            }
            var userInfo = this.$store.getters.StateUserInfo;
            await axios.post(`https://api.zealbots.com/api/v1/checkoutstripe/?Key=${userInfo.key}`, data)
                .then(async response => {
                    this.payment_id = response.data.payment_id
                })
                .catch(error => {
                    console.log('Error:', error)
                })
        },
        async download() {
            var userInfo = this.$store.getters.StateUserInfo;
            await axios.get(`https://api.zealbots.com/api/v1/downloadbill/?Key=${userInfo.key}`)
                .then(async response => {
                    window.location.href = response.data.bill
                })
                .catch(error => {
                    console.log('Error:', error)
                })
        },
    },
    created: async function () {
        await this.subscribe();
    },

}
</script>

<style scoped>
button {
    z-index: 1;
    position: relative;
    font-size: inherit;
    font-family: inherit;
    color: white;
    padding: 0.5em 1em;
    outline: none;
    border: none;
    background-color: hsl(236, 32%, 26%);
    overflow: hidden;
    transition: color 0.4s ease-in-out;
}

button::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: #3cefff;
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    transition: transform 0.45s ease-in-out;
}

button:hover {
    cursor: pointer;
    color: #161616;
}

button:hover::before {
    transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
}

body {
    background: #999;
}

.container {
    max-width: 380px;
    margin: 30px auto;
    overflow: hidden;
}

.printer-top {
    z-index: 1;
    border: 6px solid #666666;
    height: 6px;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    background: #333333;
}

.printer-bottom {
    z-index: 0;
    border: 6px solid #666666;
    height: 6px;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    background: #333333;
}

.paper-container {
    position: relative;
    overflow: hidden;
    height: 467px;
}

.paper {
    background: #ffffff;
    height: 447px;
    position: absolute;
    z-index: 2;
    margin: 0 12px;
    margin-top: -12px;
    animation: print 18000ms cubic-bezier(.17, .67, .83, .67) infinite;
    -moz-animation: print 1800ms cubic-bezier(1.10, 0.67, 0.83, 0.67) infinite;
}

.main-contents {
    margin: 0 12px;
    padding: 24px;
}

/* // Paper Jagged Edge */
.jagged-edge {
    position: relative;
    height: 20px;
    width: 100%;
    margin-top: -1px;
}

.success-icon {
    text-align: center;
    font-size: 48px;
    height: 72px;
    background: #359d00;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    margin: 16px auto;
    color: #fff;
}

.success-title {
    font-size: 22px;
    text-align: center;
    color: #666;
    font-weight: bold;
    margin-bottom: 16px;
}

.success-description {
    font-size: 15px;
    line-height: 21px;
    color: #999;
    text-align: center;
    margin-bottom: 24px;
}

.order-details {
    text-align: center;
    color: #333;
    font-weight: bold;

    .order-number-label {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .order-number {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        line-height: 48px;
        font-size: 48px;
        padding: 8px 0;
        margin-bottom: 24px;
    }
}

.order-footer {
    text-align: center;
    line-height: 18px;
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #999;
}

@keyframes print {
    0% {
        transform: translateY(-90%);
    }

    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes print {
    0% {
        -webkit-transform: translateY(-90%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

@-moz-keyframes print {
    0% {
        -moz-transform: translateY(-90%);
    }

    100% {
        -moz-transform: translateY(0%);
    }
}

@-ms-keyframes print {
    0% {
        -ms-transform: translateY(-90%);
    }

    100% {
        -ms-transform: translateY(0%);
    }
}
</style>
