<template>
  <!-- component -->
  <div class="flex flex-col items-center h-screen bg-gray-100 justify-center" >
  <p v-if="msg" class="font-bold text-red-600  py-3" >{{msg}}</p>
    <div class="flex flex-col md:flex-row lg:flex-row justify-between border border-indigo-900  max max-w-screen-md">
      <div class="hidden p-8 py-2 text-white  md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly" style="background-color : #274472">
        <div class="my-3 text-3xl font-bold tracking-wider text-center">
          <div class="flex justify-center">
            <img src="../assets/img/logo.png" class="w-20 h-20" />
          </div>
          <a href="#">Create account</a>
        </div>
        <p class="mt-6 font-normal text-center text-white-300">
          Make your research relevant with the help of our semantic AI. ZealBots will find the best publications for you.
        </p>
      </div>

      <div class="lg:hidden md:hidden">
        <div class="mt-4  text-2xl font-bold tracking-wider text-center">
          <div class="flex justify-center">
            <img src="../assets/img/logo.png" class="w-10 h-10" />
          </div>
          <a href="#" class="text-gray-600">SignUp</a>
        </div>
      </div>

    <div class="py-2 px-1 bg-gray-100">

    <div class="">
      <form id="getData" class="space-y-8 px-4 text-gray-700" @submit.prevent="SignUp" >
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
            <div class="  flex flex-row justify-between">
            <div class="">
                <label for="first-name" class="p-2 text-xs not-sr-only">First Name *</label>
                <input id="first_name" name="first_name" type="text" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="First Name">
              </div>
              

              <div class="pl-1">
                <label for="last-name" class=" p-2 text-xs not-sr-only">Last Name </label>
                <input id="last-name" name="last_name" type="text" class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Last Name">
              </div>
            </div>
            <br>

            <div class="flex flex-col  items-center" >
                <div class="w-full">
                    <label for="orcid" class="p-2 text-xs not-sr-only">ORCID</label>
                    <input id="orcid" name="orcid" type="text" class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="ORCID">
                </div>
            </div>
            <br>
          
            <div>
                <label for="email-address" class=" p-2 text-xs not-sr-only">Email address *</label>
                <input id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
            </div>
          <br>
          <div class="flex flex-row justify-between">
          <div >
            <label for="password" class="p-2 text-xs not-sr-only">Password *</label>
            <input v-model="password_watch" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
          </div>

          

          <div class="pl-1" >
            <label for="retype-password" class=" p-2 text-xs not-sr-only">Retype Password *</label>
            <input v-if="password_watch == retype_password_watch" v-model="retype_password_watch" id="retype-password" name="retype_password" type="password" autocomplete="current-password" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Retype Password">
            <input v-else v-model="retype_password_watch" id="retype-password" name="retype_password" type="password" autocomplete="current-password" required class="appearance-none relative block w-full px-3 py-2 border bg-red-100 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Retype Password">
          </div>
        </div>

        </div>
  
        <div class="flex flex-wrap gap-4">
          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
            <label for="remember-me" class="ml-1 block text-xs text-gray-900">
              Accepts terms & conditions
            </label>
          </div>
  

          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
            <label for="remember-me" class="ml-1 block text-xs text-gray-900">
              News letter
            </label>
          </div>

          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
            <label for="remember-me" class="ml-1 block text-xs text-gray-900">
              Bot recommendations
            </label>
          </div>


        </div>
  
        <div>
          <button type="submit" class="group relative w-full flex  justify-center py-2 px-4 border border-transparent text-sm rounded-md text-white bg-indigo-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            SIGN UP
          </button>
        </div>
        <span class=" flex items-center justify-center space-x-2">
          <span class="h-px bg-gray-400 w-14"></span>
          <span class="font-normal ">or</span>
          <span class="h-px bg-gray-400 w-14"></span>
        </span>
        
        <div class="flex flex-row justify-center bg-gray-100" >
          <button type="button" class="group relative font-bold w-1/4 flex justify-center items-center py-2 px-4 border border-transparent text-sm rounded-md text-gray-500 border-gray-400 focus:outline-none focus:ring-2 bg-gray-200 hover:bg-gray-100 ">
              <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
          </button>
        </div>

      </form>
    </div>
  </div>
  


    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { mapActions } from "vuex";
// const electron = require("@electron/remote");
// const shell = electron.shell;
export default {
  data: () => ({
    email: "",
    password: "",
    info: "",
    msg: "",
    retype_password_watch : "",
    password_watch : "",

  }),
  mounted() {},
  methods: {
    
    async SignUp(submitEvent){

      var firstName = submitEvent.target.elements.first_name.value;
      var lastName = submitEvent.target.elements.last_name.value;
      var emailID = submitEvent.target.elements.email.value;
      // var OrcID = submitEvent.target.elements.orcid.value;
      var passWord = submitEvent.target.elements.password.value;
      var passWordRetype = submitEvent.target.elements.retype_password.value;

      var dataSignUp = {
        "first_name" : firstName,
        "last_name" : lastName,
        "email" : emailID,
        "password" : passWord
      }
      console.log("dataSignUp",dataSignUp)

      if (passWord == passWordRetype){
          await axios.post("https://api.zealbots.com/register/", dataSignUp)
          .then(response => {
                if (response && response.data) {
                    console.log(response)
                } else {
                    console.log(response)
                }
          })
      }
      else {
       this.msg = "Password mismatched!!!" 
        
      }
    }

  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
